<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img
          src="./../../../public/assets/images/bag.svg"
          alt=""
        />Orders
      </h6>
    </div>
    <div class="contant-details">
      <!-- purchase list start -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <table class="table table-sm m-0" id="my-table">
          <thead>
            <tr>
              <th scope="col" v-for="f in fields" v-bind:key="f.id">
                {{ f.split("_").join(" ") }}
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" v-bind:key="item.id"  class="button-tr">
              <td class="font-light-text" width="15%"> {{ item?.order_no }}</td>
              <td class="font-light-text" width="20%">{{ item?.order_date}}</td>
              <td class="font-light-text" width="20%"> {{ item?.currency}}</td> 
              <td class="font-light-text" width="5%" data-toggle="modal" data-target="#exampleModal" @click="fetchData(item)">
                  <table class="main-button--table">
                    <tr>
                      <td>
                        <button class="eye-view">
                          <EyeIcon />
                          </button>
                      </td>
                    </tr>
                  </table>
                
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :page="page"
          :totalPages="totalPages"
          :name="'Purchases'"
          :count="items.length ? items.length : 0"
          :incrementpage="incrementpage"
          :decrementpage="decrementpage"
          :setpage="setpage"
          :perpage="10"
        />
      </div>
      <!-- purchase list end -->
    </div>
    <!-- purchase details model start -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Order Details ({{tempItem.order_no}})</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- {{tempItem}} -->
        <table class="table">
  <thead>
    <tr>
      <th scope="col">Article ID</th>
      <th scope="col">Article Name</th>
      <th scope="col">Price</th>
      <th scope="col">Count</th>
      <th scope="col">Currency</th>
      <th scope="col">Unit Price</th>
      <th scope="col">Total Price</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
  <tr v-for="line in tempItem.order_lines" v-bind:key="line.id">
    <td class="font-light-text" width="10%">{{ line.article?.sku }}</td>
    <td class="font-light-text" width="30%">{{ line.article?.name }}</td>
    <td class="font-light-text" width="7.5%">{{ line.item_price }}</td>
    <td class="font-light-text" width="7.5%">{{ line.count }}</td>
    <td class="font-light-text" width="10%">{{ line.currency }}</td>
    <td class="font-light-text" width="10%">{{ line.item_price }}</td>
    <td class="font-light-text" width="10%">{{ line.total_price}}</td>
  </tr>
  <tr class="hidden-border">
    <td width="10%"></td>
    <td width="30%"></td>
    <td width="15%"></td>
    <td width="5%"></td>
    <td width="5%"></td>
    <td width="5%">Total</td>
    <td width="10%">
      {{ totalOrderValue }}
    </td>
    <td width="10%">{{ tempItem?.currency }}</td>
  </tr>
</tbody>
</table>
      </div>
      <div class="modal-footer">
        <button type="button" class="cancle-btn" data-dismiss="modal" >Cancel</button>
      </div>
    </div>
  </div>
</div>
 <!-- purchase details model end -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "../../Core/Pagination";
import EyeIcon from "../../icons/eye.vue";
import { LIST_CUSTOMER_PURCHASES } from "@/Core/store/action-types";
export default {
  name: "Purchases",
  components: {
    Pagination,
    EyeIcon
  },
  data() {
    return {
      page: 1,
      totalPages: [],
      tempItem:{},
      fields: ["Order ID", "Date", "currency"],
      items: [],
      loadindShow:false,
    };
  },
  computed: {
     totalOrderValue() {
    if (this.tempItem.order_lines && this.tempItem.order_lines.length > 0 && this.tempItem.order_lines[0].receipt) {
      return this.tempItem.order_lines[0].receipt.total_order_value;
    }
      return 'N/A';
    },
    ...mapGetters("customer", ["customerDetails"]),
  },
  methods: {
    ...mapActions("customer", [LIST_CUSTOMER_PURCHASES]),
    //get customer purchase details
    InItCustomerPurchaseDetails() {
      if (this.customerDetails?.external_id) {
        const externalId = this.customerDetails.external_id;
        const payload = {
          params: {
            page: this.page,
          },
          objId: externalId,
        };
        this.loadindShow = true;
        this[LIST_CUSTOMER_PURCHASES](payload)
          .then((res) => {
            this.items = res;
            console.log(this.items);
            const total = Math.ceil(res.count / 10);
            this.totalPages = Array(total).fill(0).map((e, i) => i + 1);
          })
          .catch((e) => {
            console.log("error=>", e);
          })
          .finally(() => (this.loadindShow = false));
      }
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.InItCustomerPurchaseDetails();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.InItCustomerPurchaseDetails();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.InItCustomerPurchaseDetails();
    },
    
    fetchData(data){
      this.tempItem = data;
    }
  },
  mounted() {
    if (this.customerDetails?.external_id) {
      this.InItCustomerPurchaseDetails();
    } else {
      console.log("Waiting for customerDetails...");
    }
  },
  watch: {
    customerDetails(newVal) {
      if (newVal?.external_id) {
        this.InItCustomerPurchaseDetails();
      }
    }
  }
};
</script>
<style scoped>
  .contant-box-main {
    background: #fff;
    border: 1px solid #ECECED;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 0;
}
.contant-header{
    padding: 20px;
    margin: 0;
    min-height: 74px;
}
.modal-dialog {
    min-width: 68%!important;
}
tr.hidden-border {
    border-style: hidden;
    border-top: 1px solid;
}
/* .button-tr{
  cursor: pointer;
} */

/* .table-wrp{
  height: calc(100vh - -40px);
  overflow-y: auto
} */
.table > :not(:first-child) {
    border-top: 0;
}
table tr th{
  color: #4F4D55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #E9EBED !important;
  background: #565DF20F;
  padding: 12px 20px;
}
table tr td{
  padding: 12px 20px;
  color: #4F4D55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #E9EBED;
}
.table-wrp table tr:hover {
    background: transparent;
}
table.main-button--table{
    margin: 0 auto;
    text-align: center;
    width: auto;
}
.table-wrp .table .main-button--table td{
    padding: 0;
    border: 0;
    width: auto;
}
.eye-view{
    background: transparent;
    border: 0;
    border-radius: 0;
    width: auto;
    height: auto;
    margin: 0 !important;
    padding: 0;
}
nav.main-pagination {padding: 20px;}
</style>

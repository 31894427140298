<template>
  <div class="main-wrp">
    <MainMenu />
    <TopBar title="CustomersNeedRestock" :author="{ home: 'Home' }" />
    <div class="box-wrapper">
      <!-- heading -->
      <div class="data-heading-wrp">
        <div class="row align-items-center justify-content-between">
        
          <div class="col-auto">
            <h5 class="section-title mb-0">
              <img src="@/../public/assets/images/user-square.svg" alt="user-square" />
              Customer Restock Now
            </h5>
          </div>

          <div class="col-auto">
            <div class="action-wrapper d-flex align-items-center gap-4">
              <span
                class="date-display"
                v-if="restock_period_start && restock_period_end"
              >
                {{ restock_period_start }} - {{ restock_period_end }}
                <img src="@/../public/assets/images/calendar.svg" alt="calendar" />
              </span>
              <button
                type="button"
                class="save-btn"
                @click="generateCSV(customer_restock)"
              >
                Export List
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow">
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <table class="table my-table-wrp table-sm" id="my-table">
          <thead>
            <tr>
              <th width="25%">Customer ID</th>
              <th width="25%">Company Name</th>
              <th width="25%">Last Purchase Date</th>
              <th width="25%">Restock Purchase Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in all_customer_restock
                ? all_customer_restock.slice(25 * (page_model - 1), page_model * 25)
                : []"
              :key="customer.id"
            >
              <td class="font-light-text">
                {{ customer.external_id }}
              </td>
              <td class="font-text">
                {{ customer.company_name }}
              </td>
              <td class="font-text">
                {{ customer.last_purchase_date }}
              </td>
              <td class="font-text">
                {{ customer.predicted_restock_date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        class="main-pagination"
        :page="page_model"
        :totalPages="
          all_customer_restock
            ? Array(Math.ceil(all_customer_restock.length / 25))
                .fill(0)
                .map((e, i) => i + 1)
            : []
        "
        :name="'Customers'"
        :count="all_customer_restock ? all_customer_restock.length : 0"
        :incrementpage="incrementpage_model"
        :decrementpage="decrementpage_model"
        :setpage="setpage_model"
        :perpage="25"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET_CUSTOMER_RESTOCK } from "@/Core/store/action-types";
import Pagination from "../../../Core/Pagination.vue";
import TopBar from "@/Menu/TopBar.vue";
import MainMenu from "@/Menu/MainMenu.vue";

export default {
  name: "CustomerNeedRestockView",
  components: {
    Pagination,
    TopBar,
    MainMenu,
  },
  data() {
    return {
      page_model: 1,
      customer_restock: [],
      all_customer_restock: [],
      loadindShow: false,
      restock_period_start: "",
      restock_period_end: "",
      visiblePages: 5, // Number of visible pages in pagination
    };
  },
  methods: {
    ...mapActions("customer", [GET_CUSTOMER_RESTOCK]),
    initCustomerNeedRestock(isAll = true) {
      this.loadindShow = true;
      let params = {
        duration:
          this.customerTime == "month"
            ? 30
            : this.customerTime == "week"
            ? 7
            : this.customerTime == "quater"
            ? 90
            : this.customerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this[GET_CUSTOMER_RESTOCK](params)
        .then((result) => {
          const data = result.results; // Extract the `results` array
          if (isAll) this.all_customer_restock = data.customers;
          else this.customer_restock = data.customers;
          this.restock_period_start = data.restock_period_start;
          this.restock_period_end = data.restock_period_end;
        })
        .catch(() => {
          this.all_customer_restock = [];
          this.restock_period_start = "";
          this.restock_period_end = "";
        })
        .finally(() => (this.loadindShow = false));
    },

    generateCSV() {
      if (this.all_customer_restock.length === 0) {
        console.error("No customer data to export");
        return;
      }
      let csvContent = "";

      // Header
      csvContent +=
        Object.keys(this.all_customer_restock[0] || {})
          .map((e) => `"${e}"`)
          .join(",") + "\n";

      // Data
      this.all_customer_restock.forEach((item) => {
        const row = Object.values(item)
          .map(
            (field) => `"${field ?? "N/A".toString().replace(/"/g, '""')}"` // Escape double quotes and wrap each field in double quotes
          )
          .join(",");
        csvContent += row + "\n";
      });

      // Create a Blob object with the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "customer-need-restock.csv");
      link.click();

      // Clean up the object URL after the download
      URL.revokeObjectURL(url);
    },

    onTimeChange() {
      this.initCustomerNeedRestock();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    setpage_model(page) {
      this.page_model = page;
    },
  },
  mounted() {
    this.initCustomerNeedRestock();
  },
};
</script>
<style scoped>
.box-wrapper {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.main-pagination {
  padding: 20px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table > :not(:first-child) {
  border-top: 0;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-display {
  font-size: 12px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border-radius: 10px;
  padding: 10px;
}
.date-display img {
  margin-bottom: 4px;
  margin-left: 10px;
}
.action-wrapper {
  white-space: nowrap;
}
</style>

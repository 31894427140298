<template>
  <!-- Create article modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createDialogLabel">
            Create Article
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ArticleForm :onSubmit="onSubmit" ref="child" :isEditMode="false"  :onFileUpload="HandleFileUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_ARTICLES,ARTICLE_FILE_UPLOAD,CREATE_PRODUCT} from "@/Core/store/action-types";
import ArticleForm from "./ArticleForm.vue";

export default {
  name: "CreateArticle",
  components: {
    ArticleForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
  },
  data(){
    return {
      tempArticle:{}
    }
  },
  methods: {
    ...mapActions("article", [CREATE_ARTICLES,ARTICLE_FILE_UPLOAD]),
    ...mapActions("product", [CREATE_PRODUCT]),

    onSubmit(values, actions) {
      console.log(values,actions)
       if (values.product  === undefined) {
          this.$refs.child.$refs.productForm.validate().then((result) => {
              if(result.valid){
                this[CREATE_PRODUCT](this.$refs.child.product_formvalues).then((response) => {
                  values.product = response.id
                  this[CREATE_ARTICLES](values) 
                  .then(() => {
                    this.$parent.initArticles(1)
                    window.$(`#${this.modalId}`).modal("hide");
                    this.$alertify.notify(
                      "article successfully created.",
                      "success",
                      3
                    );
                    actions.resetForm();
                  })
                  .catch((e) => {
                    this.$refs.child.formValues.product = response.id
                    console.log(e.message);
                    this.errMsg = e.message
                    actions.setFieldError('detail', e.message || 'something went wrong');
                  }).finally(()=> this.$refs.child.fetchProducts())
                })
                .catch((e) => {
                  console.log(e.message);
                  this.errMsg = e.message
                   this.$refs.child.$refs.productForm.setFieldError('detail', e.message || 'something went wrong');
                })
              }
          })

      }else{
         this[CREATE_ARTICLES](values) 
          .then(() => {
            window.$(`#${this.modalId}`).modal("hide");
            this.$alertify.notify(
              "article successfully created.",
              "success",
              3
            );
            this.
            actions.resetForm();
            this.$parent.initArticles(1);
          })
          .catch((e) => {
            console.log(e.message);
            this.errMsg = e.message
            actions.setFieldError('detail', e.message || 'something went wrong');
          });
      }
    },
    HandleFileUpload(file) {
      if (file) {
        this[ARTICLE_FILE_UPLOAD](file)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            // this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Article Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            // document.getElementById("close-file-import-model").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
      }
    },
  },
  
};
</script>
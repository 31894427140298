<template>
  <div>
    <div>
      <CreateProductModal :modalId="createModalId" />
      <EditProductModal v-bind:modalId="editModalId" v-bind:productToEdit="editedResource" />
      <CustomModel v-if="deletedresource" :modalId="deleteProductModelID" :item="deletedresource"
        :onDelete="deleteProduct" :resourseName="'product'" actionType="Delete" />
      <div>
        <div class="contant-box-main">
          <div class="data-heading-wrp">
            <div class="row">
              <div class="col-lg-6">
                <!-- search box start -->
              <div class="search-box-wrp">
                <input type="text" class="form-control" v-model="search" name=""
                  placeholder="Search by product name" />
                <a class="search_icon" href="#"><img src="/assets/images/search_icon.svg" alt="" /></a>
              </div>
            </div>
              <div class="col-lg-6 text-right">
                <!-- create button start -->
              <div class="data-heading-btn" v-if="createpermission">
                <button @click="showCreateDialog">
                  <img src="@/../public/assets/images/add.svg" alt=""> create new product
                </button>
              </div>
            <!-- create button end -->
              </div>
            </div>
          </div>
          <!-- shipping charge table start -->
          <div class="table-wrp overflow-auto position-relative">
            <div class="loader-main" v-if="loadingShow">
              <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <table class="table my-table-wrp table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    <div class="table-head">
                      {{ f.split("_").join(" ") }}
                    </div>
                <tr></tr>
                </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products.results" v-bind:key="product.id" class="active-row">
                  <td width="10%" class="font-light-text">{{ product.number }}</td>
                  <td width="45%" class="font-text">{{ product.name }}</td>
                  <td width="40%" class="font-light-text">
                    {{ product?.category?.name }}
                  </td>
                  <td class="btn-align" width="5%">
                    <div class="d-flex align-item-center">
                      <button class="action-btns icon-effect" @click="editProduct(product)">
                        <img src="@/../public/assets/images/eye-blue.svg" alt="Logo" />
                      </button>
                      <!-- <button
                        class="action-btns ms-2 delete-profile"
                        @click="showDeleteModel(product)"
                      >
                        <DeleteIcon />
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination :page="page" :totalPages="totalPages" name="products"
              :count="products.count ? products.count : 0" :incrementpage="incrementpage" :decrementpage="decrementpage"
              :setpage="setpage" :perpage="10" />
          </div>
          <!-- product table end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PenIcon from "../icons/pen.vue";
// import DeleteIcon from "../icons/cancel";
import Pagination from "../Core/Pagination.vue";
import { mapActions } from "vuex";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "./product.vars";
import CustomModel from "../Core/CustomModel.vue";
import CreateProductModal from "./components/CreateProductModal.vue";
import EditProductModal from "./components/EditProductModal.vue";
import { LIST_PRODUCTS, DELETE_PRODUCT } from "@/Core/store/action-types";
export default {
  name: "Products",
  components: {
    // DeleteIcon,
    CreateProductModal,
    EditProductModal,
    Pagination,
    // PenIcon,
    CustomModel,
  },
  data() {
    return {
      fields: ["Product Number", "Product Name", "Category", ""],
      products: [],
      search: "",
      page: 1,
      totalPages: [],
      createModalId: "createProductDialog",
      editModalId: "editProductDialog",
      editedResource: {},
      deletedresource: {},
      deleteProductModelID: "deleteProductModel",
      createpermission: this.$ability.can("create", subject(RESOURCE_NAME, {})),
      loadingShow: false
    };
  },
  methods: {
    ...mapActions("product", [
      LIST_PRODUCTS, DELETE_PRODUCT
    ]),
    //get capmain data
    initProducts(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let params = {
        q: this.search.length < 3 ? null : this.search,
        page: this.page,
      };
      this.loadingShow = true
      this[LIST_PRODUCTS]({ persist: true, params }).then((res) => {
        this.products = res;
        this.totalPages = Array(Math.ceil(res.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
      }).finally(() => this.loadingShow = false)
    },
    showDeleteModel(item) {
      this.deletedresource = item;
      window.$(`#${this.deleteProductModelID}`).modal("toggle");
    },
    editProduct(product) {
      // this.onUpdateItem(RESOURCE_NAME, window.$(`#${this.editModalId}`), user);
      (this.editedResource = product), console.log(this.product);
      window.$(`#${this.editModalId}`).modal("toggle");
    },
    async deleteProduct() {
      window.$(`#${this.deleteProductModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[DELETE_PRODUCT](this.deletedresource?.id)
          .then(() => {
            this.initProducts(1);
            this.$alertify.notify(
              `${this.deletedresource.name} successfully removed.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },

    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initProducts();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initProducts();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initProducts();
    },
  },
  mounted() {
    this.initProducts();
  },
  watch: {
    status() {
      this.page = 1;
      this.initProducts();
    },
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initProducts();
      }
    },
  },
};
</script>
<style>
.dashboard-top-section .dts-box {
  background: #ffffff;
  padding: 40px;
}

.dashboard-top-section .dts-box span {
  color: #5e66fa;
  font-weight: 600;
  font-size: 54px;
  line-height: 43px;
  /* margin-bottom: 15px; */
}

.dashboard-top-section .dts-box h6 {
  margin: 0;
  color: #3f3f40;
  font-weight: 500;
  font-size: 18px;
}

.data-heading-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.data-heading-wrp .data-heading {
  position: relative;
}

.data-heading-wrp .data-heading-btn button {
  background: #5e66fa;
  border-radius: 4px;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 11px 19px;
}

.search-box-wrp {
  position: relative;
  min-width: 300px;
  display: inline-block;
  margin-right: 20px;
}

.search-box-wrp input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #aaabad;
}

.search-box-wrp input::placeholder {
  color: #aaabad;
}

.tab-box-wrp {
  position: relative;
  display: inline-block;
}

.tab-box-wrp ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.tab-box-wrp ul li {
  background: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #3f3f40;
  list-style-type: none;
  border: 1px solid #e2e2e2;
  padding: 9px 20px;
  cursor: pointer;
}

.tab-box-wrp ul li:first-child {
  border-radius: 4px 0px 0px 4px;
}

.tab-box-wrp ul li:last-child {
  border-radius: 0px 4px 4px 0px;
}

.tab-box-wrp ul li.active {
  background: #5e66fa;
  color: #fff;
  border: 1px solid #5e66fa;
}

.table-wrp table tr:hover {
  background: #f6f6f7;
}

button.page-link {
  display: inline-block;
}

button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}

.offset {
  width: 500px !important;
  margin: 20px auto;
}

.sort-active {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}

.campaign-status-expired {
  border: none;
  width: 67px;
  height: 32px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #ec4424;
  background: rgba(236, 68, 36, 0.1);
  border-radius: 4px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.campaign-status-active-btn {
  border: none;
  width: 67px;
  height: 32px;
  background: rgba(11, 201, 132, 0.1);
  border-radius: 4px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #0bc984;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.campaign-status-pending {
  border: none;
  width: 67px;
  height: 32px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #f29d4f;
  background: rgba(242, 157, 79, 0.1);
  border-radius: 4px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.action-btns {
  background: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 36px;
  min-height: 36px;
}
</style>
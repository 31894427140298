<template>
    <div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
          <div class="loader-main" v-if="loadindShow">
            <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Article ID</th>
                <th>Article Name</th>
                <th>Warehouse Code</th>
                <th>Quantity</th>
                <th>Turnover Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(article) in displayedArticles" :key="article.article_id">
                <td>{{ article.sku }}</td>
                <td>{{ article.name }}</td>
                <td>{{ article.warehouse_code }}</td>
                <td>{{ article.quantity }}</td>
                <td>{{ article.turnover }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <div class="contant-footer d-flex justify-content-end" v-if="articles.length > 5">
        <router-link :to="{
        name: `InventoryViewAll`,
        params: { api: 'inventoryTurnOver' },
      }">
          <a
            role="button"
            data-toggle="modal"
            class="view-more-btn"
        >
          View All
          <img src="@/../public/assets/images/right-sky.png" alt="" />
        </a>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { API_URL } from "@/Core/helpers/utils";
  
  export default {
    name: "InventoryTurnOver",
    props: {
      inventoryTab: {
        type: String,
        required: true,
        default: 'top'
      }
    },
    data() {
      return {
        articles: [],
        loadindShow: false
      };
    },
    computed: {
      displayedArticles() {
        return this.articles.slice(0, 10);
      },
      apiEndpoint() {
        return `${API_URL}/api/v1/warehouse/${this.inventoryTab}-inventory-turnover/`;
      }
    },
    mounted() {
      this.fetchArticles();
    },
    methods: {
      async fetchArticles() {
        const token = this.$store.getters['user/userProfile'].access;
        this.loadindShow = true;
        try {
          const response = await axios.get(this.apiEndpoint, {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.articles = response.data;
        } catch (error) {
          console.error('Error fetching articles:', error.response ? error.response.data : error.message);
        } finally {
          this.loadindShow = false;
        }
      }
    },
    watch: {
      inventoryTab: {
        handler() {
          this.fetchArticles();
        },
        immediate: true
      }
    }
  };
  </script>
  
  <style scoped>
  .table td {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .table th:nth-child(1),
  .table td:nth-child(1) {
    max-width: 100px;
  }
  
  .table th:nth-child(2),
  .table td:nth-child(2) {
    max-width: 150px;
  }
  
  .table th:nth-child(3),
  .table td:nth-child(3) {
    max-width: 150px;
  }
  
  .view-more-btn {
    color: #5f66fa !important;
    font-weight: 500;
  }
  </style>
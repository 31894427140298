<template>
  <div class="customer-details-box">
    <div class="contant-header">
      <h6>
        <img
          src="./../../../public/assets/images/user-square.svg"
          alt=""
        />Customer details
      </h6>
      <div class="delete-status-box">
        <!-- delete customer model start -->
        <CustomModel
          :modalId="deleteCustomerModelId"
          :item="customerDetails"
          :onDelete="deleteCustomer"
          :resourseName="'customer'"
          actionType="Delete"
        ></CustomModel>
        <!-- delete customer model end -->
          <div class="data-heading-btn">
            <button @click="showConnectWarehouseDialog">Connect to Warehouse</button>
          </div>
        <div class="status-box">
          <label :class="customerDetails?.is_active ? 'active-status m-0' : 'm-0'">
            <span>Active</span>
          </label>
          <label
            :class="customerDetails?.is_active ? 'm-0' : 'inactive-status m-0'"
          >
            <span>Inactive</span>
          </label>
        </div>
        <button
        title="Delete Customer"
        v-show="hasPermission"
        class="delete-profile"
        @click="showDeleteDialog"
      >
      <!-- @click="deleteCustomer(JSON.parse(JSON.stringify(customerDetails)))" -->
      <img src="./../../../public/assets/images/trash.svg" alt="trash"/>
      </button>
        <div v-if="customerDetails" class="edit-button form-btns">
          <button type="button" class="cancle-btn" @click="csvExport()">
            GDPR extract
          </button>
          <button
            type="submit"
            class="save-btn"
            @click.prevent="
              this.$router.push({
                name: 'EditCustomer',
                params: { customerId: customerDetails.id },
              })
            "
          >
          <img src="./../../../public/assets/images/edit.svg" alt="edit"/>
            Edit
          </button>
        </div>
      </div>
    </div>

    <!-- Connect Warehouse modal Window -->
    <div class="modal fade" id="connectWarehouseModel" tabindex="-1" role="dialog" aria-labelledby="createDialogLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createDialogLabel">
              Connect to Warehouse
            </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div>
                <label for="parent">Select National/Distribution Warehouse</label>
                <select class="form-select form-control" name="parent" v-model="selectedWarehouse">
                  <option value="Select a warehouse" disabled selected>Select a warehouse</option>
                  <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                    {{ warehouse.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="cancle-btn" data-bs-dismiss="modal"> Cancel </button>
            <button type="button" class="save-btn" @click="connectToWarehouse">Connect</button>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-details position-relative">
      <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
      <div class="profile-field_wrp">
        <div class="profile_field_data">
          <h6 class="profile-title">Company Name</h6>
          <p class="profile-title">{{ customerDetails?.company_name || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">First Name</h6>
          <p class="profile-title">{{ customerDetails?.first_name || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Last Name</h6>
          <p class="profile-title">{{ customerDetails?.last_name || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Phone</h6>
          <p class="profile-title">{{ customerDetails?.phone  || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Postal code</h6>
          <p class="profile-title">{{ customerDetails?.postal_id  || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">City</h6>
          <p class="profile-title">{{ customerDetails?.city  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">State</h6>
          <p class="profile-title">{{ customerDetails?.state  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Country</h6>
          <p class="profile-title">{{ customerDetails?.country  || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Internal ID</h6>
          <p class="profile-title">{{ customerDetails?.id  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">External ID</h6>
          <p class="profile-title">
            {{ customerDetails?.external_id ? customerDetails?.external_id : "-" }}
          </p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Allows Marketing Campaigns</h6>
            <p class="profile-title" v-if ="customerDetails?.allow_marketing">
            Yes
          </p>
          <p class="profile-title" v-else>
            No
          </p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Warehouse</h6>
          <p class="profile-title">{{ customerDetails?.warehouse ? customerDetails?.warehouse.name : "-" }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Churn prediction</h6>
          <p class="profile-title capitalize">
            {{ customerDetails?.churn_prediction ? customerDetails?.churn_prediction.churn_status : "-" }}
            {{ customerDetails?.churn_prediction ? "(" + customerDetails?.churn_prediction?.churn_period[0] + " - " : "" }}
            {{ customerDetails?.churn_prediction ? customerDetails?.churn_prediction?.churn_period[1] + ")" : "" }}
          </p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Email</h6>
          <p class="profile-title">{{ customerDetails?.email || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Address</h6>
          <p class="profile-title">{{ customerDetails?.address  || '-' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { API_URL } from "@/Core/helpers/utils";
import { mapActions, mapGetters } from "vuex";
import { subject } from "@casl/ability";
import {
  CUSTOMER_DETAILS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_GDPR_DATA,
} from "@/Core/store/action-types";
// import { deleteItem } from "@/Core/helpers/gridUtils";
import { RESOURCE_NAME } from "../customer.vars";
import CustomModel from "../../Core/CustomModel.vue";
import router from "../../Router";
export default {
  name: "CustomerDetails",
  components: {
    CustomModel,
  },
  props: {
    profileDetails: {
      type: Object,
    },
  },
  data() {
    return {
      deleteCustomerModelId: "deleteCustomerModel",
      loadindShow:false,
      hasPermission: this.$ability.can(
            "delete",
            subject(RESOURCE_NAME, {})
          ),
      warehouses: [],
      selectedWarehouse: null,
    };
  },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("customer", [
      CUSTOMER_DETAILS,
      UPDATE_CUSTOMER,
      DELETE_CUSTOMER,
      GET_GDPR_DATA,
    ]),

    showConnectWarehouseDialog() {
      this.fetchWarehouses();
			window.$(`#connectWarehouseModel`).modal("toggle");
		},

    async fetchWarehouses() {
      const token = this.$store.getters['user/userProfile'].access;
      try {
        const response = await axios.get(`${API_URL}/api/v1/national-warehouses/?member_id=${this.customerDetails.external_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.warehouses = response.data;
        console.log("Warehouses===>", this.warehouses);
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }
    },

    async connectToWarehouse() {
      const token = this.$store.getters['user/userProfile'].access;
      try {
        const response = await axios.patch(`${API_URL}/api/v1/national-warehouses/`, {
          member_id: this.customerDetails.external_id,
          warehouse_id: this.selectedWarehouse
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("Connection successful:", response.data);
        this.$alertify.notify("Member connected to warehouse successfully.", "success", 3);
        window.$(`#connectWarehouseModel`).modal("hide"); // Close the modal
        this.InItCustomerDetails();
      } catch (error) {
        console.error('Error connecting to warehouse:', error);
        this.$alertify.notify("Failed to connect member to warehouse.", "error", 3);
      }
    },

    //get customer details
    InItCustomerDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") { 
        const params={
          customerId:this.$route.params.customerId,
          currency:this.default_currency
        }
        this.loadindShow = true
        this[CUSTOMER_DETAILS](params).then(() => {
          // this.hasPermission = this.$ability.can(
          //   "delete",
          //   subject(RESOURCE_NAME, this.customerDetails)
          // );
        }).finally(()=>this.loadindShow=false)
      }
    },
    // deleteCustomer(item) {
    //   console.log(this.$ability.can("delete", subject(RESOURCE_NAME, item)));
    //   deleteItem(
    //     this.$alertify,
    //     this.$ability,
    //     this[DELETE_CUSTOMER],
    //     RESOURCE_NAME,
    //     item
    //   );
    // },

    //delete customer
    deleteCustomer(checkStatus) {
      if (checkStatus) {
        this.csvExport();
      }
      window.$(`#${this.deleteCustomerModelId}`).modal("hide");
      this[DELETE_CUSTOMER](this.customerDetails.id).then(() => {
        this.$alertify.notify(
          `${this.customerDetails.name} successfully removed.`,
          "success",
          3
        );
        router.push("/Customers");
      });
    },
    //export GDPR data
    csvExport() {
      this[GET_GDPR_DATA](this.customerDetails.id).then((resp) => {
        let arrData = [
          {
            personalDetails: [resp.member_profile],
            receipts: resp.orderlines,
            campaigns: resp.campaigns,
          },
        ];
        console.log("ArrrrData===>", arrData);
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "Personal Details,",
          "\n" + Object.keys(arrData[0].personalDetails[0] || {}).join(","),
          ...arrData[0].personalDetails.map((item) =>
            Object.values(item).join(",")
          ),
        ].join("\n");
        if (arrData[0].receipts.length > 0) {
          csvContent += [
            "\n\n\n\n" + "Order History,",
            "\n" + Object.keys(arrData[0].receipts[0] || {}).join(","),
            ...arrData[0].receipts.map((item) => Object.values(item).join(",")),
          ].join("\n");
        }
        if (arrData[0].campaigns.length > 0) {
          csvContent += [
            "\n\n\n\n" + "Campaigns,",
            "\n" + Object.keys(arrData[0].campaigns[0] || {}).join(","),
            ...arrData[0].campaigns.map((item) =>
              Object.values(item).join(",")
            ),
          ].join("\n");
        }
        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", `GDPR_${this.customerDetails.id}.csv`);
        link.click();
      });
    },
    //open delete dialog
    showDeleteDialog() {
      window.$(`#${this.deleteCustomerModelId}`).modal("toggle");
    },
  },
  mounted() {
    this.InItCustomerDetails();
  },
};
</script>
<style scoped>
.customer-details-box{
  background: #fff;
    border: 1px solid #ECECED;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
    border-radius: 12px;
    margin-bottom: 20px;
}
.customer-details-box .contant-header {
    border-bottom: 1px solid #ECECED;
    padding: 20px;
    margin: 0;
}
.customer-details-box .data-heading-btn button{
  border: 0;
  border-radius: 5px;
  background: #565DF21A;
  color: #565DF2;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 15px;
}
.status-box {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 30px;
}
.status-box label span {
    border: 1px solid #1A2B3A1A;
    padding: 7px 15px;
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1A2B3A;
}
.active-status span {
    background: #12B76A;
    color: #fff !important;
    border-color: #12B76A !important;
}
.inactive-status span {
    background: #F04438;
    color: #FFFFFF !important;
    border-color: #F04438 !important;
}
.delete-profile {
    border: 1px solid #F044381A;
    background: transparent;
    border-radius: 5px;
    padding: 0;
    margin-left: 0;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.delete-profile:hover {
    background: transparent;
    border-color: #F044381A;
}
.edit-button {
    margin-top: 0;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 15px;
}
.edit-button .save-btn{
  font-weight: 400;
    margin: 0;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.edit-button .save-btn:hover{
  border: 1px solid #565DF2;
    background: #565DF2;
    color: #fff;
}
.cancle-btn {
    border: 1px solid #565DF280;
    background: #fff;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #565DF2;
    font-weight: 400;
}
.cancle-btn:hover{
  background: transparent;
}
.profile-details{
  padding: 20px;
}
.profile_field_data {
    display: block;
    padding: 0;
    border-bottom: 0;
    min-height: auto;
    max-width: 200px;
}
.profile_field_data .profile-title {
    color: #1A2B3A;
    margin: 0;
    width: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
    opacity: 0.8;
    word-break: break-word;
}
.profile_field_data p.profile-title{
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.profile-field_wrp {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 60px;
}
.modal .modal-body label {
    color: #1A2B3A;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
}
.modal .modal-body .form-control {
    border: 0;
    border-radius: 10px;
    background-color: #F5F7F9 !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #242220;
    height: 46px;
    padding: 13px;
}
select{
  background-image:url('../../image/arrow-down.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 13px;
    font-family: "Outfit", sans-serif !important;
    background-size: 20px;
}
</style>
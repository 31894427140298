<template>
  <div class="contant-box-main customer-list-box p-0">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img
          src="@/../public/assets/images/user-square.svg" 
            alt="user-square"
          />
          Most Valuable Customers
        </h6>
        <div>
          <select
            class="for"
            id="adminfilter"
            v-model="valuablecustomerTime"
            @change="onTimeChange"
          >
            <option v-for="option in timeOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Company Name</th>
                <th>Amount</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in highest_ordering_customers" :key="customer.id">
                <td class="font-light-text" width="15%">
                  {{ customer.id }}
                </td>
                <td width="40%" class="font-text">
                  <router-link :to="{ name: 'CustomerDetailsGrid', params: { customerId: customer.id } }">
                    {{ customer.company_name }}
                  </router-link>
                </td>
                <td class="font-light-text" width="15%">
                  {{ customer.total_value.toFixed(0) }}
                </td>
                <td class="font-light-text" width="10%">
                  {{ customer.currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <router-link to="/MostValuableCustomerView">
      <a
        role="button"
        class="view-more-btn"
        @click="initValuableCustomer(true)"
      >
        View All
        <img src="@/../public/assets/images/right-sky.png" alt="" />
      </a>
      </router-link>
    </div>
  </div>
  <!-- most valuable customer list model -->
  <div
    class="modal fade"
    id="valubaleCustomerListModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="valubaleCustomerListModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="valubaleCustomerListModelTitle">
            Most valuable customers
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="contant-header">
                    <h6>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBjVJBbtpQEJ0Z29+N2lS+QeEGcIKSE5TuqihBlhrSRReQEwROELproBWmRN2GngBuEHIDjkClVBQb/8kMwY4SJQqz8HyP/3vz3owRNrEf3pQc3zTAQkFeB8OeiWCLIH18ChcF1zNjtFDVCiL0D+txf2sCz3dOAWFOTloenps9YPgmJOHnr/xuKwLpGQhoHn3fmembZRxpXi7/F18icNdwtteiu1o7Tk4t4DWBPZPy7OLHziS7WKvHDUktURowYGfY9U5yBb+6fktlA3BLwJdyDojjjxn44CipCLDDAFO5M0DgZu142YJ7C0LSM02itLjiuEyvvWLUezPNfZKtaJbN7EmzUI5TZnyfW6iGHLw1q4ZNuemiE6T/ksnB0aKdWbApTMkRGxuLUikRQZQr2PWSS5XPyALgtmygQOSMhWTd+eKnP3pgUeaDmLbX81N/RDxmsCfD7quOFkNRZE1yxQwzlZ1Z2a/flDT/fmAv87cBa0QRzqXjH1FSySyqfBfM2EVzdfgl7uvPl8/gyUCey2N93PWTMyEMUXzLJv4iwwdjnIooLT9PsAntJIBQZzM4l3WDrnUx0hmlXlIlZjtjhMljYFYnzwk0rzgZZd90O3eYFG4Bk3jRLFulvBYAAAAASUVORK5CYII="
                        alt=""
                      />
                      Most Valuable Customers
                    </h6>
                    <button
                      type="button"
                      class="save-btn"
                      @click="generateCSV()"
                    >
                      Export List
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                        <div
                          class="spinner-border"
                          role="status"
                          style="width: 36px; height: 36px; color: #5e66fa"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                          <tr>
                            <th width="10%">Customer ID</th>
                            <th width="30%">Company Name</th>
                            <th width="20%">Total Amount</th>
                            <th width="20%">Currency</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="customer in all_most_valuable_customers ? all_most_valuable_customers.slice(10 * (page_model - 1), page_model * 10) : []"
                            :key="customer.id"
                          >
                            <td class="font-light-text">
                              {{ customer.id }}
                            </td>
                            <td class="font-text">
                              {{ customer.company_name }}
                            </td>
                            <td class="font-light-text">
                              {{ customer.total_value.toFixed(0) }}
                            </td>
                            <td class="font-light-text">
                              {{ customer.currency }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      :page="page_model"
                      :totalPages="all_most_valuable_customers ? Array(Math.ceil(all_most_valuable_customers.length / 10)).fill(0).map((e, i) => i + 1) : []"
                      :name="'Customers'"
                      :count="all_most_valuable_customers ? all_most_valuable_customers.length : 0"
                      :incrementpage="incrementpage_model"
                      :decrementpage="decrementpage_model"
                      :setpage="setpage_model"
                      :perpage="10"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import {
  CREATE_AUDIENCE,
  GET_MOST_VALUABLE_CUSTOMERS,
} from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";
export default {
  name: "MostValuableCustomersCard",
  components: {
    Pagination,
  },
  data() {
    return {
      page_model: 1,
      highest_ordering_customers: [],
      all_most_valuable_customers: [],
      valuablecustomerTime: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      createAudienceMode: false,
      nameError: false,
      errMsg: "",
      audienceName: "",
      loadindShow:false,
    };
  },
   computed: {
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("audience", [CREATE_AUDIENCE]),
    ...mapActions("customer", [GET_MOST_VALUABLE_CUSTOMERS]),
    initValuableCustomer(isAll = false) {
      this.loadindShow = true
      let params = {
        currency:this.default_currency,
        duration:
          this.valuablecustomerTime == "month"
            ? 30
            : this.valuablecustomerTime == "week"
            ? 7
            : this.valuablecustomerTime == "quater"
            ? 90
            : this.valuablecustomerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this.loadindShow = true
      this[GET_MOST_VALUABLE_CUSTOMERS](params)
        .then((result) => {
          if (isAll) this.all_most_valuable_customers = result;
          else this.highest_ordering_customers = result;
        })
        .catch(() => (this.highest_ordering_customers = []))
        .finally(()=>this.loadindShow=false)
    },
    onTimeChange() {
      this.initValuableCustomer();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    validateName() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    generateCustomerIdArray() {
      let customerIds = [];
      this.all_most_valuable_customers.map((customer) =>
        customerIds.push(customer.id)
      );
      return customerIds;
    },
    onAudienceCreate() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.all_most_valuable_customers.length <= 0) {
        this.nameError = false;
        this.$alertify.notify(
          "There in no customers to create audience",
          "error",
          3
        );
      } else {
        this.nameError = false;
        let payload = {
          members: this.generateCustomerIdArray(),
          name: this.audienceName,
          articles: [],
          article_pref_type:'',
          gender: "All",
          churn_status: 'All',
          age_min: 0,
          age_max: 100,
        };
        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audienceName} successfully created`,
              "success",
              3
            );
            this.createAudienceMode = false;
          })
          .catch((e) => {
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    generateCSV() {
      console.log("Total customers:", this.all_most_valuable_customers.length); // Debug: Check how many customers are loaded

      if (this.all_most_valuable_customers.length === 0) {
        console.error("No customers data available to export.");
        return; // Exit if no customers data is available
      }

      let arrData = [
        {
          customers: this.all_most_valuable_customers,
        },
      ];
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0].customers[0]).join(","),
        ...arrData[0].customers.map(item => Object.values(item).join(","))
      ].join("\n");
      csvContent = csvContent.replace (/#/g, '');

      console.log("csvContent :",csvContent)

      const data = encodeURI(csvContent);
      console.log("🚀 ~ generateCSV ~ data:", data)
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", `valuable-customers-${this.valuablecustomerTime}.csv`);
      document.body.appendChild(link); // Append link to body to ensure visibility in DOM
      link.click();
      document.body.removeChild(link); // Clean up: remove link after clicking
    },
  },
  mounted() {
    this.initValuableCustomer();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
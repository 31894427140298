<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none">
        <rect y="0.5" width="44" height="44" rx="10" fill="#585BEF" fill-opacity="0.1"/>
        <g clip-path="url(#clip0_169_2843)">
        <path d="M13.4697 30.6197H23.5018V29.3271H13.4697C12.8767 29.3278 12.3076 29.0931 11.8873 28.6747C11.4671 28.2562 11.2301 27.6881 11.2282 27.095V15.5247C11.2301 14.9317 11.4671 14.3636 11.8873 13.9451C12.3076 13.5266 12.8767 13.292 13.4697 13.2927H15.9387V16.9186C15.9393 17.4327 16.1438 17.9256 16.5073 18.2891C16.8708 18.6526 17.3637 18.8571 17.8778 18.8577H22.2535C22.7676 18.8571 23.2604 18.6526 23.6239 18.2891C23.9875 17.9256 24.1919 17.4327 24.1925 16.9186V13.2927H26.6615C27.2546 13.292 27.8237 13.5266 28.2439 13.9451C28.6642 14.3636 28.9012 14.9317 28.903 15.5247V22.2264H30.1957V15.5247C30.1934 14.589 29.82 13.6923 29.1575 13.0315C28.4949 12.3707 27.5973 11.9998 26.6615 12H13.4697C12.534 11.9998 11.6363 12.3707 10.9738 13.0315C10.3112 13.6923 9.93783 14.589 9.93555 15.5247V27.095C9.93783 28.0308 10.3112 28.9274 10.9738 29.5882C11.6363 30.249 12.534 30.62 13.4697 30.6197ZM17.2314 13.2927H22.8998V16.9186C22.8996 17.09 22.8314 17.2543 22.7103 17.3754C22.5891 17.4966 22.4248 17.5648 22.2535 17.565H17.8778C17.7064 17.5648 17.5421 17.4966 17.421 17.3754C17.2998 17.2543 17.2317 17.09 17.2314 16.9186V13.2927Z" fill="#565DF2"/>
        <path d="M13.373 26.1202H17.3515V27.4129H13.373V26.1202ZM13.373 23.5331H18.9889V24.8258H13.373V23.5331ZM29.2726 23.415C28.3247 23.415 27.3982 23.6961 26.6101 24.2227C25.822 24.7493 25.2077 25.4978 24.845 26.3735C24.4822 27.2492 24.3873 28.2127 24.5723 29.1424C24.7572 30.072 25.2136 30.9259 25.8838 31.5962C26.5541 32.2664 27.408 32.7228 28.3376 32.9077C29.2673 33.0927 30.2308 32.9977 31.1065 32.635C31.9822 32.2723 32.7307 31.658 33.2573 30.8699C33.7839 30.0818 34.065 29.1553 34.065 28.2074C34.0636 26.9368 33.5582 25.7187 32.6598 24.8202C31.7613 23.9218 30.5432 23.4164 29.2726 23.415ZM29.2726 31.7071C28.5804 31.7071 27.9038 31.5019 27.3282 31.1173C26.7527 30.7328 26.3041 30.1862 26.0393 29.5467C25.7744 28.9072 25.7051 28.2035 25.8401 27.5247C25.9751 26.8458 26.3085 26.2222 26.7979 25.7328C27.2873 25.2433 27.9109 24.91 28.5898 24.775C29.2687 24.6399 29.9724 24.7092 30.6118 24.9741C31.2513 25.239 31.7979 25.6876 32.1825 26.2631C32.567 26.8386 32.7723 27.5153 32.7723 28.2074C32.7713 29.1353 32.4022 30.0249 31.7461 30.681C31.09 31.3371 30.2004 31.7061 29.2726 31.7071Z" fill="#565DF2"/>
        <path d="M29.0044 28.3568L28.1112 27.4636L27.1973 28.3771L28.5477 29.7275C28.6689 29.8486 28.8332 29.9165 29.0044 29.9165C29.1757 29.9165 29.34 29.8486 29.4612 29.7275L31.8992 27.29L30.9852 26.3765L29.0044 28.3568Z" fill="#565DF2"/>
        </g>
        <defs>
        <clipPath id="clip0_169_2843">
        <rect width="24" height="24" fill="white" transform="translate(10 10.5)"/>
        </clipPath>
        </defs>
        </svg>
</template>

<script>
export default {
    name: "InventoryIcon",
};
</script>

<style scoped></style>
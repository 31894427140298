<template>
  <div class="listWarehouse">
    <div class="accordion" v-for="warehouse in filteredWarehouses" :key="warehouse.id">
      <div class="handle">
        <div class="content-model">
          <span @click="showUploadDialog"
            >{{ warehouse.name }} ({{ warehouse.type }})</span
          >
          <div class="button-group" style="float: right">
            <!-- Conditional rendering for central/production warehouses -->
            <template v-if="warehouse.type === 'central/production'">
              <button
                class="btn btn-primary"
                @click="showUpdateStockDialog(warehouse.id)"
              >
                <img src="@/../public/assets/images/stock.svg" alt="" /> Update stock
                levels
              </button>
              <button class="btn btn-success" @click="showMoveStockDialog(warehouse.id)">
                <img src="@/../public/assets/images/truck.svg" alt="" /> Shipping
              </button>
            </template>
          </div>
        </div>
        <div class="arrow-container" @click="toggleAccordion">
          <span class="arrow"
            ><img src="@/../public/assets/images/arrow-down.svg" alt=""
          /></span>
        </div>
      </div>
      <div class="content">
        <div class="content-table">
          <div class="table-wrp overflow-auto position-relative">
            <table id="table" class="table my-table-wrp table-sm">
              <tr>
                <th>Article ID</th>
                <th>Article name</th>
                <th>Inventory (no of)</th>
                <th>Inventory expected to last</th>
                <th>Volume in transit</th>
                <!-- <th>Inventory status</th> -->
                <th></th>
              </tr>
              <tr v-for="item in getWarehouseData(warehouse).slice(0, 10)" :key="item.id">
                <td>
                  {{
                    warehouse.type === "central/production" ? item.sku : item.article_sku
                  }}
                </td>
                <td>
                  {{
                    warehouse.type === "central/production"
                      ? item.name
                      : item.article_name
                  }}
                </td>
                <td>
                  {{
                    warehouse.type === "central/production"
                      ? item.central_stock
                      : item.quantity
                  }}
                </td>
                <td>{{ warehouse.average_shipping_days + " days" }}</td>
                <td>
                  {{
                    warehouse.type === "central/production"
                      ? item.volume_in_transit
                      : item.volume_in_transit
                  }}
                </td>
                <td>
                  <button
                    v-if="
                      warehouse.type === 'national/distribution' &&
                      warehouse.stock_data.some(
                        (stock) =>
                          stock.article_status === 'on hold' &&
                          stock.article === item.article
                      )
                    "
                    class="btn btn-primary"
                    @click="
                      confirmShipping(item.article, warehouse.id, item.volume_in_transit)
                    "
                  >
                    Confirm Shipping
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="contant-footer d-flex justify-content-center pt-0"
          v-if="getWarehouseData(warehouse).length > 10"
        >
          <a
            role="button"
            data-toggle="modal"
            data-target="#viewAllModel"
            class="view-more-btn"
            @click="showViewAllModal(warehouse)"
          >
            View All <img src="@/../public/assets/images/right-sky.png" alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>

  <!-- View All Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="viewAllModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="viewAllModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewAllModelTitle" v-if="clickedWarehouse">
            {{ clickedWarehouse.name }}
          </h5>
          <h5 v-else>Loading warehouse details...</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="contant-details">
            <form class="profile-form">
              <div class="model_table">
                <div class="table-wrp overflow-auto position-relative">
                  <div class="loader-main" v-if="loadindShow">
                    <div
                      class="spinner-border"
                      role="status"
                      style="width: 36px; height: 36px; color: #5e66fa"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <table class="table my-table-wrp table-sm" id="my-table">
                    <thead>
                      <tr>
                        <th>Article ID</th>
                        <th>Article name</th>
                        <th>Inventory (no of)</th>
                        <th>Inventory expected to last</th>
                        <th>Volume in transit</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="article in allArticles
                          ? allArticles.slice(10 * (page_model - 1), page_model * 10)
                          : []"
                        :key="article.article_id"
                        :class="
                          checkedFilter(article.article_id) ? 'active-row selected' : ''
                        "
                      >
                        <td>
                          {{
                            clickedWarehouse.type === "central/production"
                              ? article.sku
                              : article.article_sku
                          }}
                        </td>
                        <td>
                          {{
                            clickedWarehouse.type === "central/production"
                              ? article.name
                              : article.article_name
                          }}
                        </td>
                        <td>
                          {{
                            clickedWarehouse.type === "central/production"
                              ? article.central_stock
                              : article.quantity
                          }}
                        </td>
                        <td>{{ clickedWarehouse.average_shipping_days + " days" }}</td>
                        <td>
                          {{
                            clickedWarehouse.type === "central/production"
                              ? article.volume_in_transit
                              : article.volume_in_transit
                          }}
                        </td>
                        <td>
                          <button
                            v-if="
                              clickedWarehouse.type === 'national/distribution' &&
                              clickedWarehouse.stock_data.some(
                                (stock) =>
                                  stock.article_status === 'on hold' &&
                                  stock.article === article.article
                              )
                            "
                            class="btn btn-primary"
                            @click="
                              confirmShipping(
                                article.article,
                                clickedWarehouse.id,
                                article.volume_in_transit
                              )
                            "
                          >
                            Confirm Shipping
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Pagination
                  :page="page_model"
                  :totalPages="
                    allArticles
                      ? Array(Math.ceil(allArticles.length / 10))
                          .fill(0)
                          .map((e, i) => i + 1)
                      : []
                  "
                  :name="'Articles'"
                  :count="allArticles ? allArticles.length : 0"
                  :incrementpage="incrementpage_model"
                  :decrementpage="decrementpage_model"
                  :setpage="setpage_model"
                  :perpage="10"
                />
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Move Stock Modal -->
  <div
    class="modal fade"
    id="moveStockModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="moveStockLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="moveStockLabel">Move Stock</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="warehouse-select">Select Warehouse (national/distribution)</label>
            <select
              v-model="selectedWarehouse"
              @change="updateArticles"
              class="form-select form-control"
            >
              <option
                v-for="warehouse in nationalWarehouses"
                :key="warehouse.id"
                :value="warehouse.id"
              >
                {{ warehouse.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="article-select">Select Article</label>
            <select
              v-model="selectedArticle"
              @change="updateMaxStock"
              class="form-control form-control"
            >
              <option v-for="article in articles" :key="article.id" :value="article.id">
                {{ article.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="move-stock">Move Stock</label>
            <input
              type="number"
              class="form-control"
              id="move-stock"
              v-model="moveStock"
              :max="maxStock"
              placeholder="Enter quantity"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="cancle-btn" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="save-btn" @click="saveMoveStock">Move</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Update Stock Levels Modal -->
  <div
    class="modal fade"
    id="updateStockModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="updateStockLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateStockLabel">{{ selectedWarehouseName }}</h5>
          <!-- Display warehouse name -->
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="article-select">Select Article</label>
            <select
              class="form-select form-control"
              v-model="selectedArticle"
              id="article-select"
              @change="updateCurrentStock"
            >
              <option v-for="article in articles" :key="article.id" :value="article.id">
                {{ article.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="current-stock">Current Stock Level</label>
            <input
              type="number"
              class="form-control"
              id="current-stock"
              v-model="currentStock"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="new-stock">Update Stock Level</label>
            <input type="number" class="form-control" id="new-stock" v-model="newStock" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="cancle-btn" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="save-btn" @click="updateStockLevels">Add</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Update Shipping Days modal window -->
  <div
    class="modal fade"
    id="UpdateShippingModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createDialogLabel">Update Shipping Days</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group shipping-from-group">
            <label for="warehouse-type"
              >Warehouse Type<span class="required-field text-danger">*</span></label
            >
            <select
              v-model="selectedWarehouseType"
              id="warehouse-type"
              class="form-control"
            >
              <option v-for="type in uniqueWarehouseTypes" :key="type">{{ type }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="shipping-days"
              >Average no of shipping days from Central to National<span
                class="required-field text-danger"
                >*</span
              ></label
            >
            <input
              type="number"
              class="form-control"
              id="shipping-days"
              v-model="shippingDays"
              placeholder="Average no of shipping days from Central to National"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="cancle-btn" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="save-btn" @click="updateShippingDays">Save</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Confirm Shipping Modal -->
  <div
    class="modal fade"
    id="confirmShippingModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmShippingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmShippingModalLabel">Confirm Shipping</h5>
          <button type="button" class="close" @click="closeModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            Would you like to add the incoming volume of
            <span class="highlight">{{ incomingVolume }}</span> items to the article
            stock?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="cancle-btn" @click="closeModal">No</button>
          <button type="button" class="save-btn" @click="confirmShippingAction">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { API_URL } from "@/Core/helpers/utils";
import Pagination from "@/Core/Pagination";

export default {
  components: { Pagination },
  props: {
    activeTab: {
      type: String,
      required: true,
      default: "all",
    },
  },
  data() {
    return {
      formData: {
        name: "",
        warehouse_type: "",
        supplier: "",
      },
      // remove this later
      shippingDays: [],
      selectedWarehouseType: "asd",

      filteredWarehouses: [],
      warehouse_data: [],
      centralWarehouses: [],
      nationalWarehouses: [],

      warehouse_type_initiate: ["central/production", "national/distribution"],
      selectedWarehouseId: null, // Initialize selectedWarehouseId
      articles: [], // Initialize articles
      selectedWarehouse: null, // For selected warehouse
      clickedWarehouse: null, // For selected warehouse
      selectedArticle: null, // For selected article
      moveStock: 0,
      maxStock: 0,
      selectedWarehouseName: "", // To hold the name of the selected warehouse
      currentStock: 0, // To hold the current stock level
      newStock: 0, // To hold the new stock level input
      incomingVolume: 0,
      allArticles: [], // Data to display in the modal
      page_model: 1, // Pagination control for modal
      loadindShow: false, // Loading state for modal data
      choosen_articles: [],
    };
  },
  mutations: {
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
  },
  mounted() {
    document.querySelectorAll(".arrow-container").forEach((el) => {
      el.addEventListener("click", this.toggleAccordion);
    });

    window.$("#viewAllModel").on("hide.bs.modal", this.resetModalFields);
    this.fetchInitialData();
  },
  computed: {
    ...mapState("user", ["userProfile"]),

    uniqueWarehouseTypes() {
      return [...new Set(this.warehouse_data.map((warehouse) => warehouse.type))];
    },
  },
  watch: {
    activeTab: {
      handler: "updateFilteredWarehouses",
      immediate: true,
    },
  },
  methods: {
    ...mapActions("user", ["fetchInitialData"]),

    updateFilteredWarehouses() {
      if (this.activeTab === "all") {
        this.filteredWarehouses = this.warehouse_data;
      } else if (this.activeTab === "national") {
        this.filteredWarehouses = this.nationalWarehouses;
      } else if (this.activeTab === "central") {
        this.filteredWarehouses = this.centralWarehouses;
      }
    },

    getWarehouseData(warehouse) {
      // Return the appropriate data based on warehouse type
      return warehouse.type === "central/production"
        ? warehouse.article_data
        : warehouse.stock_data;
    },

    showViewAllModal(warehouse) {
      this.loadindShow = true;
      this.clickedWarehouse = warehouse;

      // Get the warehouse data based on the current filter
      const currentWarehouse = this.filteredWarehouses.find((w) => w.id === warehouse.id);
      console.log("currentWarehouse", currentWarehouse);
      if (currentWarehouse) {
        this.allArticles = this.getWarehouseData(currentWarehouse);
      } else {
        this.allArticles = []; // Fallback to empty array if warehouse not found
      }

      this.page_model = 1;
      this.loadindShow = false;
    },

    AddRemoveArticles(id) {
      this.isAllSelected = false;
      setTimeout(() => {
        if (this.choosen_articles.includes(id)) {
          this.choosen_articles = this.choosen_articles.filter(
            (article_id) => article_id != id
          );
        } else {
          this.choosen_articles.push(id);
        }
      }, 300);
    },
    checkedFilter(id) {
      if (this.choosen_articles.includes(id)) return true;
      else return false;
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    AddRemoveAllArticles() {
      if (this.isAllSelected) {
        this.choosen_articles = [];
        this.isAllSelected = false;
      } else {
        this.choosen_articles = this.getAllArticleIDs();
        this.isAllSelected = true;
      }
    },

    getAllArticleIDs() {
      return this.allArticles.map((a) => a.article_id);
    },

    resetModalFields() {
      this.choosen_articles = [];
      this.isAllSelected = false;
      this.page_model = 1;
      this.loadindShow = false;
    },

    showupdateshippingDialog() {
      // Updated implementation for showCreateDialog
      window.$(`#UpdateShippingModel`).modal("toggle");
    },

    showUpdateStockDialog(warehouseId) {
      // Find the warehouse by ID from the warehouse_data array
      const warehouse = this.warehouse_data.find((w) => w.id === warehouseId);
      if (warehouse) {
        this.selectedWarehouseName = warehouse.name; // Set the warehouse name
        this.articles = warehouse.article_data; // Populate articles from the selected warehouse
        this.selectedArticle = null; // Reset selected article
        this.currentStock = 0; // Reset current stock
        this.newStock = 0; // Reset new stock

        window.$(`#updateStockModal`).modal("toggle"); // Show the modal
      }
    },

    updateCurrentStock() {
      // Find the selected article and update the current stock
      const selected = this.articles.find(
        (article) => article.id === this.selectedArticle
      );
      if (selected) {
        this.currentStock = selected.central_stock; // Set current stock to central_stock of selected article
      } else {
        this.currentStock = 0; // Reset if no article is selected
      }
    },

    async updateShippingDays() {
      const token = this.$store.getters["user/userProfile"].access; // Get the token from Vuex store
      const payload = {
        article_id: this.selectedArticle,
        average_shipping_days: this.shippingDays,
      };

      try {
        const response = await axios.patch(
          `${API_URL}/api/v1/warehouse/update-shipping-days/`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` }, // Include the token in the headers
          }
        );
        console.log(
          `Shipping days updated successfully for article ID: ${this.selectedArticle}`,
          response.data
        );
        window.$(`#updateShippingModel`).modal("hide"); // Close the modal after updating
        this.fetchInitialData(); // Optionally refresh data to reflect changes
      } catch (error) {
        console.error("Error updating shipping days:", error);
        this.$alertify.notify("Failed to update shipping days.", "error", 3);
      }
    },

    // Method to update stock levels
    async updateStockLevels() {
      const token = this.$store.getters["user/userProfile"].access; // Get the token from Vuex store
      const payload = {
        article_id: this.selectedArticle,
        central_stock: this.newStock, // This value will be added to the current central_stock
      };

      try {
        const response = await axios.patch(
          `${API_URL}/api/v1/articles/update-stock/`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` }, // Include the token in the headers
          }
        );
        console.log(
          `Stock updated successfully for article ID: ${this.selectedArticle}`,
          response.data
        );
        window.$(`#updateStockModal`).modal("hide"); // Close the modal after updating
        this.fetchInitialData(); // Optionally refresh data to reflect changes
      } catch (error) {
        console.error("Error updating stock:", error);
        this.$alertify.notify("Failed to update stock.", "error", 3);
      }
    },

    updateMaxStock() {
      const selected = this.articles.find(
        (article) => article.id === this.selectedArticle
      );
      this.maxStock = selected ? selected.stock_quantity : 0; // Set max stock based on selected article
    },

    showMoveStockDialog(warehouseId) {
      this.selectedWarehouseId = warehouseId; // Set the selected warehouse ID
      const warehouse = this.warehouse_data.find((w) => w.id === warehouseId);

      // Check if warehouse exists and has articles
      if (warehouse && warehouse.article_data) {
        this.articles = warehouse.article_data; // Use existing article data
      } else {
        this.articles = []; // Fallback to an empty array if no articles
      }

      // Filter national/distribution warehouses
      this.nationalWarehouses = this.warehouse_data.filter(
        (w) => w.type === "national/distribution"
      );

      console.log("selectedWarehouseId", this.selectedWarehouseId);
      console.log("articles", this.articles);

      // Show the modal after setting articles
      window.$(`#moveStockModal`).modal("toggle");
    },

    saveMoveStock() {
      const token = this.$store.getters["user/userProfile"].access; // Get the token from Vuex store
      const payload = {
        from_warehouse_id: this.selectedWarehouseId, // ID of the selected warehouse
        transfers: [
          {
            article_id: this.selectedArticle, // ID of the selected article
            quantity: this.moveStock, // Quantity to transfer
            to_warehouse_id: this.selectedWarehouse, // ID of the destination warehouse
          },
        ],
      };

      axios
        .post(`${API_URL}/api/v1/warehouse/transfer/`, payload, {
          headers: { Authorization: `Bearer ${token}` }, // Include the token in the headers
        })
        .then((response) => {
          console.log("Stock moved successfully:", response.data);
          this.fetchInitialData(); // Refresh data to reflect changes
          window.$(`#moveStockModal`).modal("hide"); // Close the modal
        })
        .catch((error) => {
          console.error("Error moving stock:", error);
          this.$alertify.notify("Failed to move stock.", "error", 3);
        });
    },

    confirmShipping(articleId, warehouseId, incomingVolume) {
      this.selectedArticleId = articleId;
      this.selectedWarehouseId = warehouseId;
      this.incomingVolume = incomingVolume;
      window.$("#confirmShippingModal").modal("show");
    },

    confirmShippingAction() {
      const token = this.$store.getters["user/userProfile"].access; // Get the token from Vuex store
      const payload = {
        from_warehouse_id: this.selectedWarehouseId,
        arrivals: [
          {
            article_id: this.selectedArticleId,
            source_warehouse_id: this.selectedWarehouseId,
            destination_warehouse_id: this.selectedWarehouseId,
          },
        ],
      };

      axios
        .post(`${API_URL}/api/v1/warehouse/confirm-arrival/`, payload, {
          headers: { Authorization: `Bearer ${token}` }, // Include the token in the headers
        })
        .then((response) => {
          console.log("Stock arrival confirmed successfully:", response.data);
          this.fetchInitialData(); // Refresh data to reflect changes
          window.$("#confirmShippingModal").modal("hide");
        })
        .catch((error) => {
          console.error("Error confirming stock arrival:", error);
          this.$alertify.notify("Failed to confirm stock arrival.", "error", 3);
        });
    },
    closeModal() {
      window.$("#confirmShippingModal").modal("hide");
    },

    toggleAccordion(event) {
      const acc = event.currentTarget.closest(".accordion");
      const content = acc.querySelector(".content");
      const isOpen = acc.classList.toggle("active");
      content.style.maxHeight = isOpen ? `${content.scrollHeight}px` : "0";
    },

    async fetchInitialData() {
      const token = this.$store.getters["user/userProfile"].access;
      try {
        const response = await axios.get(`${API_URL}/api/v1/warehouse/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.warehouse_data = response?.data?.results;

        // Filter national/distribution warehouses
        this.nationalWarehouses = this.warehouse_data.filter(
          (w) => w.type === "national/distribution"
        );
        this.centralWarehouses = this.warehouse_data.filter(
          (w) => w.type === "central/production"
        );

        this.updateFilteredWarehouses();
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
  },
  beforeUnmount() {
    document.querySelectorAll(".arrow-container").forEach((el) => {
      el.removeEventListener("click", this.toggleAccordion);
    });
  },
};
</script>
<style scoped>
.heading-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.listWarehouse_title {
  margin: 0 0 15px 0 !important;
  padding: 0 !important;
}

.listWarehouse ul {
  border: 1px solid #e9f0f7;
}

.listWarehouse ul li {
  border: 1px solid #e9f0f7;
  padding: 15px;
}

.accordion {
  margin-bottom: 15px;
  background-color: #f5f7f9;
  border: 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.handle {
  background-color: #f5f7f9;
  cursor: pointer;
  padding: 16px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  width: 100%;
  min-height: 70px;
}

.handle .content-model {
  width: 97%;
  flex: 0 0 97%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.handle .content-model span {
  color: #1a2b3a;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

.handle .content-model .button-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.handle .content-model .button-group button {
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 9px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  border: 0;
}

.handle .content-model .button-group .btn-primary,
table td button {
  background: #565df2 !important;
  border-color: #565df2 !important;
}

.handle .content-model .button-group .btn-success {
  background: #198754;
}

.arrow-container {
  width: 3%;
  flex: 0 0 3%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 16px;
}

.content-table {
  position: relative;
  background: #fff;
  border: 1px solid #e9ebed;
  border-radius: 12px;
  margin-bottom: 16px;
}

.content-table table tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-top: 0;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.content-table table tr th:first-child {
  border-top-left-radius: 12px;
}

.content-table table tr th:last-child {
  border-top-right-radius: 12px;
}

.content-table table .table > :not(:first-child) {
  border-top: 0;
}

.content-table table tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table-wrp table tr:hover {
  background: transparent;
}

.content h1 {
  margin-bottom: 10px !important;
  text-align: center !important;
}

.accordion .arrow {
  transition: 0.5s ease;
  margin: 0;
  line-height: 0;
}

.accordion.active .arrow {
  transform: rotate(180deg);
}

.dashboard-top-section span {
  color: #3f3f40 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 43px !important;
}

.handle:hover {
  background: transparent !important;
}

.content p {
  margin: 0 !important;
  padding: 10px !important;
}

span.green {
  width: 20px;
  height: 20px;
  background: green;
  border-radius: 100%;
  margin-left: 10px;
}

span.yellow {
  width: 25px;
  height: 25px;
  background: yellow;
  border-radius: 100px;
  margin-left: 10px;
}

span.red {
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 100px;
  margin-left: 10px;
}

.inventory-status {
  position: relative;
}

/* Updated tooltip CSS for visibility on hover */
.form-group:hover .tooltip {
  display: block;
  opacity: 1;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: normal;
  z-index: 1000;
  top: 100%;
  /* Adjust as necessary */
  left: 0;
  transform: translateX(0);
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Show the tooltip on hover */
td:hover .tooltip {
  display: block;
  opacity: 1;
}

.dashboard-top-section .listWarehouse_title {
  margin: 0 !important;
  padding: 0 !important;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.tab-content-settings .data-heading-btn {
  gap: 10px;
}

.button-group[data-v-19326c1e] {
  display: flex;
  gap: 10px;
  margin: 0px 0px 0px 800px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.tab-content-settings .data-heading-wrp .data-heading-btn button {
  padding: 10px 16px;
}

select {
  background-image: url("../../image/arrow-down.svg");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 13px;
  font-family: "Outfit", sans-serif !important;
  background-size: 20px;
}
</style>

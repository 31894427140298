<template>
    <!-- Welcome Back Section (Right Side) -->

    <div class="col-lg-7">
        <div
            class="authentication-bg text-center p-3 md:p-5 position-relative d-flex align-items-center justify-content-center flex-column">
            <div class="position-relative authentication-bg-up">
                <h3 class="auth-title text-white font-medium">
                    Welcome back! <br />
                    Please sign in to your account
                </h3>
                <div>
                    <img class="img-wrp" src="../../../public/assets/images/Desktop.png" alt="slider" />
                </div>
            </div>

            <div class="authentication-overlay position-absolute">
                <img src="../../../public/assets/images/back-pattern.png" alt="slider" class="" />
            </div>
        </div>
    </div>
    <!--end col-->
</template>
<script>
export default {
    name: 'ImageSlider',
}
</script>

<style scoped>
.authentication-bg {
    background: linear-gradient(148.66deg, #565df2 -1.13%, #555cf2 193.64%);
    border-radius: 25px;
}

.authentication-bg-up {
    z-index: 1;
}

.authentication-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    margin: auto;
}

.authentication-overlay img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auth-title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Inter", serif !important;
}

.auth-paragraph {
    font-size: 12px;
    margin-bottom: 20px;
    font-family: "Inter", serif;

}

.auth-paragraph br {
    display: none;
}

.img-wrp {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    border-top: 5px solid rgb(137, 142, 244);
    border-left: 5px solid rgb(137, 142, 244);
    border-right: 5px solid rgb(137, 142, 244);

}

@media (min-width: 992px) {
    .authentication-bg {
        height: calc(100vh - 32px);
        max-height: 736px;
    }
}

@media (min-width: 1200px) {
    .auth-paragraph {
        margin-bottom: 36px;
    }
}
</style>
<template>
  <div
    class="modal fade "
    :id="modalId"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content delete-model-content py-2">
        <div>
          <p class="delete-model-titile">
            {{ actionType ? actionType : "Delete" }} {{ resourseName }}?
          </p>
        </div>
        <div>
          <p class="delete-model-subtitile">
            Do you want to {{ actionType ? actionType : "Delete" }}
            {{ resourseName == "User" || resourseName == "Merchant"? item?.email : item?.name || item?.country_name }}
            <!-- {{ resourseName }}? -->
          </p>
        </div>
        <div class="alert alert-warning mx-3 mb-3 p-1" role="alert" v-if="resourseName == 'Google Ads' || resourseName =='Google Analytics'">
           <b>Note : </b>By disconnecting a Google services you’ll disconnect all services provided by Google connected to Crescofy.<br>
            If you disconnect  you’ll need to reconnect any service you wish to use from Google.
          </div>
        <div class="model-action">
          <button
            class="deletemodel-yes"
            @click="
              resourseName == 'customer' ? onDelete(checkStatus) : onDelete()
            "
          >
            Yes, {{ actionType ? actionType : "Delete" }}
          </button>
          <button
            class="deletemodel-no"
            :class="'close'"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            No, Cancel
          </button>
        </div>
        <div class="model-checkbox" v-if="resourseName == 'customer'">
          <label class="checkbox-container"
            >Extract GDPR
            <input
              type="checkbox"
              :checked="checkStatus"
              @change="checkStatus = !checkStatus"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomModal",
  props: {
    modalId: {
      required: true,
      type: String,
    },
    item: {
      required: true,
      type: Object,
    },
    onDelete: {
      required: true,
      type: Function,
    },
    resourseName: {
      required: true,
      type: String,
    },
    actionType: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      checkStatus: false,
    };
  },
};
</script>
<style>
</style>
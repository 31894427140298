<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar title="Settings" :author="{
      home: 'Home',
      cart: 'Settings',
    }" />
    <div class="main-wrp">
      <div class="tabbable">
        <ul class="nav nav-tabs">
          <li class="active">
            <a @click.prevent="setActive('admins')" :class="{ active: isActive('admins') }" href="#admins">User
              Admin</a>
          </li>
          <!-- <li>
          <a
            @click.prevent="setActive('stores')"
            :class="{ active: isActive('stores') }"
            href="#stores"
            >Sales Channel</a
          >
        </li> -->
          <li>
            <a @click.prevent="setActive('articles')" :class="{ active: isActive('articles') }"
              href="#articles">Articles</a>
          </li>
          <li>
            <a @click.prevent="setActive('products')" :class="{ active: isActive('products') }"
              href="#products">Products</a>
          </li>
          <!-- <li>
          <a
            @click.prevent="setActive('shipping')"
            :class="{ active: isActive('shipping') }"
            href="#shipping"
            >Shipping Charges</a
          >
        </li> -->
          <li>
            <a @click.prevent="setActive('settings')" :class="{ active: isActive('settings') }" href="#settings">Domain
              Settings</a>
          </li>
          <li>
            <a @click.prevent="setActive('integrations')" :class="{ active: isActive('integrations') }"
              href="#integrations">Integrations</a>
          </li>
          <li>
            <a @click.prevent="setActive('imports')" :class="{ active: isActive('imports') }" href="#imports">File
              import</a>
          </li>
          <li>
            <a @click.prevent="setActive('inventories')" :class="{ active: isActive('inventories') }"
              href="#inventories">Warehouse</a>
          </li>
        </ul>
      </div>
      <div class="tab-content tab-content-settings">
        <div class="tab-pane" :class="{ 'active show': isActive('admins') }" id="admins">
          <AdminTable v-if="isActive('admins')" />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('stores') }" id="stores">
          <Stores v-if="isActive('stores')" />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('articles') }" id="articles">
          <Articles v-if="isActive('articles')" />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('products') }" id="products">
          <Products v-if="isActive('products')" />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('shipping') }" id="shipping">
          <Shipping v-if="isActive('shipping')" />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('settings') }" id="settings">
          <DomainSettings />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('integrations') }" id="integrations">
          <Integrations ref="IntegrationRef" />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('imports') }" id="imports">
          <Imports />
        </div>
        <div class="tab-pane" :class="{ 'active show': isActive('inventories') }" id="inventories">
          <Inventory />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Grid from './components/AdminGrid';
import AdminTable from "./components/AdminTable.vue";
import DomainSettings from "../User/components/DomainSettings.vue";
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import Integrations from "./components/Integrations.vue";
import Imports from "./components/Imports.vue";
import Stores from "../Stores/Stores.vue";
import Shipping from "../Shipping/Shipping.vue";
import Products from "../Products/Products.vue";
import Articles from "../Articles/Articles.vue";
import Inventory from "./components/Inventory.vue";
import { GET_ORG, FACEBOOK_DISCONNECT } from "@/Core/store/action-types";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Admin",
  components: {
    AdminTable,
    MainMenu,
    TopBar,
    Loading,
    DomainSettings,
    Integrations,
    Imports,
    Shipping,
    Stores,
    Products,
    Articles,
    Inventory
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      activeItem: "admins",
    };
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
    ...mapGetters("org", ["org"]),
  },
  methods: {
    ...mapActions("org", [GET_ORG, FACEBOOK_DISCONNECT]),
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    initOrg() {
      this[GET_ORG](this.userProfile.organization.id).then(() => {
        this.validateAccessToken()
      })
    },
    validateAccessToken() {
      if (this.org && this.org.facebook_ad_access_token) {
        let vm = this;
        window.FB?.api(
          `/debug_token?input_token=${vm.org.facebook_ad_access_token}&access_token=${process.env.VUE_APP_FACEBOOK_APP_ID}|${process.env.VUE_APP_FACEBOOK_APP_SECRET}`,
          function (response) {
            if (response.data && !response.data.error) {
              console.log('verified')
            } else {
              vm[FACEBOOK_DISCONNECT]().then(() => {
                vm.initOrg();
                vm.$alertify.notify(
                  "Facebook ad disconnected, please connect again.",
                  "error",
                  3
                );
              })
            }
          }
        );
      }
    },
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  mounted() {
    this.initOrg()
    this.validateAccessToken();
    if (this.$route?.query?.google_connection) {
      this.setActive('integrations')
    }
  },
};
</script>
<style scoped>
.tabbable .nav-tabs {
  gap: 10px;
  padding-bottom: 20px !important;
  border: 0;
}

.tabbable .nav-tabs li a {
  color: #1A2B3A;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid rgba(26, 43, 58, 0.1019607843);
  opacity: 0.8;
  border-radius: 5px;
  padding: 6px 10px;
  letter-spacing: normal;
}

.tabbable .nav-tabs li a.active {
  background: #565DF2;
  color: #fff;
  opacity: 1;
  border-color: #565DF2;
}

.contant-box-main {
  padding: 0;
  border: 1px solid #ECECED;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
</style>
<template>
  <!-- Log In page -->
  <div class="container-full d-flex align-items-center">
    <div class="container-xxl">
      <div class="row d-flex justify-content-center">
        <div class="col-12 align-self-center">
          <div class="">
            <div class="card-body p-0">
              <div class="row d-flex justify-content-center align-items-center welcome-gap">
                <div class="col-lg-5">
                  <div class="media mb-5 justify-content-center d-none">
                    <img src="../../public/assets/images/crescofy-logo.png" height="55" alt="logo" class="auth-logo" />
                  </div>
                  <div class="card card-box">
                    <div class="card-body p-0">
                      <div class="forgot-title-box">
                        <h2>Thank You!</h2>
                        <h4 class="form-paragraph">
                          Thank you for your interest in Crescofy. The request has been sent via email.
                        </h4>
                      </div>
                      <div class="form-group mb-0 row">
                        <div class="col-12 d-flex align-items-center flex-column">
                          <router-link to="/forgotpassword"
                            class="w-100 btn btn-block waves-effect waves-light login-button">
                            Send email verification
                          </router-link>
                          <router-link to="/login" class="login-page-link">
                            <img src="@/../public/assets/images/arrow-left.svg" alt="" />Back to Log in</router-link>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group-->
                    </div>
                  </div>
                </div>

                <!-- Welcome Back Section (Right Side) -->
                <ImageSlider />
              </div>
              <!--end row-->
            </div>
            <!--end card-body-->
          </div>
          <!--end card-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import * as yup from "yup";
import { mapActions } from "vuex";

import { FORGOT_PASSWORD } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import ImageSlider from "./components/ImageSlider.vue";

export default {
  name: "VerificationPage",
  components: {
    ImageSlider
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [FORGOT_PASSWORD]),

    onSubmit(values, actions) {
      console.log("egfewgfeg", values);
      this[FORGOT_PASSWORD]({ values, actions })
        .then(() => {
          this.$alertify.notify(
            `reset password link sent to your email.please check`,
            "success",
            3
          );
          this.$router.push("/login");
        })
        .catch(() => { }); //stay on a page
    },
  },
  data() {
    const schema = yup.object().shape({
      email: this.emailValidator(),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      schema,
    };
  },
};
</script>
<style scoped>
.container-full {
  background: #fff;
  height: auto;
  padding: 40px 0;
}

.control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.login-button {
  padding-top: 12px;
  background-color: #5e66fa;
  color: #ffffff;
  height: 46px;
  font-size: 14px;
  border-radius: 8px;
}

.login-button:hover {
  background-color: #727aff;
  color: #fff;
}

.login-page-link {
  border-radius: 8px;
  margin-left: 0;
  color: #1a2b3a;
  font-style: normal;
  height: 46px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d5dd;
  margin-top: 30px;
  gap: 8px;
}

.login-page-link:hover {
  border: 1px solid #d0d5dd;
  background: #d0d5dd;
}

.card-box {
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
  border: 0;
}

.forgot-title-box h2 {
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1a2b3a;
}

.forgot-title-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #1a2b3a;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 30px;
}

.form-paragraph {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
  color: #485561;
  margin-bottom: 30px;
}

.form-group label {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.64px;
  text-align: left;
  color: #1a2b3a;
  margin-bottom: 12px;
}

.form-group .form-control {
  font-size: 14px;
  border: 0;
  font-weight: 400;
  color: #1a2b3a;
  padding: 8px 15px;
  background: #f5f7f9;
  height: 46px;
  border-radius: 8px;
  padding-left: 47px;
}

.authentication-bg {
  background: linear-gradient(148.66deg, #565df2 -1.13%, #555cf2 193.64%);
  border-radius: 25px;
}

.form-control-feedback {
  line-height: 12px;
}

.auth-paragraph {
  font-size: 12px;
  margin-bottom: 20px;
  font-family: "Inter", serif;
}

.authentication-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  margin: auto;
}

.authentication-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Inter", serif;
}

.control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.authentication-bg-up {
  z-index: 1;
}

.welcome-gap {
  gap: 30px;
}

.auth-paragraph br {
  display: none;
}

@media (min-width: 992px) {
  .authentication-bg {
    height: calc(100vh - 32px);
    max-height: 736px;
  }

  .auth-paragraph br {
    display: block;
  }

  .welcome-gap {
    gap: 0;
  }

  .container-full {
    height: 100vh;
    padding: 0 0;
  }
}

@media (min-width: 1200px) {
  .auth-paragraph {
    margin-bottom: 36px;
  }
}
</style>
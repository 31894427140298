<template>
  <!-- Template for intransitArticles -->
  <div v-if="slug === 'intransitArticles'" class="contant-box-main customers-table-wrp">
    <div class="data-heading-wrp">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <h5 class="section-title mb-0">
            <img src="@/../public/assets/images/star.svg" alt="" />
            In-transit Articles (National Warehouse)
          </h5>
        </div>
        <div class="col-lg-6 text-right">
          <button
            type="button"
            class="save-btn"
            @click="generateCSV(inTransitFieldToExport, allArticles)"
          >
            Export List
          </button>
        </div>
      </div>
    </div>
    <div class="table-wrp overflow-auto position-relative">
      <div class="loader-main" v-if="loadindShow">
        <div
          class="spinner-border"
          role="status"
          style="width: 36px; height: 36px; color: #5e66fa"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <table class="table my-table-wrp table-sm" id="my-table">
        <thead>
          <tr>
            <th>Article ID</th>
            <th>Article Name</th>
            <th>Volume in Transit</th>
            <th>Days Left</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="article in allArticles
              ? allArticles.slice(10 * (page_model - 1), page_model * 10)
              : []"
            :key="article.article_id"
            :class="checkedFilter(article.article_id) ? 'active-row selected' : ''"
          >
            <td>{{ article.sku }}</td>
            <td>{{ article.name }}</td>
            <td>{{ article.volume_in_transit }}</td>
            <td>{{ article.days_left }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :page="page_model"
      :totalPages="
        allArticles
          ? Array(Math.ceil(allArticles.length / 10))
              .fill(0)
              .map((e, i) => i + 1)
          : []
      "
      :name="'Articles'"
      :count="allArticles ? allArticles.length : 0"
      :incrementpage="incrementpage_model"
      :decrementpage="decrementpage_model"
      :setpage="setpage_model"
      :perpage="10"
    />
  </div>

  <!-- Template for ArticlesLowStock -->
  <div
    v-else-if="slug === 'ArticlesLowStock'"
    class="contant-box-main customers-table-wrp"
  >
    <div class="data-heading-wrp">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <h5 class="section-title mb-0">
            <img src="@/../public/assets/images/star.svg" alt="" />
            Articles Low Stock
          </h5>
        </div>
        <div class="col-lg-4 text-center">
          <ul class="nav nav-pills me-3 mb-0" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: lowArticlesTab === 'national' }"
                @click="switchLowArticlesTab('national')"
              >
                National Warehouse
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: lowArticlesTab === 'central' }"
                @click="switchLowArticlesTab('central')"
              >
                Central Warehouse
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 text-right">
          <button
            type="button"
            class="save-btn"
            @click="generateCSV(lowArticlesFieldToExport, lowStockArticlesAll)"
          >
            Export List
          </button>
        </div>
      </div>
    </div>
    <div class="table-wrp overflow-auto position-relative">
      <div class="loader-main" v-if="loadindShow">
        <div
          class="spinner-border"
          role="status"
          style="width: 36px; height: 36px; color: #5e66fa"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <table class="table my-table-wrp table-sm" id="my-table">
        <thead>
          <tr>
            <th>Article ID</th>
            <th>Article Name</th>
            <th>Warehouse Code</th>
            <th>Stock</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="article in allArticles
              ? allArticles.slice(10 * (page_model - 1), page_model * 10)
              : []"
            :key="article.article_id"
            :class="checkedFilter(article.article_id) ? 'active-row selected' : ''"
          >
            <td>{{ article.sku }}</td>
            <td>{{ article.name }}</td>
            <td>{{ article.warehouse_code }}</td>
            <td>{{ article.stock }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :page="page_model"
      :totalPages="
        allArticles
          ? Array(Math.ceil(allArticles.length / 10))
              .fill(0)
              .map((e, i) => i + 1)
          : []
      "
      :name="'Articles'"
      :count="allArticles ? allArticles.length : 0"
      :incrementpage="incrementpage_model"
      :decrementpage="decrementpage_model"
      :setpage="setpage_model"
      :perpage="10"
    />
  </div>

  <!-- template for inventory turnover -->
  <div
    v-else-if="slug === 'inventoryTurnOver'"
    class="contant-box-main customers-table-wrp"
  >
    <div class="data-heading-wrp">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <h5 class="section-title mb-0">
            <img src="@/../public/assets/images/star.svg" alt="" />
            Inventory Turnover (National Warehouse)
          </h5>
        </div>
        <div class="col-lg-4 text-center">
          <ul
            class="nav nav-pills me-3 justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: inventoryTab === 'top' }"
                @click.prevent="switchInventoryTab('top')"
                role="button"
              >
                Top
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: inventoryTab === 'lowest' }"
                @click.prevent="switchInventoryTab('lowest')"
                role="button"
              >
                Lowest
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="filter-up mr-3">
              <select name="" id="">
                <option value="Up">UP</option>
                <option value="Down">Down</option>
              </select>
              <select name="" id="">
                <option value="Month">Month</option>
                <option value="Week">Week</option>
              </select>
            </div>
            <button
              type="button"
              class="save-btn"
              @click="generateCSV(inventoryTurnOverFieldToExport, allArticles)"
            >
              Export List
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-wrp overflow-auto position-relative">
      <div class="loader-main" v-if="loadindShow">
        <div
          class="spinner-border"
          role="status"
          style="width: 36px; height: 36px; color: #5e66fa"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <table class="table my-table-wrp table-sm" id="my-table">
        <thead>
          <tr>
            <th>Article ID</th>
            <th>Article Name</th>
            <th>Warehouse Code</th>
            <th>Quantity</th>
            <th>Turnover Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="article in allArticles
              ? allArticles.slice(10 * (page_model - 1), page_model * 10)
              : []"
            :key="article.article_id"
            :class="checkedFilter(article.article_id) ? 'active-row selected' : ''"
          >
            <td>{{ article.sku }}</td>
            <td>{{ article.name }}</td>
            <td>{{ article.warehouse_code }}</td>
            <td>{{ article.quantity }}</td>
            <td>{{ article.turnover }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :page="page_model"
      :totalPages="
        allArticles
          ? Array(Math.ceil(allArticles.length / 10))
              .fill(0)
              .map((e, i) => i + 1)
          : []
      "
      :name="'Articles'"
      :count="allArticles ? allArticles.length : 0"
      :incrementpage="incrementpage_model"
      :decrementpage="decrementpage_model"
      :setpage="setpage_model"
      :perpage="10"
    />
  </div>

  <!-- template for average inventory turnover -->
  <div
    v-else-if="slug === 'AverageInventoryTurnover'"
    class="contant-box-main customers-table-wrp"
  >
    <div class="data-heading-wrp">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <h5 class="section-title mb-0">
            <img src="@/../public/assets/images/star.svg" alt="" />
            Average Inventory Turnover
          </h5>
        </div>
        <div class="col-lg-6 text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="filter-up mr-3">
              <select name="" id="">
                <option value="Up">UP</option>
                <option value="Down">Down</option>
              </select>
              <select name="" id="">
                <option value="Month">Month</option>
                <option value="Week">Week</option>
              </select>
            </div>
            <button
              type="button"
              class="save-btn"
              @click="generateCSV(averageInventoryTurnOverFieldToExport, allArticles)"
            >
              Export List
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-wrp overflow-auto position-relative">
      <div class="loader-main" v-if="loadindShow">
        <div
          class="spinner-border"
          role="status"
          style="width: 36px; height: 36px; color: #5e66fa"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <table class="table my-table-wrp table-sm" id="my-table">
        <thead>
          <tr>
            <th>Article ID</th>
            <th>Article Name</th>
            <th>Average turnover</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="article in allArticles
              ? allArticles.slice(10 * (page_model - 1), page_model * 10)
              : []"
            :key="article.article_id"
            :class="checkedFilter(article.article_id) ? 'active-row selected' : ''"
          >
            <td>{{ article.sku }}</td>
            <td>{{ article.name }}</td>
            <td>{{ article.average_turnover }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :page="page_model"
      :totalPages="
        allArticles
          ? Array(Math.ceil(allArticles.length / 10))
              .fill(0)
              .map((e, i) => i + 1)
          : []
      "
      :name="'Articles'"
      :count="allArticles ? allArticles.length : 0"
      :incrementpage="incrementpage_model"
      :decrementpage="decrementpage_model"
      :setpage="setpage_model"
      :perpage="10"
    />
  </div>
  <!-- Optional: Add a fallback for other slugs -->
  <div v-else>
    <NotFound />
  </div>
</template>

<script>
import Pagination from "@/Core/Pagination";
import axios from "axios";
import { API_URL } from "@/Core/helpers/utils";
import NotFound from "@/Core/404";

export default {
  name: "CommonViewAll",
  components: {
    Pagination,
    NotFound,
  },
  data() {
    return {
      loadindShow: false,
      page_model: 1,
      choosen_articles: [],
      isAllSelected: false,
      allArticles: [],
      lowStockArticlesAll: [],
      slug: this.$route.params.api,
      lowArticlesTab: "national",
      inventoryTab: "top",
      inTransitFieldToExport: ["sku", "name", "volume_in_transit", "days_left"],
      lowArticlesFieldToExport: ["sku", "name", "warehouse_code", "stock"],
      inventoryTurnOverFieldToExport: [
        "sku",
        "name",
        "warehouse_code",
        "quantity",
        "turnover_rate",
      ],
      averageInventoryTurnOverFieldToExport: ["sku", "name", "average_turnover"],
    };
  },
  computed: {
    apiEndpoint() {
      // for inventory lowest and top turnover
      return `${API_URL}/api/v1/warehouse/${this.inventoryTab}-inventory-turnover/`;
    },
  },
  watch: {
    slug: {
      handler(newSlug) {
        this.toggleApi(newSlug);
      },
      immediate: true,
    },
  },
  methods: {
    switchInventoryTab(type) {
      this.inventoryTab = type;
      this.fetchInventoryTurnover();
    },

    switchLowArticlesTab(type) {
      this.lowArticlesTab = type;
      if (type === "central") {
        this.articles = this.centralLowArticles;
      } else if (type === "national") {
        this.articles = this.nationalLowArticles;
      }
      this.allArticles = this.articles;
      this.page_model = 1; // Reset to first page when switching
    },

    toggleApi(slug) {
      switch (slug) {
        case "intransitArticles":
          this.fetchIntransitArticles();
          break;
        case "ArticlesLowStock":
          this.fetchArticlesLowStock();
          break;
        case "inventoryTurnOver":
          this.fetchInventoryTurnover();
          break;
        case "AverageInventoryTurnover":
          this.fetchAverageInventoryTurnover();
          break;
        default:
          console.log("Invalid option selected");
      }
    },

    async fetchIntransitArticles() {
      const token = this.$store.getters["user/userProfile"].access;
      this.loadindShow = true;
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/warehouse/articles-in-transit`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.articles = response.data;
        this.allArticles = this.articles;
      } catch (error) {
        console.error(
          "Error fetching articles:",
          error.response ? error.response.data : error.message
        );
      } finally {
        this.loadindShow = false;
      }
    },

    async fetchArticlesLowStock() {
      const token = this.$store.getters["user/userProfile"].access;
      this.loadindShow = true;
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/warehouse/low-stock-articles/?threshold=20`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.lowStockArticlesAll = response.data;
        this.centralLowArticles = response.data.filter(
          (article) => article.warehouse_type === "central/production"
        );
        this.nationalLowArticles = response.data.filter(
          (article) => article.warehouse_type === "national/distribution"
        );
        this.articles = this.nationalLowArticles;
        this.allArticles = this.articles;
      } catch (error) {
        console.error(
          "Error fetching articles:",
          error.response ? error.response.data : error.message
        );
      } finally {
        this.loadindShow = false;
      }
    },

    async fetchInventoryTurnover() {
      const token = this.$store.getters["user/userProfile"].access;
      this.loadindShow = true;
      try {
        const response = await axios.get(this.apiEndpoint, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.articles = response.data;
        this.allArticles = this.articles;
      } catch (error) {
        console.error(
          "Error fetching articles:",
          error.response ? error.response.data : error.message
        );
      } finally {
        this.loadindShow = false;
      }
    },

    async fetchAverageInventoryTurnover() {
      const token = this.$store.getters["user/userProfile"].access;
      this.loadindShow = true;
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/warehouse/average-inventory-turnover/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.articles = response.data;
        this.allArticles = this.articles;
      } catch (error) {
        console.error(
          "Error fetching articles:",
          error.response ? error.response.data : error.message
        );
      } finally {
        this.loadindShow = false;
      }
    },

    onTimeChange() {
      this.fetchIntransitArticles();
    },

    AddRemoveArticles(id) {
      this.isAllSelected = false;
      setTimeout(() => {
        if (this.choosen_articles.includes(id)) {
          this.choosen_articles = this.choosen_articles.filter(
            (article_id) => article_id != id
          );
        } else {
          this.choosen_articles.push(id);
        }
      }, 300);
    },
    checkedFilter(id) {
      if (this.choosen_articles.includes(id)) return true;
      else return false;
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    AddRemoveAllArticles() {
      if (this.isAllSelected) {
        this.choosen_articles = [];
        this.isAllSelected = false;
      } else {
        this.choosen_articles = this.getAllArticleIDs();
        this.isAllSelected = true;
      }
    },

    getAllArticleIDs() {
      return this.allArticles.map((a) => a.article_id);
    },

    resetModalFields() {
      this.choosen_articles = [];
      this.isAllSelected = false;
      this.page_model = 1;
      this.loadindShow = false;
    },
    generateCSV(fieldToExport, articlesDataToExport) {
      // Use Blob instead of data URI to handle large datasets
      const rows = [];
      // header
      rows.push(fieldToExport.join(","));

      // Data 
      articlesDataToExport.forEach((item) => {
        const rowData = fieldToExport
          .map((field) => {
            const value = item[field] ?? "N/A";
            // Properly escape values containing commas or quotes
            return `"${value.toString().replace(/"/g, '""')}"`;
          })
          .join(",");
        rows.push(rowData);
      });

      // Create Blob with BOM for Excel compatibility
      const BOM = "\uFEFF";
      const csvContent = BOM + rows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

      // Create download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${this.slug}_export.csv`);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },
  },
  mounted() {
    window
      .$("#intransitArticlesNationalWareHouseModel")
      .on("hide.bs.modal", this.resetModalFields);
  },
};
</script>

<style scoped>
.customers-table-wrp {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

/* Navigation pills styles */
.nav-pills {
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-pills .nav-link {
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0 5px;
  border-radius: 4px;
  color: #6c757d;
}

.nav-pills .nav-link.active {
  background-color: #5e66fa;
  color: white;
}

/* Right side container */
.header-wrapper .d-flex {
  gap: 15px;
  align-items: center;
}
.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}
.table-wrp .table thead tr th .table-head {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  padding: 0;
}
.table-wrp .table thead tr th span {
  margin-left: 12px;
  cursor: pointer;
}
.table-wrp .table thead tr th span span {
  margin-left: 0px;
  font-size: 14px;
  margin-right: 2px;
}
.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}
.table-wrp .table .main-button--table td {
  padding: 0;
  border: 0;
  width: auto;
}
.table > :not(:first-child) {
  border-top: 0;
}
.table-wrp .table .eye-view {
  background: transparent;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0 !important;
  padding: 0;
}
table.main-button--table {
  margin: 0 auto;
  text-align: center;
  width: auto;
}
.table-wrp .table thead tr th:last-child,
.table-wrp .table tbody tr td:last-child {
  text-align: center;
}

nav.main-pagination {
  padding: 20px;
}
.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

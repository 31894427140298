<template>
  <div class="main-wrp">
    <MainMenu />
    <TopBar
      title="Articles Re-stocking (National warehouse)"
      :author="{ home: 'Home' }"
    />
    <div class="box-wrapper">
      <!-- heading -->
      <div class="data-heading-wrp">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <h5 class="section-title mb-0">
              <img src="@/../public/assets/images/star-icon.png" alt="star" />
              Articles re-stocking (National warehouse)
            </h5>
          </div>
          <div class="col-auto">
            <div class="action-wrapper d-flex align-items-center gap-4">
              <span class="filter-wrp">
                <select
                  class="form-select"
                  id="adminfilter"
                  v-model="time"
                  @change="onTimeChange"
                >
                  <option v-for="option in timeOptions" :key="option">
                    {{ option }}
                  </option>
                </select>
              </span>
              <button
                type="button"
                class="save-btn"
                @click.prevent="initCustomerList(true)"
              >
                Export List
              </button>
            </div>
          </div>
        </div>

        <!-- Article Preference Type -->
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-group" :class="{ 'has-error': pref_type_error }">
              <label class="form-label">Article Preference Type</label>
              <Multiselect
                v-model="pref_type"
                :options="pref_options"
                label="key"
                valueProp="value"
                placeholder="Select article preference type"
              />
              <span class="error">{{ pref_type_error }}</span>
            </div>
          </div>
        </div>

        <!-- Audience Name Section -->
        <div v-if="createAudienceMode" class="row mt-2">
          <div class="col-12">
            <div class="form-group" :class="{ 'has-error': errMsg }">
              <label class="form-label">Audience Name</label>
              <div class="d-flex" style="gap: 16px">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="audienceName"
                  @keyup="validateName"
                  @blur="validateName"
                />
                <button type="button" class="save-btn" @click.prevent="initCustomerList">
                  Create
                </button>
              </div>
              <span class="error">{{ errMsg }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow">
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <table class="table my-table-wrp table-sm" id="my-table">
          <thead>
            <tr>
              <th width="5%">
                <div class="checkbox-list">
                  <label class="checkbox">
                    <input
                      @change="AddRemoveAllArticles()"
                      class="checkbox-input"
                      type="checkbox"
                      :checked="isAllSelected"
                    />
                    <span class="checkbox-checkmark-box">
                      <span class="checkbox-checkmark"></span>
                    </span>
                  </label>
                </div>
              </th>
              <th>Id</th>
              <th>Name</th>
              <th>Purchase count</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="article in all_selling_articles
                ? all_selling_articles.slice(25 * (page_model - 1), page_model * 25)
                : []"
              :key="article.article__id"
              :class="checkedFilter(article.article__id) ? 'active-row selected' : ''"
            >
              <td width="5%">
                <div class="checkbox-list">
                  <label class="checkbox">
                    <input
                      @change="AddRemoveArticles(article.article__id)"
                      class="checkbox-input"
                      type="checkbox"
                      :checked="checkedFilter(article.article__id)"
                    />
                    <span class="checkbox-checkmark-box">
                      <span class="checkbox-checkmark"></span>
                    </span>
                  </label>
                </div>
              </td>
              <td class="font-light-text" width="20%">
                {{ article.article__sku }}
              </td>
              <td width="55%">{{ article.article__name }}</td>
              <td class="font-light-text" width="20%">
                {{ article.quantity }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :page="page_model"
        :totalPages="
          all_selling_articles
            ? Array(Math.ceil(all_selling_articles.length / 25))
                .fill(0)
                .map((e, i) => i + 1)
            : []
        "
        :name="'Articles'"
        :count="all_selling_articles ? all_selling_articles.length : 0"
        :incrementpage="incrementpage_model"
        :decrementpage="decrementpage_model"
        :setpage="setpage_model"
        :perpage="25"
      />
    </div>
  </div>
</template>
<script>
import Pagination from "../../../Core/Pagination.vue";
import MainMenu from "../../../Menu/MainMenu.vue";
import TopBar from "../../../Menu/TopBar.vue";

export default {
  name: "ArticlesRestockNationalView",
  components: {
    Pagination,
    MainMenu,
    TopBar,
  },
  data() {
    return {
      top_selling_articles: [],
      all_selling_articles: [],
      customerIDs: [],
      choosen_articles: [],
      createAudienceMode: false,
      page_model: 1,
      nameError: false,
      errMsg: "",
      audienceName: "",
      time: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      isAllSelected: false,
      pref_options: [
        { value: "has_bought", key: "Has bought" },
        {
          value: "likely_to_buy",
          key: "Likely to buy ",
        },
        {
          value: "similar_articles",
          key: "Similar articles",
        },
        {
          value: "likely_to_buy_again",
          key: "Likely to buy Again",
        },
        { value: "all_fields", key: "All Fields" },
      ],
      pref_type: "",
      pref_type_error: "",
      loadindShow: false,
    };
  },
  methods: {
    // ...mapActions("article", [TOP_SELLING_ARTICLES]),
    // ...mapActions("audience", [CREATE_AUDIENCE]),
    // ...mapActions("customer", [LIST_CUSTOMERS]),

    AddRemoveAllArticles() {
      if (this.isAllSelected) {
        this.choosen_articles = [];
        this.isAllSelected = false;
      } else {
        this.choosen_articles = this.getAllArticleIDs();
        this.isAllSelected = true;
      }
    },
    getAllArticleIDs() {
      return this.all_selling_articles.map((a) => a.article__id);
    },
    getCustomerIDs(customers) {
      return customers.map((a) => a.id);
    },
    generateCSV(customers) {
      let arrData = [
        {
          customers: customers,
        },
      ];
      window.$(`#exportCsvModel`).modal("hide");
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0].customers[0] || {}).join(","),
        ...arrData[0].customers.map((item) => Object.values(item).join(",")),
      ].join("\n");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", `selling-article-customers-${this.time}.csv`);
      link.click();
    },
    AddRemoveArticles(id) {
      this.isAllSelected = false;
      setTimeout(() => {
        if (this.choosen_articles.includes(id)) {
          this.choosen_articles = this.choosen_articles.filter(
            (article_id) => article_id != id
          );
        } else {
          this.choosen_articles.push(id);
        }
      }, 300);
    },
    checkedFilter(id) {
      if (this.choosen_articles.includes(id)) return true;
      else return false;
    },
    onTimeChange() {},
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
  },
  watch: {
    pref_type(value) {
      console.log("ff", value);
      if (this.choosen_articles.length > 0 && (value == "" || !value))
        this.pref_type_error = "please select articles preference type";
      else this.pref_type_error = "";
    },
    choosen_articles(value) {
      if (value.length == 0) {
        (this.pref_type_error = ""), (this.errMsg = "");
      }
    },
    audienceName(value) {
      if (!value) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (value.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else {
        this.nameError = false;
        this.errMsg = "";
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.box-wrapper {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.main-pagination {
  padding: 20px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table > :not(:first-child) {
  border-top: 0;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-display {
  font-size: 12px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border-radius: 10px;
  padding: 10px;
}
.date-display img {
  margin-bottom: 4px;
  margin-left: 10px;
}
.filter-wrp select {
  font-size: 14px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border: none;
  border-radius: 10px;
  padding: 10px;
}
.action-wrapper {
  white-space: nowrap;
}
</style>

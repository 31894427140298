<template>
  <div class="main-wrp">
    <div class="dashboard-top-section">
      <div class="dts-row">
        <div class="dts-box-outer">
          <div class="dts-box campaign-box">
            <!-- <span class="box-icon">
              <CampaignsIcon />
            </span> -->
            <h6 class="mb-3">Total Customers</h6>
            <span>{{orgStats?.total_customers}}</span>
            <div class="bottom__title">
              <span>+{{orgStats?.customer_added_current_month || 0}}</span>
              <span>from {{new Date(new Date().getFullYear(), 
                new Date().getMonth(), 1)
                .toLocaleString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })}}</span>
            </div>
          </div>
        </div>
        <div class="dts-box-outer">
          <div class="dts-box customer-box">
            <!-- <span class="box-icon"><CustomersIcon /></span> -->
            <h6 class="mb-3">Total Articles</h6>
            <span>{{orgStats?.total_articles}}</span>
          </div>
        </div>
        <div class="dts-box-outer">
          <div class="dts-box offer-box">
            <!-- <span class="box-icon"><OffersIcon /></span> -->
            <h6 class="mb-3">Total Receipts</h6>
            <span>{{orgStats?.total_receipts}}</span>
            <div class="bottom__title">
              <span>+{{orgStats?.receipts_added_current_month || 0}}</span>
              <span>from {{new Date(new Date().getFullYear(), 
                new Date().getMonth(), 1)
                .toLocaleString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })}}</span>
            </div>
          </div>
        </div>
        <div class="dts-box-outer">
          <div class="dts-box article-box">
            <!-- <span class="box-icon"><ArticlesIcon /></span> -->
            <h6 class="mb-3">Recommendation Follow Up</h6>
            <div class="bottom__title_recommendation">
              <span>No of customer recommendations week 42: <strong>{{ recommendationsStats?.no_of_customer_recommendations_week || 'N/A' }}</strong></span><br>
              <span>No of customer contacts made: <strong>{{ recommendationsStats?.no_of_customer_contacts_made || 'N/A' }}</strong></span><br>
              <span>No of orders on customer contacts: <strong>{{ recommendationsStats?.no_of_orders_on_customer_contacts.count || 'N/A' }}</strong> ({{ recommendationsStats?.no_of_orders_on_customer_contacts.rate }})</span><br>
              <span>No of purchases within 7 days +/- recommendation: <strong>{{ recommendationsStats?.no_of_purchases_within_7_days.count || 'N/A' }}</strong> ({{ recommendationsStats?.no_of_purchases_within_7_days.rate }})</span><br>
              <span>No of purchases within 14 days +/- recommendation: <strong>{{ recommendationsStats?.no_of_purchases_within_14_days.count || 'N/A' }}</strong> ({{ recommendationsStats?.no_of_purchases_within_14_days.rate }})</span><br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="../../../public/assets/images/Members.png"
                alt=""
              />Admin Users
            </h6>
          </div>
          <div class="contant-details">
            <div class="overflow-auto table-wrp">
              <table class="table table-sm" id="my-table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in admin_users?.results" :key="user?.id">
                    <td class="font-light-text" width="15%">{{ user?.id }}</td>
                    <td width="50%" class="font-text">{{ user?.email }}</td>
                    <td class="font-light-text" width="20%">
                      <label class="status-active" v-if="user?.is_active">
                    Active
                  </label>
                  <label class="status-inactive" v-else>Inactive</label>
                    </td>
                    <td class="font-light-text" width="15%"> {{ user?.last_login || "-" }}</td>
                  </tr>
                </tbody>
              </table>
                <Pagination
                :page="page"
                :totalPages="totalPages"
                :name="'Admin Users'"
                :count="admin_users ? admin_users?.count : 0"
                :incrementpage="incrementpage"
                :decrementpage="decrementpage"
                :setpage="setpage"
                :perpage="10"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="../../../public/assets/images/Members.png"
                alt=""
              />Users
            </h6>
          </div>
          <div class="contant-details">
            <div class="overflow-auto table-wrp">
              <table class="table table-sm" id="my-table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users?.results" :key="user?.id">
                    <td class="font-light-text" width="15%">{{ user?.id }}</td>
                    <td width="50%" class="font-text">{{ user?.email }}</td>
                    <td class="font-light-text" width="20%">
                      <label class="status-active" v-if="user?.is_active">
                    Active
                  </label>
                  <label class="status-inactive" v-else>Inactive</label>
                    </td>
                    <td class="font-light-text" width="15%"> {{ user?.last_login || "-" }}</td>
                  </tr>
                </tbody>
              </table>
               <Pagination
                :page="userPage"
                :totalPages="userTotalPages"
                :name="'Users'"
                :count="users ? users?.count : 0"
                :incrementpage="userincrementpage"
                :decrementpage="userdecrementpage"
                :setpage="usersetpage"
                :perpage="10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-6">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="../../../public/assets/images/star-icon.png"
                alt=""
              />Integrations
            </h6>
          </div>
          <div class="contant-details"></div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <MetaIcon />
                </div>
                <div class="title">
                  <p class="main_title">Facebook &amp; Instagram</p>
                  <p class="sub_title">https://www.meta.com/</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="button">
                <!-- <button class="connected">Connected</button> -->
                <label :class="org?.is_facebook_ads_connected?'connected':'not-connected'">{{org?.is_facebook_ads_connected?'Connected':'Not Connected'}}</label>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <GoogleAdIcon />
                </div>
                <div class="title">
                  <p class="main_title">Google Adwords</p>
                  <p class="sub_title">adwords.google.com</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="button">
                <!-- <button class="connected">Connected</button> -->
                <label :class="org?.is_google_ads_connected?'connected':'not-connected'">{{org?.is_google_ads_connected?'Connected':'Not Connected'}}</label>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <GoogleAnalyticsIcon />
                </div>
                <div class="title">
                  <p class="main_title">Google Analytics</p>
                <p class="sub_title">analytics.google.com</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="button">
                <!-- <button class="connected">Connected</button> -->
               <label :class="org?.is_google_analytics_connected?'connected':'not-connected'">{{org?.is_google_analytics_connected?'Connected':'Not Connected'}}</label>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <WoocommerceIcon />
                </div>
                <div class="title">
              <p class="main_title">Woocommerce</p>
              <p class="sub_title">https://woocommerce.com/</p>
            </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="button">
                <!-- <button class="connected">Connected</button> -->
                <label :class="org?.is_woocommerce_connected?'connected':'not-connected'">{{org?.is_woocommerce_connected?'Connected':'Not Connected'}}</label>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <ShopifyIcon />
                </div>
                 <div class="title">
              <p class="main_title">Shopify</p>
              <p class="sub_title">https://www.shopify.com</p>
            </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="button">
                <!-- <button class="connected">Connected</button> -->
                <label :class="org?.is_shopify_connected?'connected':'not-connected'">{{org?.is_shopify_connected?'Connected':'Not Connected'}}</label>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <SendGridIcon />
                </div>
                <div class="title">
              <p class="main_title">SendGrid</p>
              <p class="sub_title">https://app.sendgrid.com/</p>
            </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="button">
                <label :class="org?.is_sendgrid_connected?'connected':'not-connected'">{{org?.is_sendgrid_connected?'Connected':'Not Connected'}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <i class="bi bi-clipboard-check"></i>Subscriptions 
            </h6>
          </div>
          <div class="contant-details"></div>
          <div class="row align-items-center py-3 line_section">
            <div class="col-lg-6">
              <div class="social_img_section">
                <div class="social_img">
                  <img width="58" height="58" src="https://img.icons8.com/external-sbts2018-outline-color-sbts2018/58/external-subscription-payment-1-sbts2018-outline-color-sbts2018-2.png" alt="external-subscription-payment-1-sbts2018-outline-color-sbts2018-2"/>
                </div>
                <div class="title">
                  <p class="main_title">Subscription status</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6" id="action-btns">
              <div 
              data-toggle="modal"
              data-target="#subscription"
              class="button">
                <label :class="org?.subscription_status?'connected':'not-connected'">{{org?.subscription_status?'On':'Off'}}</label>
              </div>
              <div 
              data-toggle="modal"
              data-target="#organization"
              class="button">
                <label class="del-btn">Delete Organization</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- model for subscription -->
    <div
      class="modal fade"
      id="subscription"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subscriptionmodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subscriptionmodelLabel">
              Subscription Status
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form
            @submit="changeSubscriptionStatus"
            class="form-horizontal"
            action="#"
          >
            <div class="modal-body p-3">
              Are you sure you want to turn {{ org?.subscription_status ? 'off' : 'on' }} the status?
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn">Yes</button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-subscription-model"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>


    <!-- delete first model -->
    <div
      class="modal fade"
      id="organization"
      tabindex="-1"
      role="dialog"
      aria-labelledby="organizationmodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="organizationmodelLabel">
              Delete Organization
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- <Form
            class="form-horizontal"
            action="#"
          > -->
            <div class="modal-body p-3">
              Are you sure you want to delete the {{ org?.name }} organization?
            </div>
            <div class="modal-footer">
              <button
                data-toggle="modal"
                data-target="#delete-model"
                type="submit" 
                class="save-btn"
              >
                Yes
              </button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-organization-model"
              >
                Cancel
              </button>
            </div>
          <!-- </Form> -->
        </div>
      </div>
    </div>

    <!-- delete second model -->
    <div
      class="modal fade"
      id="delete-model"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deletemodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deletemodelLabel">
              Delete Organization
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form
            @submit="showDeleteModel"
            class="form-horizontal"
            action="#"
          >
            <div class="modal-body p-3">
              Are you sure you want to delete?<br>
              All data for users associated with {{ org?.name }} will be permanently deleted.
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn">Yes</button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-second-delete-model"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import EditIcon from "../../icons/edit.vue";
import { GET_ORG, LIST_USERS,GET_ORG_STATS, GET_RECOMMENDATIONS_STATS } from "@/Core/store/action-types";
import { mapGetters, mapActions } from "vuex";
import MetaIcon from "../../icons/meta.vue";
import GoogleAdIcon from "../../icons/googleAd.vue";
import GoogleAnalyticsIcon from "../../icons/googleAnalytics.vue";
import WoocommerceIcon from "../../icons/woocommerce.vue";
import ShopifyIcon from "../../icons/shopify.vue";
import Pagination from "../../Core/Pagination";
import SendGridIcon from "../../icons/sendgrid.vue";
// import DeleteIcon from "../../icons/cancel";
import {
  SET_SUBSCRIPTION_STATUS, DELETE_ORG
} from "@/Core/store/action-types";

export default {
  name: "OrgDetailsGrid",
  components: {
    MetaIcon,
    GoogleAnalyticsIcon,
    GoogleAdIcon,
    WoocommerceIcon,
    ShopifyIcon,
    Pagination,
    SendGridIcon,
    // EditIcon,
    // CustomModel,
    // DeleteIcon,
  },
  data() {
    return {
      page: 1,
      admin_users:null,
      totalPages: [],
      orgStats:null,
      userPage: 1,
      users:null,
      userTotalPages: [],
      subscriptionData: {},
      recommendationsStats:null
    };
  },
  computed: {
    ...mapGetters("org", ["org"]),
  },
  methods: {
    ...mapActions("org", [GET_ORG,GET_ORG_STATS,SET_SUBSCRIPTION_STATUS, DELETE_ORG, GET_RECOMMENDATIONS_STATS]),
    ...mapActions("user", [LIST_USERS]),
    InItOrgDetails() {
      if (this?.$route?.params?.id && this.$route.params.id != "") {
        this[GET_ORG](this?.$route?.params?.id)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fetchRecommendationsStats() {
      const orgId = this.$route.params.id; // or however you get the org ID
      this.$store.dispatch('org/GET_RECOMMENDATIONS_STATS', orgId)
        .then(data => {
          this.recommendationsStats = data;
          console.log("this.recommendationsStats :", this.recommendationsStats)
        })
        .catch(error => {
          console.error('Failed to fetch recommendations stats:', error);
        });
    },
    formatPercentage(value) {
      const num = parseFloat(value);
      if (isNaN(num) || num === 0) return '0%';
      return `${(num * 100).toFixed(2)}%`;
    },
    initAdminUser(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        persist: false,
        page: this.page,
        merchant: 1,
        organization: this?.$route?.params?.id,
      };
      this[LIST_USERS](options)
        .then((res) => {
          this.admin_users = res;
          this.totalPages = Array(Math.ceil(res.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initUser(pageNumber = null) {
      pageNumber ? (this.userPage = pageNumber) : null;
      let options = {
        persist: false,
        page: this.userPage,
        worker: 1,
        organization: this?.$route?.params?.id,
      };
      this[LIST_USERS](options)
        .then((res) => {
          this.users = res;
          this.userTotalPages = Array(Math.ceil(res.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initOrgStats(){
      this[GET_ORG_STATS](this?.$route?.params?.id).then((res) => {
        this.orgStats = res
      }).catch((err) => {
          console.log(err);
        });
    },
    userIncrementpage() {
      this.userPage = this.userPage + 1;
      this.initUser();
    },
    userDecrementpage() {
      this.userPage = this.userPage - 1;
      this.initUser();
    },
    userSetpage(page) {
      this.userPage = page;
      this.initUser();
    },
    incrementpage() {
      this.page = this.page + 1;
      this.initAdminUser();
    },
    decrementpage() {
      this.page = this.page - 1;
      this.initAdminUser();
    },
    setpage(page) {
      this.page = page;
      this.initAdminUser();
    },
    changeSubscriptionStatus (event) {
      event.preventDefault();
      this.subscriptionData['status'] = !this.org.subscription_status
      this.subscriptionData['org_id'] = this.org.id

      this[SET_SUBSCRIPTION_STATUS](this.subscriptionData)
        .then(() => {
          this.$alertify.notify(
            "Subscription status updated successfully.",
            "success",
            3
            );

            document.getElementById("close-subscription-model").click();

            // call the GET_ORG for the update status of the subscription
            this[GET_ORG](this?.$route?.params?.id)
              .then(() => {
              })
              .catch((error) => {
                console.log(error);
              });

          })
        .catch((e) => {
          console.log(e?.response?.data?.detail);
          this.$alertify.notify(
            e?.response?.data?.detail || "Some error occured, please try again",
            "error",
            3
          );
        })
    },
    showDeleteModel(e) {
      e.preventDefault();
      this[DELETE_ORG](this.org.id)
      .then(() => {
        this.$alertify.notify(
          `${this.org.name} successfully deleted.`,
          "success",
          3
        );
      })
      .catch(() => {
        this.$alertify.notify("Action Forbidden", "error", 3);
      })
      .finally(()=> {
        document.getElementById("close-organization-model").click();
        document.getElementById("close-second-delete-model").click();
        this.$router.push({ name: "Organizations"});
      });
    },
  },
  mounted() {
    this.InItOrgDetails();
    this.initAdminUser();
    this.initUser();
    this.initOrgStats();
    this.fetchRecommendationsStats();
  },
};
</script>

<style scoped>
.bottom__title span {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
.bottom__title span:nth-child(1) {
  color: #0bc984 !important;
  margin-right: 5px !important;
}

.bottom__title span:nth-child(2) {
  color: #aaabad !important;
}
.bottom__title_recommendation span{
  font-size: 16px !important;
  margin-bottom: 0 !important;
  color: #5e66fa !important;
}
.social_img_section {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-start;
}
.title p {
  margin: 0;
}

.button button,.button label {
  background: #5e66fa;
  border: 0;
  padding: 8px 48px;
  border-radius: 19px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.title .main_title {
  font-size: 16px;
  font-weight: 500;
  color: #000352;
}
.button {
  text-align: center;
}

.button .connected {
  background: #0bc984;
  pointer-events: none;
  cursor: pointer !important;
}
.button .pending {
  background: #ffa010;
}
.button .not-connected {
  background: #8f8f8f !important;
  cursor: pointer !important;
}

i.bi.bi-clipboard-check {
  color: #656dfa;
  margin-right: 13px
}

div#action-btns {
  display: flex;
  gap: 25px;
}

.del-btn {
  background-color: red !important;
  cursor: pointer;
}

</style>
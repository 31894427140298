<template>
  <div class="main-wrp">
    <MainMenu />
    <TopBar title="Top Trending Articles" :author="{ home: 'Home' }" />
    <div class="box-wrapper">
      <!-- heading -->
      <div class="data-heading-wrp">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <h5 class="section-title mb-0">
              <img src="@/../public/assets/images/star-icon.png" alt="star" />
              Top Trending Articles
            </h5>
          </div>
          <div class="col-auto">
            <div class="action-wrapper d-flex align-items-center gap-4">
              <span class="filter-wrp">
                <select
                  class="form-select"
                  id="adminfilter"
                  v-model="direction"
                  style="min-width: 75px"
                  @change="onDirectionChange"
                >
                  <option v-for="option in directionOptions" :key="option">
                    {{ option }}
                  </option>
                </select>
              </span>
              <span class="filter-wrp">
                <select
                  class="form-select"
                  id="adminfilter"
                  v-model="time"
                  @change="onTimeChange"
                >
                  <option v-for="option in timeOptions" :key="option">
                    {{ option }}
                  </option>
                </select>
              </span>
              <button type="button" class="save-btn" @click="initCustomerList(true)">
                Export List
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow">
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <table class="table my-table-wrp table-sm" id="my-table">
          <thead>
            <tr>
              <th width="20%">Id</th>
              <th width="40%">Name</th>
              <th width="10%">Previous Period</th>
              <th width="10%">Current Period</th>
              <th width="15%">Change</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="article in all_trending_articles
                ? all_trending_articles.slice(25 * (page_model - 1), page_model * 25)
                : []"
              :key="article.id"
              :class="checkedFilter(article.id) ? 'active-row selected' : ''"
            >
              <td class="font-light-text" width="15%">
                {{ article.sku }}
              </td>
              <td width="40%">{{ article.name }}</td>
              <td class="font-light-text" width="10%">
                {{ article.sales_0 }}
              </td>
              <td class="font-light-text" width="10%">
                {{ article.sales_1 }}
              </td>
              <td width="15%">
                {{ article.diff }}%
                <DownArrow v-if="article.diff < 0" />
                <UpArrow v-else />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :page="page_model"
        :totalPages="
          all_trending_articles
            ? Array(Math.ceil(all_trending_articles.length / 25))
                .fill(0)
                .map((e, i) => i + 1)
            : []
        "
        :name="'Articles'"
        :count="all_trending_articles ? all_trending_articles.length : 0"
        :incrementpage="incrementpage_model"
        :decrementpage="decrementpage_model"
        :setpage="setpage_model"
        :perpage="25"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  TRENDING_ARTICLES,
  CREATE_AUDIENCE,
  LIST_CUSTOMERS,
} from "@/Core/store/action-types";
import UpArrow from "../../../icons/upArrow.vue";
import DownArrow from "../../../icons/downArrow.vue";
import Pagination from "../../../Core/Pagination.vue";
import MainMenu from "@/Menu/MainMenu.vue";
import TopBar from "@/Menu/TopBar.vue";
export default {
  name: "TopTrendingArticlesView",
  components: {
    MainMenu,
    TopBar,
    UpArrow,
    DownArrow,
    Pagination,
  },
  data() {
    return {
      page_model: 1,
      all_trending_articles: [],
      top_trending_articles: [],
      choosen_articles: [],
      direction: "up",
      directionOptions: ["up", "down"],
      time: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      customerIDs: [],
      createAudienceMode: false,
      nameError: false,
      errMsg: "",
      audienceName: "",
      isAllSelected: false,
      loadindShow: false,
      pref_options: [
        { value: "has_bought", key: "Has bought" },
        {
          value: "likely_to_buy",
          key: "Likely to buy ",
        },
        {
          value: "similar_articles",
          key: "Similar articles",
        },
        {
          value: "likely_to_buy_again",
          key: "Likely to buy Again",
        },
        { value: "all_fields", key: "All Fields" },
      ],
      pref_type: "",
      pref_type_error: "",
    };
  },
  methods: {
    ...mapActions("article", [TRENDING_ARTICLES]),
    ...mapActions("audience", [CREATE_AUDIENCE]),
    ...mapActions("customer", [LIST_CUSTOMERS]),
    initTrendingArticles(isAll = true) {
      let params = {
        date_after:
          this.time == "month"
            ? this.days_ago_date(30)
            : this.time == "week"
            ? this.days_ago_date(7)
            : this.time == "quater"
            ? this.days_ago_date(90)
            : this.time == "half a year"
            ? this.days_ago_date(180)
            : this.days_ago_date(365),
        date_before: this.today_date(),
        all: isAll ? isAll : null,
      };
      this.loadindShow = true;
      this[TRENDING_ARTICLES]({ direction: this.direction, params })
        .then((result) => {
          if (isAll) this.all_trending_articles = result;
          else this.top_trending_articles = result;
        })
        .catch(() => (this.top_trending_articles = []))
        .finally(() => (this.loadindShow = false));
    },
    onAudienceCreate() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.customerIDs.length <= 0) {
        this.$alertify.notify("No customers found to create audience", "error", 3);
      } else {
        this.nameError = false;
        let payload = {
          members: this.customerIDs,
          name: this.audienceName,
          articles: this.choosen_articles,
          article_pref_type: this.pref_type,
          gender: "All",
          churn_status: "All",
          age_min: 0,
          age_max: 100,
        };

        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audienceName} successfully created`,
              "success",
              3
            );
            this.createAudienceMode = false;
          })
          .catch((e) => {
            console.log("in aud", e.message);
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },

    getAllArticleIDs() {
      return this.all_trending_articles.map((a) => a.id);
    },
    getCustomerIDs(customers) {
      return customers.map((a) => a.id);
    },
    initCustomerList(export_list = false) {
      let options = {
        params: {
          pref_type: this.choosen_articles.length != 0 ? this.pref_type : null,
          prefs: this.choosen_articles != 0 ? this.choosen_articles : null,
          bought_all: false,
        },
      };
      this[LIST_CUSTOMERS](options)
        .then((resp) => {
          if (resp && resp?.length == 0)
            this.$alertify.notify(
              "No customers found for choosen articles and prefrence type",
              "error",
              3
            );
          else if (export_list == true) this.generateCSV(resp);
          else {
            this.customerIDs = this.getCustomerIDs(resp);
            this.onAudienceCreate();
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    generateCSV(customers) {
      let arrData = [
        {
          customers: customers,
        },
      ];
      let csvContent = [
        Object.keys(arrData[0].customers[0] || {}).join(","), // Header
        ...arrData[0].customers.map(
          (item) => Object.values(item).join(",") // Rows
        ),
      ].join("\n");

      // Clean up unwanted characters (e.g., #)
      csvContent = csvContent.replace(/#/g, "");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `trending-article-customers-${this.direction}-${this.time}.csv`
      );
      link.click();
      // Clean up: Revoke the Object URL to free up memory
      URL.revokeObjectURL(url);
    },

    AddRemoveArticles(id) {
      this.isAllSelected = false;
      setTimeout(() => {
        if (this.choosen_articles.includes(id)) {
          this.choosen_articles = this.choosen_articles.filter(
            (article_id) => article_id != id
          );
        } else {
          this.choosen_articles.push(id);
        }
      }, 300);
    },
    AddRemoveAllArticles() {
      if (this.isAllSelected) {
        this.choosen_articles = [];
        this.isAllSelected = false;
      } else {
        this.choosen_articles = this.getAllArticleIDs();
        this.isAllSelected = true;
      }
    },
    checkedFilter(id) {
      if (this.choosen_articles.includes(id)) return true;
      else return false;
    },
    onDirectionChange() {
      this.initTrendingArticles();
    },
    onTimeChange() {
      this.initTrendingArticles();
    },
    days_ago_date(days) {
      var d = new Date(new Date().setDate(new Date().getDate() - days));
      return d.toISOString(); // Formats date in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)
    },
    today_date() {
      var d = new Date();
      return d.toISOString(); // Formats date in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)
    },

    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
  },
  watch: {
    pref_type(value) {
      if (this.choosen_articles.length > 0 && (value == "" || !value))
        this.pref_type_error = "please select articles preference type";
      else this.pref_type_error = "";
    },
    choosen_articles(value) {
      if (value.length == 0) {
        (this.pref_type_error = ""), (this.errMsg = "");
      }
    },
    audienceName(value) {
      if (!value) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (value.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else {
        this.nameError = false;
        this.errMsg = "";
      }
    },
  },
  mounted() {
    this.initTrendingArticles();
  },
};
</script>
<style scoped>
.box-wrapper {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.main-pagination {
  padding: 20px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table > :not(:first-child) {
  border-top: 0;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-wrp select {
  font-size: 14px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border: none;
  border-radius: 10px;
  padding: 10px;
}
.action-wrapper {
  white-space: nowrap;
}
</style>

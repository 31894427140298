<template>
  <!-- Change Password Page -->
  <div class="container-full d-flex align-items-center">
    <div class="container-xxl">
      <div class="row d-flex justify-content-center">
        <div class="col-12 align-self-center">
          <div class="">
            <div class="card-body p-0">
              <div class="row d-flex justify-content-center align-items-center welcome-gap">
                <!-- Change Password Form Section -->
                <div class="col-lg-5 mx-auto">
                  <div class="card card-box">
                    <div class="card-body p-0">
                      <div class="forgot-title-box">
                        <h2 class="form-title">Set New Password</h2>
                        <h4 class="form-paragraph">Choose a new password for your account. New Password must be at least
                          8 characters long and include 1 capital letter and 1 number.</h4>
                      </div>
                      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"
                        class="form-horizontal auth-form my-4" action="index.html">
                        <!-- New Password Field -->
                        <div class="form-group mb-3" :class="{ 'has-error': errors.new_password }">
                          <label for="new_password">New password</label>
                          <div class="position-relative">
                            <div class="control-icon">
                              <img src="@/../public/assets/images/lockicon.svg" alt="eye-icon" />
                            </div>
                            <Field as="input" class="form-control form-control-danger"
                              :type="[showPassword.indexOf(1) > 0 ? 'text' : 'password']" name="new_password"
                              placeholder="Enter new password" />
                            <div class="control">
                              <button type="button" class="button" @click="toggleShow(1)">
                                <span class="eye-iocon" :class="{
                                  'eye-off': showPassword.indexOf(1) > 0,
                                  'eye-on': showPassword.indexOf(2) < 0,
                                }">
                                  <img src=@/../public/assets/images/eye-icon.svg alt="" />
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="form-control-feedback">
                            {{ errors.new_password }}
                          </div>
                        </div>

                        <!-- Confirm Password Field -->
                        <div class="form-group mb-2" :class="{ 'has-error': errors.confirm_password }">
                          <label for="confirm_password">Confirm password</label>
                          <div class="position-relative">
                            <div class="control-icon">
                              <img src="@/../public/assets/images/lockicon.svg" alt="eye-icon" />
                            </div>
                            <Field as="input" :type="[showPassword.indexOf(2) > 0 ? 'text' : 'password']"
                              class="form-control" name="confirm_password" placeholder="Enter confirm password" />
                            <div class="control">
                              <button type="button" class="button" @click="toggleShow(2)">
                                <span class="eye-iocon" :class="{
                                  'eye-off': showPassword.indexOf(2) > 0,
                                  'eye-on': showPassword.indexOf(2) < 0,
                                }">
                                  <img src="@/../public/assets/images/eye-icon.svg" alt="" />
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="form-control-feedback">
                            {{ errors.confirm_password }}
                          </div>
                        </div>

                        <!-- Hidden Field (For Details) -->
                        <div class="form-group mb-0" :class="{ 'has-error': errors.detail }">
                          <Field as="input" type="hidden" class="form-control" name="detail" />
                          <div class="form-control-feedback">
                            {{ errors.detail }}
                          </div>
                        </div>

                        <!-- Submit and Links -->
                        <div class="form-group mt-4 row">
                          <div class="col-12 d-flex align-items-center flex-column">
                            <button class="btn btn-block waves-effect waves-light login-button w-100" type="submit">
                              Set New Password
                            </button>
                            <router-link to="/login" class="login-page-link">
                              <img src="@/../public/assets/images/arrow-left.svg" alt="" />Back to Log in
                            </router-link>
                          </div>
                        </div>
                      </Form><!--end form-->
                    </div>
                  </div>
                </div>
                <!-- Welcome Back Section (Right Side) -->
                <ImageSlider />
              </div><!--end row-->
            </div><!--end card-body-->
          </div><!--end card-->
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-xxl-->
  </div><!--end container-full-->
  <!-- End Change Password Page -->
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import { mapActions } from "vuex";

import { RESET_PASSWORD } from "@/Core/store/action-types";
import ImageSlider from "./components/ImageSlider.vue";

export default {
  name: "ChangePassword",
  components: {
    Form,
    Field,
    ImageSlider
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [RESET_PASSWORD]),

    onSubmit(values, actions) {
      console.log("egfewgfeg", values);
      let id = this?.$route?.params?.id;
      let token = this?.$route?.params?.token;
      console.log(id, token);
      this[RESET_PASSWORD]({ values, actions, token, id })
        .then(() => {
          this.$alertify.notify(`password changed successfully.`, "success", 3);
          // got to the login
          this.$router.push("/login");
        })
        .catch(() => { }); //stay on a page
    },
    toggleShow(id) {
      let i = this.showPassword.indexOf(id);
      if (i > 0) {
        this.showPassword.splice(i, 1);
      } else {
        this.showPassword.push(id);
      }
    },
  },
  data() {
    const schema = yup.object().shape({
      new_password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      schema,
      showPassword: [0],
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.img-wrp {
  border-radius: 12px;
  border-top: 5px solid rgb(137, 142, 244);
  border-left: 5px solid rgb(137, 142, 244);
  border-right: 5px solid rgb(137, 142, 244);

}

.container-full {
  background: #fff;
  height: auto;
  padding: 40px 0;
}

.card-box {
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
}

.card {
  border: 0;
}

.login-button {
  background-color: #5e66fa;
  color: #ffffff;
  height: 46px;
  font-size: 14px;
  /* font-weight: 600; */
  border-radius: 8px;
}

.login-button:hover {
  background-color: #727aff;
  color: #fff;
}

.forgot-password {
  color: #5e66fa;
  font-size: 14px;
  font-weight: 500;
}

.form-title {
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1a2b3a;
}

.form-group label {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.64px;
  text-align: left;
  color: #1a2b3a;
  margin-bottom: 12px;
}

.form-group .form-control {
  font-size: 14px;
  border: 0;
  font-weight: 400;
  color: #1a2b3a;
  padding: 8px 15px;
  background: #f5f7f9;
  height: 46px;
  border-radius: 8px;
  padding-left: 47px;
}

.form-paragraph {
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: left;
  color: #485561;
  margin-bottom: 30px;
}

.control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}

.control .button {
  border: none;
  background: transparent;
  padding: 0;
  margin-right: 0 !important;
}

.control .button .eye-iocon.eye-off {
  position: relative;
}

.control .button .eye-iocon.eye-off:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 1px;
  background: black;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate3d(1, 1, 1, -45deg);
  top: 50%;
}


.control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.form-check-input:checked {
  background-color: #565df2;
  border-color: #565df2;
}

.form-control-feedback {
  line-height: 12px;
}

.login-page-link {
  border-radius: 8px;
  margin-left: 0;
  color: #1a2b3a;
  font-style: normal;
  height: 46px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d5dd;
  margin-top: 30px;
  gap: 8px;
}

.login-page-link:hover {
  border: 1px solid #d0d5dd;
  background: #d0d5dd;
}

@media (min-width: 992px) {
  .container-full {
    height: 100vh;
    padding: 0 0;
  }
}
</style>
<template>
  <div class="sales-all-table">
    <div class="contant-details h-100">
      <div class="overflow-auto position-relative">
        <div class="loader-main" v-if="showLoading">
          <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <table class="table m-0" id="my-table">
          <thead>
            <tr>
              <th>Customer</th>
              <th>Last Order (date)</th>
              <th>Restock Period (date)</th>
              <th>Contact Made</th>
              <th>Placed Order</th>
              <th></th>
            </tr>
          </thead>
          <tbody 
            v-for="(customer) in customerItems"
            :key="customer.customer_id"
          >
            <tr class="perent-row">
              <td @click="detail(customer.customer_id)" style="cursor: pointer;">{{ customer.company_name }}</td>
              <td>{{ customer.last_purchase_date }}</td>
              <td>{{ customer.predicted_restock_date }}</td>
              <td>
                <select 
                  v-model="customer.contact_made" 
                  name="ContactMode" 
                  id="ContactMode"
                  @change="updateContactMode(customer.customer_id, customer.contact_made)"
                >
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </td>
              <td>
                <select 
                  v-model="customer.purchased" 
                  name="PlaceOrder" 
                  id="PlaceOrder"
                  @change="updatePlaceOrder(customer.customer_id, customer.purchased)"
                >
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </td>
              <td align="right">
                <button @click="toggleRowActive(customer.customer_id)" :class="{ 'active': activeRows[customer.customer_id] }" class="accordian-arrow" >
                  <img src="../../../public/assets/images/arrow-down.svg" alt="Arrow" />
                </button>
              </td>
            </tr>
            <tr :class="{ 'highlight': activeRows[customer.customer_id] }" class="child-row" >
              <td colspan="6">
                <h3>Likely articles</h3>
                <div class="border-div">
                  <table class="table m-0">
                    <thead>
                      <tr>
                        <th>Article name</th>
                        <th>Article ID</th>
                        <th>Last order date</th>
                        <th>Likely Re-stock period</th>
                        <th>Contact information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Check if there are no articles -->
                      <tr v-if="!customer.likelyArticles?.length">
                        <td colspan="4" class="text-center">No data available</td>
                        <!-- Always show customer info if available, in the 5th column -->
                        <td style="width: 28%;">
                          <div class="contact-info-box" v-if="customer.company_name || customer.customer_phone || customer.customer_email">
                            <div class="row">
                              <div class="col-lg-7">
                                <p>Contact name</p>
                                <h5>{{ customer.company_name || 'N/A' }}</h5>
                              </div>
                              <div class="col-lg-5">
                                <p>Phone no.</p>
                                <h5>{{ customer.customer_phone || 'N/A' }}</h5>
                              </div>
                              <div class="col-lg-12">
                                <p>Email</p>
                                <h5>{{ customer.customer_email || 'N/A' }}</h5>
                              </div>
                            </div>
                          </div>
                          <!-- If no customer info is available, show 'No data available' -->
                          <div v-else class="text-center">No data available</div>
                        </td>
                      </tr>
                      <!-- Dynamic rendering of articles -->
                      <tr v-for="(article, index) in customer.likelyArticles" :key="article.article_id || article.article_name">
                        <td>{{ article.article_name || 'N/A' }}</td>
                        <td>{{ article.article_sku || 'N/A' }}</td>
                        <td>{{ article.last_order_date || 'N/A' }}</td>
                        <td>{{ article.purchase_period_start || 'N/A' }} - {{ article.purchase_period_end || 'N/A' }}</td>
                        <td v-if="index === 0" style="width: 28%;" rowspan="4">
                          <div class="contact-info-box">
                            <div class="row">
                              <div class="col-lg-7">
                                <p>Contact name</p>
                                <h5>{{ customer.company_name || 'N/A' }}</h5>
                              </div>
                              <div class="col-lg-5">
                                <p>Phone no.</p>
                                <h5>{{ customer.customer_phone || 'N/A' }}</h5>
                              </div>
                              <div class="col-lg-12">
                                <p>Email</p>
                                <h5>{{ customer.customer_email || 'N/A' }}</h5>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination 
          :page="page" 
          :totalPages="totalPages" 
          :name="'Customers'"
          :count="count ? count : 0" 
          :incrementpage="incrementPage"
          :decrementpage="decrementPage" 
          :setpage="setPage" 
          :perpage="10" 
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "../../Core/Pagination.vue";
import { mapActions } from "vuex";
import {
  MOST_LIKELY_TO_BUY_PRODUCT,
  GET_CUSTOMER_INACTIVE,
  GET_CUSTOMER_RESTOCK,
  GET_CUSTOMER_NOORDER,
  CUSTOMER_CONTACT_MODE_UPDATE,
  CUSTOMER_PURCHASED_ORDER_MODE_UPDATE
} from "@/Core/store/action-types";

export default {
  name: "Table",
  components: {
    Pagination,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
      default: "re-stock",
    },
    selectedDateRange: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      asc: null,
      des: null,
      showLoading: false,
      page: 1,
      count: 0,
      customerItems: [],
      totalPages: [],
      activeRows: {}, // Tracks expanded rows
      showModal: false,
    };
  },
  watch: {
    activeTab: "onFilterChange",
    page: "onFilterChange",
    startDate: "onFilterChange",
    endDate: "onFilterChange",
  },
  methods: {
    ...mapActions("article", [MOST_LIKELY_TO_BUY_PRODUCT]),
    ...mapActions("customer", [
      GET_CUSTOMER_INACTIVE,
      GET_CUSTOMER_RESTOCK,
      GET_CUSTOMER_NOORDER,
      CUSTOMER_CONTACT_MODE_UPDATE,
      CUSTOMER_PURCHASED_ORDER_MODE_UPDATE,
    ]),

    // Generic handler for API calls based on the activeTab
    async fetchCustomers() {
      const tabMethods = {
        "inactive-customers": GET_CUSTOMER_INACTIVE,
        "re-stock": GET_CUSTOMER_RESTOCK,
        "no-orders": GET_CUSTOMER_NOORDER,
      };

      const action = tabMethods[this.activeTab];
      if (!action) return;

      this.showLoading = true;
      try {
        const options = {
          persist: false,
          page: this.page,
          start_date: this.startDate,
          end_date: this.endDate,
        };

        const resp = await this[action](options);
        this.customerItems = resp.results.customers;
        this.count = resp.count;
        this.totalPages = Array.from(
          { length: Math.ceil(resp.count / 10) },
          (_, i) => i + 1
        );
      } catch (error) {
        console.error(`Error fetching ${this.activeTab}:`, error);
        this.customerItems = [];
      } finally {
        this.showLoading = false;
      }
    },

    // Triggered when filters or pagination change
    onFilterChange() {
      this.customerItems = [];
      this.fetchCustomers();
    },

    // Fetch details for a specific customer
    async initProductDetails(customerId) {
      try {
        customerId = customerId || this?.$route?.params?.customerId;
        if (!customerId) return;

        const customer = this.customerItems.find(
          (c) => c.customer_id === customerId
        );
        if (customer?.likelyArticles?.length) return;

        this.showLoading = true;
        const response = await this[MOST_LIKELY_TO_BUY_PRODUCT](customerId);

        this.customerItems = this.customerItems.map((c) =>
          c.customer_id === customerId
            ? { ...c, likelyArticles: response }
            : c
        );
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        this.showLoading = false;
      }
    },

    // Toggle row expansion and fetch details
    toggleRowActive(customerId) {
      this.activeRows[customerId] = !this.activeRows[customerId];
      if (this.activeRows[customerId]) {
        this.initProductDetails(customerId);
      }
    },

    // Update contact mode
    async updateContactMode(customerId, contactMade) {
      try {
        const options = { id: customerId, contact_made: contactMade };
        const response = await this[CUSTOMER_CONTACT_MODE_UPDATE](options);
        this.notify(response.message || "Successfully updated.");
      } catch (error) {
        console.error("Error updating contact mode:", error);
        this.notify("Failed to update contact mode.", "error");
      }
    },

    // Update order status
    async updatePlaceOrder(customerId, purchased) {
      try {
        const options = { id: customerId, purchased };
        const response = await this[CUSTOMER_PURCHASED_ORDER_MODE_UPDATE](options);
        this.notify(response.message || "Successfully updated.");
      } catch (error) {
        // Extract the error message from the backend response
        const errorMessage =
          error.response?.data?.error || "Failed to update order status.";
        this.notify(errorMessage, "error");
      }
    },

    // Navigate to customer details
    detail(customerId) {
      this.showModal = true;
      this.$router.push({
        name: "CustomerDetailsGrid",
        params: { customerId },
      });
    },

    // Notification helper
    notify(message, type = "success") {
      this.$alertify.notify(message, type, 3);
    },

    // Pagination handlers
    incrementPage() {
      if (this.page < this.totalPages.length) this.page++;
    },
    decrementPage() {
      if (this.page > 1) this.page--;
    },
    setPage(page) {
      if (page > 0 && page <= this.totalPages.length) this.page = page;
    },
  },
  mounted() {
    if (this.$route?.params?.customerId) {
      this.initProductDetails(this.$route.params.customerId);
    }
  },
};

</script>
<style scoped>
.loader-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}
.sales-all-table {
  position: relative;
  background: #fff;
  border: 1px solid #E9EBED;
  border-radius: 12px;
}

table tr th {
  color: #4F4D55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-top: 0;
  border-bottom: 1px solid #E9EBED !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

table tr th:first-child {
  border-top-left-radius: 12px;
}

table tr th:last-child {
  border-top-right-radius: 12px;
}

table tr td {
  padding: 12px 20px;
  color: #4F4D55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #E9EBED;
}

.table> :not(:first-child) {
  border-top: 0;
}

.table select {
  -webkit-appearance: none;
  border: 1px solid #4F4D551A;
  border-radius: 5px;
  color: #4F4D55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 56px;
  height: 26px;
  background-image: url('../../image/arrow-down.svg') !important;
  background-repeat: no-repeat;
  background-position-x: 90% !important;
  background-position-y: 4px !important;
  background-size: 15px 15px;
  padding-left: 6px;
}

button.accordian-arrow {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  transform: rotate(-90deg);
}

.child-row h3 {
  color: #1A2B3A;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding-bottom: 10px;
}

.child-row tr td {
  border: 0;
}

.contact-info-box {
  background: #565DF21A;
  border-left: 4px solid #565DF2;
  border-radius: 5px;
  padding: 15px;
}

.contact-info-box p {
  margin: 0;
  color: #1A2B3A;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
}

.contact-info-box h5 {
  color: #1A2B3A;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  word-break: break-word;
}

.border-div {
  border: 1px solid #E9EBED;
  border-radius: 12px;
}

.accordian-arrow.active {
  transform: none;
}

.child-row {
  display: none;
}

.child-row.highlight {
  display: table-row;
}

.contant-header {
  display: none;
}

.table-wrp table tr:hover {
  background: transparent;
}

nav.main-pagination {
  padding: 20px;
}
</style>
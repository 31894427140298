<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar 
      title="Inventory ViewAll" 
      :author="{home: 'Home'}"
    />
    <div class="main-wrp">
      <div class="inventory-page">
        <CommonViewAll />

<!-- 
        <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="nationalwarehouse-tab" data-toggle="pill" href="#nationalwarehouse" role="tab" aria-controls="nationalwarehouse" aria-selected="true" @click="setActiveTab('national')">National Warehouse</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="centralwarehouse-tab" data-toggle="pill" href="#centralwarehouse" role="tab" aria-controls="centralwarehouse" aria-selected="false" @click="setActiveTab('central')">Central Warehouse</a>
          </li>
        </ul>
        
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="nationalwarehouse" role="tabpanel" aria-labelledby="nationalwarehouse-tab">
            <div class="inventory-box">
              <IntransitArticlesNationalWarehouse :activeTab="activeTab" />
            </div>
          </div>
          <div class="tab-pane fade" id="centralwarehouse" role="tabpanel" aria-labelledby="centralwarehouse-tab">
              <div class="inventory-box">
                <IntransitArticlesNationalWarehouse :activeTab="activeTab" />
              </div>
            </div>
          </div>
        </div> -->


      </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  LIST_ARTICLES,
  TRENDING_ARTICLES,
  LIST_AUDIENCE,
  GET_METRICS,
} from "@/Core/store/action-types";
import TopBar from "../Menu/TopBar.vue";
import MainMenu from "../Menu/MainMenu.vue";
import CommonViewAll from "./components/CommonViewAll.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  components: {
    MainMenu,
    Loading,
    TopBar,
    CommonViewAll
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
      generalMetrics: {
        totalOrders: 0,
        totalMembers: 0,
        totalProducts: 0,
        totalArticles: 0,
        get_avg_order_value: {},
        get_avg_clv_value: {},
        get_median_clv_value: {},
      },
      dateFilterParams: {},
      articles: {},
      audiences: {},
      activeTab: 'all',
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
  },
  methods: {

    ...mapActions("article", [LIST_ARTICLES, TRENDING_ARTICLES]),
    ...mapActions("metrics", [GET_METRICS]),
    ...mapActions("audience", [LIST_AUDIENCE]),
    // onDateChange(start, end) {
    //   this.dateFilterParams = {
    //     date_after: start.format(DATE_FORMAT),
    //     date_before: end.format(DATE_FORMAT),
    //   };
    //   this.initMetrics();
    //   // this.getArticles();
    //   this.getAudiences();
    // },

    onTimeChange() {
      this.fetchIntransitArticles();
    },
    async initMetrics() {
      
      // let params = this.dateFilterParams;
      let params = {
        currency: this.default_currency,
      };
      // params.is_active = 1;
      await this[GET_METRICS](params).then((res) => {
        this.generalMetrics = res;
        // this.convertCurrency();
      });
    },
    async getArticles() {
      // let params = this.dateFilterParams;
      let params = {};
      params.order = "-sold_items";
      params.limit = 3;
      params.direction = "up";
      await this[LIST_ARTICLES]({ params: params, persist: true }).then(
        (res) => {
          this.articles = res.results;
        }
      );
    },
    async getAudiences() {
      // let params = this.dateFilterParams;
      let params = {};
      params.limit = 3;
      await this[LIST_AUDIENCE]({ params: params, persist: true }).then(
        (res) => {
          this.audiences = res;
        }
      );
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    // async convertCurrency() {
    //   this.convertedValues.avg_clv_value = await currencyConverter(
    //     this.generalMetrics.get_avg_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_clv_value?.avg_clv_value
    //   );
    //   this.convertedValues.median_clv_value = await currencyConverter(
    //     this.generalMetrics.get_median_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_median_clv_value?.median_clv_value
    //   );
    //   this.convertedValues.avg_order_value = await currencyConverter(
    //     this.generalMetrics.get_avg_order_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_order_value?.avg_receipt_value
    //   );
    // },
    last_month_last_date() {
      var d = new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started.
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    one_month_ago_date() {
      var d = new Date(new Date().setDate(new Date().getDate() - 30));
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  },
  created() {
    // this.initDateRange = [
    //   this.predefindedDates["Last 7 Days"][0],
    //   this.predefindedDates["Last 7 Days"][1],
    // ];
    // this.onDateChange(this.initDateRange[0], this.initDateRange[1]);
    // this.$root.$refs.Dashboard = this;
    // this.getArticles();
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    this.initMetrics();
    // this.initValuableCustomer();
    // this.getAudiences();
  },
};
</script>
<style scoped>
</style> 

<template>
  <div class="main-wrp">
    <MainMenu />
    <TopBar title="Churn Potential Customers" :author="{ home: 'Home' }" />
    <div class="box-wrapper">
      <!-- heading -->
      <div class="data-heading-wrp">
        <div class="row align-items-center justify-content-between">
          <!-- title -->
          <div class="col-auto">
            <h5 class="section-title mb-0">
              <img src="@/../public/assets/images/star.svg" alt="star" />
              Churn Potential Customers
            </h5>
          </div>

          <!-- actions -->
          <div class="col-auto">
            <div class="action-wrapper d-flex align-items-center gap-4">
              <span class="date-display" v-if="churn_period_start && churn_period_end">
                {{ churn_period_start }} - {{ churn_period_end }}
                <img src="@/../public/assets/images/calendar.svg" alt="calendar" />
              </span>
              <button type="button" class="save-btn" @click="generateCSV()">
                Export List
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow">
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <table class="table my-table-wrp table-sm" id="my-table">
          <thead>
            <tr>
              <th>Customer Id</th>
              <th>Customer Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in all_churn_potential_customers
                ? all_churn_potential_customers.slice(
                    25 * (page_model - 1),
                    page_model * 25
                  )
                : []"
              :key="customer.id"
              :class="checkedFilter(customer.id) ? 'active-row selected' : ''"
            >
              <td class="font-light-text" width="20%">
                {{ customer.external_id }}
              </td>
              <td class="font-text" width="55%">{{ customer.company_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :page="page_model"
        :totalPages="
          all_churn_potential_customers
            ? Array(Math.ceil(all_churn_potential_customers.length / 25))
                .fill(0)
                .map((e, i) => i + 1)
            : []
        "
        :name="'Customers'"
        :count="all_churn_potential_customers ? all_churn_potential_customers.length : 0"
        :incrementpage="incrementpage_model"
        :decrementpage="decrementpage_model"
        :setpage="setpage_model"
        :perpage="25"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { GET_CHURN_POTENTIAL_CUSTOMERS } from "@/Core/store/action-types";
import Pagination from "../../../Core/Pagination.vue";
import TopBar from "@/Menu/TopBar.vue";
import MainMenu from "@/Menu/MainMenu.vue";

export default {
  name: "ChurnPotentialView",
  components: {
    Pagination,
    TopBar,
    MainMenu,
  },
  data() {
    return {
      page_model: 1,
      churn_potential_customers: [],
      all_churn_potential_customers: [],
      loadindShow: false,
      churn_period_start: "",
      churn_period_end: "",
      isAllSelected: false,
    };
  },
  methods: {
    ...mapActions("customer", [GET_CHURN_POTENTIAL_CUSTOMERS]),
    initChurnPotentialCustomers() {
      // if (isAll && this.all_churn_potential_customers.length > 0) return;
      this.loadindShow = true;
      let params = {
        duration:
          this.customerTime == "month"
            ? 30
            : this.customerTime == "week"
            ? 7
            : this.customerTime == "quater"
            ? 90
            : this.customerTime == "half a year"
            ? 180
            : 365,
        all: true,
      };
      this[GET_CHURN_POTENTIAL_CUSTOMERS](params)
        .then((result) => {
          this.all_churn_potential_customers = result.customers;
          // else this.churn_potential_customers = result.customers;
          this.churn_period_start = result.churn_period_start;
          this.churn_period_end = result.churn_period_end;
          this.loadindShow = false;
        })
        .catch(() => {
          this.all_churn_potential_customers = [];
          this.churn_period_start = "";
          this.churn_period_end = "";
        });
    },
    generateCSV() {
      if (this.all_churn_potential_customers.length === 0) {
        console.error("No Data to Export");
        return;
      }
      let csvContent = "";

      // Header
      csvContent +=
        Object.keys(this.all_churn_potential_customers[0] || {})
          .map((e) => `"${e}"`)
          .join(",") + "\n";

      // Data
      this.all_churn_potential_customers.forEach((item) => {
        const row = Object.values(item)
          .map(
            (field) => `"${field.toString().replace(/"/g, '""')}"` // Escape double quotes and wrap each field in double quotes
          )
          .join(",");
        csvContent += row + "\n";
      });

      // Clean up unwanted characters (e.g., #)
      csvContent = csvContent.replace(/#/g, "");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `churn-potential-customer.csv`);
      link.click();
      // Clean up: Revoke the Object URL to free up memory
      URL.revokeObjectURL(url);
    },

    onTimeChange() {
      this.initChurnPotentialCustomers();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    checkedFilter(id) {
      if (this.all_churn_potential_customers.includes(id)) return true;
      else return false;
    },
  },
  watch: {
    pref_type(value) {
      console.log("ff", value);
      if (this.choosen_customers.length > 0 && (value == "" || !value))
        this.pref_type_error = "please select customers preference type";
      else this.pref_type_error = "";
    },
    choosen_customers(value) {
      if (value.length == 0) {
        (this.pref_type_error = ""), (this.errMsg = "");
      }
    },
    audienceName(value) {
      if (!value) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (value.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else {
        this.nameError = false;
        this.errMsg = "";
      }
    },
  },
  mounted() {
    this.initChurnPotentialCustomers();
  },
};
</script>
<style scoped>
.box-wrapper {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.main-pagination {
  padding: 20px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table > :not(:first-child) {
  border-top: 0;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-display {
  font-size: 12px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border-radius: 10px;
  padding: 10px;
}
.date-display img {
  margin-bottom: 4px;
  margin-left: 10px;
}
</style>

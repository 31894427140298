<template>
  <div class="main-wrp">
  <div class="main-contant-box" v-if="formData">
    <div class="main-contant-box-inner">
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="./../../../public/assets/images/coolicon-icon.png"
                alt=""
              />Edit User Details
            </h6>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <!-- <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.company_name != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100">Compnay Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Compnay Name"
                      v-model="formData.company_name"
                      @keyup="validateName"
                      @blur="validateName"
                    />
                    <span class="error">{{ errMsg.company_name }}</span>
                  </div>
                </div> -->
                <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.email != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100"
                      >Email<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="formData.email"
                      @keyup="validateEmail"
                      @blur="validateEmail"
                    />
                    <span class="error">{{ errMsg.email }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.phone != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100">Phone no.</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Phone no."
                      v-model="formData.phone"
                      @keyup="validatePhone"
                      @blur="validatePhone"
                    />
                    <span class="error">{{ errMsg.phone }}</span>
                  </div>
                </div>
                <!-- <div
                  class="col-12"
                  :class="errMsg.billing_address != '' ? 'has-error' : ''"
                >
                  <div class="form-group">
                    <label class="form-label w-100">Billing Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Biling Address"
                      v-model="formData.billing_address"
                      @keyup="validateAdrdress"
                      @blur="validateAdrdress"
                    />
                    <span class="error">{{ errMsg.billing_address }}</span>
                  </div>
                </div>
                <div
                  class="col-lg-6"
                  :class="errMsg.city != '' ? 'has-error' : ''"
                >
                  <div class="form-group">
                    <label class="form-label w-100">City</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      v-model="formData.city"
                      @keyup="validateCity"
                      @blur="validateCity"
                    />
                    <span class="error">{{ errMsg.city }}</span>
                  </div>
                </div>
                <div
                  class="col-lg-6"
                  :class="errMsg.country != '' ? 'has-error' : ''"
                >
                  <div class="form-group">
                    <label class="form-label w-100">Country</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Country"
                      v-model="formData.country"
                      @keyup="validateCountry"
                      @blur="validateCountry"
                    />
                    <span class="error">{{ errMsg.country }}</span>
                  </div>
                </div>
                <div
                  class="col-lg-6"
                  :class="errMsg.postal_id != '' ? 'has-error' : ''"
                >
                  <div class="form-group">
                    <label class="form-label w-100">Postal Code</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Postal Code"
                      v-model="formData.postal_id"
                      @keyup="validatePincode"
                      @blur="validatePincode"
                    />
                    <span class="error">{{ errMsg.postal_id }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="form-label w-100">Vat No.</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Vat No."
                      v-model="formData.vat_no"
                    />
                  </div>
                </div> -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="form-label w-100">Organization</label>
                    <select
                      class="form-control"
                      v-model="formData.organization.id"
                      disabled
                    >
                      <option
                        v-for="org in orgsList"
                        v-bind:key="org.id"
                        v-bind:value="org.id"
                      >
                        {{ org.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-12 d-inline">
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="formData.isPasswordEdited"
                    />
                    <label for="checkbox" class="form-check-label"
                      >Edit password</label
                    >
                  </div>
                </div>
                <div class="col-12" v-if="formData.isPasswordEdited">
                  <div
                    class="form-group"
                    :class="errMsg.password != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100"
                      >Password<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model="formData.password"
                      @keyup="validatePassword"
                      @blur="validatePassword"
                    />
                    <span class="error">{{ errMsg.password }}</span>
                  </div>
                </div>
                <div class="col-12" v-if="formData.isPasswordEdited">
                  <div
                    class="form-group"
                    :class="errMsg.confirm_password != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100"
                      >Confirm Password<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Confirm Password"
                      v-model="formData.confirm_password"
                      @keyup="validateConfirmPassword"
                      @blur="validateConfirmPassword"
                    />
                    <span class="error">{{ errMsg.confirm_password }}</span>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-btns">
                    <button
                      type="button"
                      class="cancle-btn"
                      @click="
                        this.$router.push({
                          name: 'MerchantDetails',
                          params: { merchantid: formData.id },
                        })
                      "
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="save-btn"
                      @click.prevent="updateUSerDetails"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  GET_USER_DETAILS,
  UPDATE_USER,
  LIST_ORGS,
} from "@/Core/store/action-types";
import { EMAIL_REGEX, PHONE_REGEX } from "@/Core/helpers/utils";

export default {
  name: "EditCustomerForm",
  data() {
    return {
      formData: null,
      errMsg: {
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["userDetails"]),
    ...mapGetters("org", ["orgsList"]),
  },
  methods: {
    ...mapActions("user", [GET_USER_DETAILS, UPDATE_USER]),
    ...mapActions("org", [LIST_ORGS]),
    //get customer details
    InItUSerDetails() {
      if (
        this?.$route?.params?.merchantid &&
        this.$route.params.merchantid != ""
      ) {
        const merchantId = this.$route.params.merchantid;
        console.log("merchant", merchantId);
        this[GET_USER_DETAILS](merchantId).then(() => {
          this.formData = JSON.parse(JSON.stringify(this.userDetails));
        });
      }
    },
    //check email is valid or not
    validateEmail() {
      this.errMsg.email = "";
      if (!this.formData.email) {
        this.errMsg.company_name = "Please enter email";
      } else if (
        this.formData.email.length > 0 &&
        !EMAIL_REGEX.test(this.formData.email)
      ) {
        this.errMsg.email = "Email is invalid";
      } else {
        this.errMsg.email = "";
      }
    },
    validatePhone() {
      if (
        this.formData.phone.length > 0 &&
        !PHONE_REGEX.test(this.formData.phone)
      ) {
        this.errMsg.phone = "Phone is invalid";
      } else {
        this.errMsg.phone = "";
      }
    },
    //check phone No is valid or not
    validatePassword() {
      if (this.formData.isPasswordEdited) {
        if (!this.formData.password) {
          this.errMsg.password = "Please enter password.";
        } else if (this.formData.password.trim().length < 6) {
          this.errMsg.password = "Password must be at least 6 characters";
        } else {
          this.errMsg.password = "";
        }
      } else {
        this.errMsg.password = "";
      }
    },
    validateConfirmPassword() {
      if (this.formData.isPasswordEdited) {
        if (!this.formData.confirm_password) {
          this.errMsg.confirm_password = "Please enter confirm password.";
        } else if (this.formData.confirm_password != this.formData.password) {
          this.errMsg.confirm_password = "Passwords must match";
        } else {
          this.errMsg.confirm_password = "";
        }
      } else {
        this.errMsg.confirm_password = "";
      }
    },
    //update customer details
    async updateUSerDetails() {
      this.validateEmail();
      this.validatePhone();
      this.validatePassword();
      this.validateConfirmPassword();
      if (
        !this.errMsg.email &&
        !this.errMsg.phone &&
        !this.errMsg.password &&
        !this.errMsg.confirm_password
      ) {
        console.log("Updated Data===>", this.formData);
        let userToUpdate = {};
        userToUpdate.is_staff = this.formData.is_staff;
        userToUpdate.is_superuser = this.formData.is_superuser;
        userToUpdate.email = this.formData.email;
        userToUpdate.id = this.formData.id;
        userToUpdate.phone = this.formData.phone;

        if (this.formData.isPasswordEdited && this.formData.password)
          userToUpdate.password = this.formData.password;

        this[UPDATE_USER]({ userToUpdate }).then(() => {
          this.$alertify.notify(
            `Merchant updated successfully. `,
            "success",
            3
          );
          this.$router.push({
            name: "MerchantDetails",
            params: { merchantid: this.formData.id },
          });
        });
      }
    },
  },
  mounted() {
    this.userDetails
      ? (this.formData = JSON.parse(JSON.stringify(this.userDetails)))
      : this.InItUSerDetails();
    this.orgsList ? null : this[LIST_ORGS]();
  },
};
</script>
    
      

      
        
        

        

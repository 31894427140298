<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none">
    <rect y="0.5" width="44" height="44" rx="10" fill="#585BEF" fill-opacity="0.1"/>
    <g clip-path="url(#clip0_169_2802)">
    <path d="M32.0511 30.1911H16.3413C16.1481 30.1911 16.0653 29.9374 16.2166 29.8207L18.4035 28.14H30.8988C31.7872 28.14 32.524 27.4097 32.5413 26.5227L32.6813 19.3162C32.6973 18.4947 32.0957 17.7814 31.282 17.6597C30.8905 17.6012 30.5239 17.8703 30.4653 18.2624C30.4066 18.6544 30.6771 19.0192 31.0695 19.0778C31.1715 19.0931 31.2468 19.1817 31.2449 19.2843L31.1048 26.4948C31.1027 26.6057 31.0103 26.7042 30.8989 26.7042H18.7299L16.4629 16.9031L17.6135 17.0708C18.0058 17.1293 18.3716 16.857 18.4303 16.465C18.489 16.073 18.2185 15.7066 17.826 15.6479L16.1026 15.3898L15.4438 12.8288C15.3623 12.5114 15.076 12.2949 14.7479 12.2949H11.9508C11.5541 12.2949 11.2324 12.6164 11.2324 13.0128C11.2324 13.4092 11.554 13.7307 11.9508 13.7307H14.1905L17.3548 27.1205L15.3391 28.6734C14.123 29.6104 14.8069 31.6269 16.3412 31.6269H18.2271C18.1436 31.832 18.0978 32.0936 18.0978 32.3503C18.0978 33.5378 19.0708 34.4999 20.2668 34.4999C21.4629 34.4999 22.4359 33.5398 22.4359 32.3523C22.4359 32.0956 22.39 31.832 22.3065 31.6269H26.2301C26.1466 31.832 26.1008 32.0936 26.1008 32.3503C26.1008 33.5378 27.0738 34.4999 28.2698 34.4999C29.4658 34.4999 30.4389 33.5398 30.4389 32.3523C30.4389 32.0956 30.3931 31.832 30.3096 31.6269H32.0512C32.4479 31.6269 32.7696 31.3054 32.7696 30.909C32.7696 30.5126 32.4478 30.1911 32.0511 30.1911ZM20.2668 33.0604C19.8631 33.0604 19.5346 32.7383 19.5346 32.3423C19.5346 31.9463 19.8631 31.6242 20.2668 31.6242C20.6706 31.6242 20.9991 31.9463 20.9991 32.3423C20.9991 32.7383 20.6706 33.0604 20.2668 33.0604ZM28.2698 33.0604C27.866 33.0604 27.5376 32.7383 27.5376 32.3423C27.5376 31.9463 27.8661 31.6242 28.2698 31.6242C28.6736 31.6242 29.002 31.9463 29.002 32.3423C29.002 32.7383 28.6735 33.0604 28.2698 33.0604Z" fill="#565DF2"/>
    <path d="M24.37 20.9055C21.493 20.9055 19.1523 18.5716 19.1523 15.7028C19.1523 12.8339 21.493 10.5 24.37 10.5C27.2471 10.5 29.5877 12.8339 29.5877 15.7028C29.5877 18.5716 27.2471 20.9055 24.37 20.9055ZM24.37 11.9355C22.2853 11.9355 20.5892 13.6255 20.5892 15.7028C20.5892 17.7801 22.2853 19.4701 24.37 19.4701C26.4548 19.4701 28.1509 17.7801 28.1509 15.7028C28.1509 13.6255 26.4548 11.9355 24.37 11.9355Z" fill="#565DF2"/>
    <path d="M23.0982 17.53L21.9442 16.1606C21.6887 15.8574 21.7276 15.4046 22.0311 15.1493C22.3346 14.894 22.7877 14.9328 23.0433 15.2361L23.6774 15.9885L25.65 13.8926C25.9218 13.6038 26.3765 13.5898 26.6655 13.8613C26.9546 14.1329 26.9685 14.5871 26.6967 14.8759L24.1711 17.5594C23.9455 17.7969 23.3682 17.8439 23.0982 17.53Z" fill="#FFA500"/>
    </g>
    <defs>
    <clipPath id="clip0_169_2802">
    <rect width="24" height="24" fill="white" transform="translate(10 10.5)"/>
    </clipPath>
    </defs>
    </svg>
</template>

<script>
export default {
  name: "OrderUploadIcon",
};
</script>

<style scoped>
</style>
<template>
  <!-- Log In page -->
  <div class="container-full d-flex align-items-center">
    <div class="container-xxl">
      <div class="row d-flex justify-content-center">
        <div class="col-12 align-self-center">
          <div class="">
            <div class="card-body p-0">
              <div class="row d-flex justify-content-center align-items-center welcome-gap">
                <div class="col-lg-5">
                  <div class="media mb-5 justify-content-center d-none">
                    <img src="../../public/assets/images/crescofy-logo.png" height="55" alt="logo" class="auth-logo" />
                  </div>
                  <div class="card card-box">
                    <div class="card-body p-0">
                      <h2 class="form-title">Log in to your account.</h2>
                      <h4 class="form-paragraph">
                        Enter your email address and password to log in.
                      </h4>
                      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"
                        class="form-horizontal auth-form my-4 mb-0" action="index.html">
                        <div class="form-group mb-3" :class="{ 'has-error': errors.email }">
                          <label for="email">Email</label>
                          <div class="position-relative">
                            <div class="control-icon">
                              <img src="@/../public/assets/images/smsicon.svg" alt="" />
                            </div>
                            <Field as="input" type="email" class="form-control form-control-danger" name="email"
                              placeholder="Enter Email" />
                          </div>

                          <div class="form-control-feedback">
                            {{ errors.email }}
                          </div>
                        </div>
                        <!--end form-group-->

                        <div class="form-group mb-2" :class="{ 'has-error': errors.password }">
                          <label for="password">Password</label>
                          <div class="position-relative">
                            <div class="control-icon">
                              <img src="@/../public/assets/images/lockicon.svg" alt="eye-icon" />
                            </div>
                            <Field as="input" :type="[
                              showPassword.indexOf(1) > 0
                                ? 'text'
                                : 'password',
                            ]" class="form-control" name="password" placeholder="Enter password" />
                            <div class="control">
                              <button type="button" class="button" @click="toggleShow(1)">
                                <span class="eye-iocon" :class="{
                                  'eye-off': showPassword.indexOf(1) > 0,
                                  'eye-on': showPassword.indexOf(1) < 0,
                                }">
                                  <img src="@/../public/assets/images/eye-icon.svg" alt="" />
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="form-control-feedback">
                            {{ errors.password }}
                          </div>
                        </div>
                        <!--end form-group-->

                        <div class="form-group mb-0" :class="{ 'has-error': errors.detail }">
                          <Field as="input" type="hidden" class="form-control" name="detail" />
                          <div class="form-control-feedback">
                            {{ errors.detail }}
                          </div>
                        </div>
                        <!--end form-group-->

                        <div class="form-group mb-0 row">
                          <div class="col-12">
                            <div class="d-flex align-items-center justify-content-between gap-3 lh-base forgot-bottom">
                              <div class="form-check">
                                <input type="checkbox" id="rememberMe" v-model="rememberMe" class="form-check-input" />
                                <label for="rememberMe" class="form-check-label mb-0">Remember me</label>
                              </div>
                              <router-link to="/forgotpassword" class="forgot-password">
                                Forgot Password?</router-link>
                            </div>
                            <button class="btn btn-block waves-effect waves-light login-button w-100" type="submit">
                              <div class="spinner-border" role="status" style="width: 20px; height: 20px"
                                v-if="isLoading">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              <span v-else>Login</span>
                            </button>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end form-group-->
                      </Form><!--end form-->
                    </div>
                  </div>
                </div>
                <!-- Welcome Back Section (Right Side) -->
                <ImageSlider />
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end card-body-->
          </div>
          <!--end card-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

import { LOGIN, UPDATE_INITIAL_CURRENCY_CLV } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import ImageSlider from "./components/ImageSlider.vue";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ImageSlider
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [LOGIN]),
    ...mapActions("org", [UPDATE_INITIAL_CURRENCY_CLV]),
    onSubmit(values, actions) {
      this.isLoading = true;
      this[LOGIN]({ values, actions, ability: this.$ability })
        .then(() => {
          this[UPDATE_INITIAL_CURRENCY_CLV]();
          this.$router.push("/dashboard");

          // got to the homepage
        })
        .catch(() => {
          console.log("FEwwefew");
        }) //stay on a page
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleShow(id) {
      let i = this.showPassword.indexOf(id);
      if (i > 0) {
        this.showPassword.splice(i, 1);
      } else {
        this.showPassword.push(id);
      }
    },
  },
  data() {
    const schema = yup.object().shape({
      email: this.emailValidator(),
      password: yup
        .string()
        .min(3, "Password must be at least 6 characters")
        .required("Password is required"),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      isLoading: false,
      schema,
      showPassword: [0],
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.container-full {
  background: #fff;
  height: auto;
  padding: 40px 0;
}

.forgot-bottom {
  margin-bottom: 30px;
}

.card-box {
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
}

.authentication-bg {
  background: linear-gradient(148.66deg, #565df2 -1.13%, #555cf2 193.64%);
  border-radius: 25px;
}

.card {
  border: 0;
}

.login-button {
  background-color: #5e66fa;
  color: #ffffff;
  height: 46px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}

.login-button:hover {
  background-color: #727aff;
  color: #fff;
}

.forgot-password {
  color: #5e66fa;
  font-size: 14px;
  font-weight: 500;
}

.form-title {
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1a2b3a;
}

.form-group label {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.64px;
  text-align: left;
  color: #1a2b3a;
  margin-bottom: 12px;
}

.form-group .form-control {
  font-size: 14px;
  border: 0;
  font-weight: 400;
  color: #1a2b3a;
  padding: 8px 15px;
  background: #f5f7f9;
  height: 46px;
  border-radius: 8px;
  padding-left: 47px;
}

.form-paragraph {
  font-size: 14px;
  font-weight: normal;
  line-height: 28px;
  text-align: left;
  color: #485561;
  margin-bottom: 30px;
}

.control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}

.control .button {
  border: none;
  background: transparent;
  padding: 0;
  margin-right: 0 !important;
}

.control .button .eye-iocon.eye-off {
  position: relative;
}

.control .button .eye-iocon.eye-off:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 1px;
  background: black;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate3d(1, 1, 1, -45deg);
  top: 50%;
}

.auth-paragraph {
  font-size: 12px;
  margin-bottom: 20px;
  font-family: "Inter", serif;
}

.authentication-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  margin: auto;
}

.authentication-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Inter", serif !important;
}

.control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.form-check-input:checked {
  background-color: #565df2;
  border-color: #565df2;
}

.form-control-feedback {
  line-height: 12px;
}

.authentication-bg-up {
  z-index: 1;
}

.welcome-gap {
  gap: 30px;
}

.auth-paragraph br {
  display: none;
}

@media (min-width: 992px) {
  .authentication-bg {
    height: calc(100vh - 32px);
    max-height: 736px;
  }

  .auth-paragraph br {
    display: block;
  }

  .welcome-gap {
    gap: 0;
  }

  .container-full {
    height: 100vh;
    padding: 0 0;
  }
}

@media (min-width: 1200px) {
  .auth-paragraph {
    margin-bottom: 36px;
  }
}
</style>

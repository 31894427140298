<template>
  <div>
    <RegistrationModal
      v-bind:modalId="createModalId"
      name="User"
      selectedRole="Merchant"
      :is_existing="true"
    />
    <CustomModel
      v-if="deletedresource"
      :modalId="deleteUserModelID"
      :item="deletedresource"
      :onDelete="deleteUser"
      :resourseName="'User'"
      actionType="Delete"
    />
    <CustomModel
      v-if="deletedresource"
      :modalId="deactivateModelID"
      :item="deletedresource"
      :onDelete="deactivateUser"
      :resourseName="'User'"
      actionType="Deactivate"
    />
    <div class="main-wrp">
      <div class="contant-box-main">
        <div class="data-heading-wrp">
          <div class="data-heading">
            <div class="row">
                <div class="col-lg-4">
                  <div class="search-box-wrp">
                    <input
                      type="text"
                      class="form-control"
                      v-model="search"
                      name=""
                      placeholder="Search by the user email "
                    />
                    <a class="search_icon" href="#"
                      ><img
                        src="../../../public/assets/images/search_icon.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
                <div class="col-lg-4 text-center">
                  <div class="tab-box-wrp">
                    <ul>
                      <li
                        @click="changeStatus('all')"
                        :class="status == 'all' ? 'active' : ''"
                      >
                        All
                      </li>
                      <li
                        @click="changeStatus('active')"
                        :class="status == 'active' ? 'active' : ''"
                      >
                        Active
                      </li>
                      <li
                        @click="changeStatus('inactive')"
                        :class="status == 'inactive' ? 'active' : ''"
                      >
                        Inactive
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 text-right">
                  <div
                      class="data-heading-btn"
                      @click="showCreateDialog"
                      v-if="createpermission"
                    >
                      <button>+ create new user</button>
                    </div>
                </div>
                <div class="col-lg-2 mt-4">
                  <div
                  class="dt-col inc-index"
                  v-if="
                    getUserRole(userProfile) == 'Admin' ||
                    getUserRole(userProfile) == 'Super Admin'
                  "
                >
                  <div class="">
                    <!-- <label for="adminfilter">Organization</label> -->
                    <!-- <select
                      class="for"
                      id="adminfilter"
                      v-model="organization"
                      v-if="orgsList"
                    >
                      <option selected value="all">ALL</option>
                      <option :value="org.id" v-for="org in orgsList" :key="org.id">
                        {{ org.name }}
                      </option>
                    </select> -->
                    <Field v-model="organization" name="organization">
                      <Multiselect
                        v-if="orgsList"
                        :id="'orgs'"
                        valueProp="id"
                        label="name"
                        trackBy="name"
                        v-model="organization"
                        :options="orgsList"
                        :searchable="true"
                        placeholder="All"
                      />
                    </Field>
                  </div>
                </div>
                </div>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-md-3 dt-col inc-index">
            <div class="form">
              <select class="for" id="adminfilter" v-model="role">
                <option selected value="all">ALL</option>
                <option
                  :value="role"
                  v-for="role in getAvailableRoles('list')"
                  :key="role"
                >
                  {{ role }}
                </option>
              </select>
            </div>
          </div> -->
         
          
        </div>
        <div class="table-wrp overflow-auto position-relative">
          <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div> 
          <table class="table my-table-wrp table-sm" id="my-table">
            <thead>
              <tr>
                <th scope="col" v-for="f in fields" v-bind:key="f.id">
                  <div
                    class="table-head"
                    v-if="f === 'ID'"
                    @click="sortTable('id')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_id ? 'sort-active' : ''">&#8595;</span>
                      <span :class="desc_id ? 'sort-active' : ''">&#8593;</span>
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Email'"
                    @click="sortTable('email')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_email ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_email ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Created by'"
                    @click="sortTable('created')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_created ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_created ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Status'"
                    @click="sortTable('status')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_status ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_status ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Pending delete'"
                    @click="sortTable('pending_delete')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_delete ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_delete ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div class="table-head" v-else>
                    {{ f.split("_").join(" ") }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="user?.is_active ? 'active-row' : 'inactive-row'"
                v-for="user in users?.results"
                v-bind:key="user?.id"
              >
                <td width="5%" class="font-light-text">{{ user?.id }}</td>
                <td width="24%">{{ user?.email }}</td>
                <td width="15%" class="font-light-text">
                  {{ user?.organization?.name }}
                </td>
                <td width="5%" class="font-light-text">
                  {{ user?.no_of_user }}
                </td>
                <td width="15%" class="font-light-text">
                  {{ user?.created_by?.email }}
                </td>
                <td width="10%" class="font-light-text">
                  {{ user?.date_joined }}
                </td>
                <td width="10%" class="font-light-text">
                  {{ user?.last_login || "-" }}
                </td>
                <td width="8%">
                  <label class="status-active" v-if="user?.is_active">
                    Active
                  </label>
                  <label class="status-inactive" v-else>Inactive</label>
                </td>
                <td width="8%">
                  <label class="status-active" v-if="user?.delete_request">
                    yes
                  </label>
                  <label class="status-inactive" v-else>No</label>
                </td>
                <td class="btn-align" width="7%">
                  <div class="d-flex align-item-center">
                    <router-link
                      :to="{
                        name: `MerchantDetails`,
                        params: { merchantid: user?.id },
                      }"
                    >
                      <button class="action-btns ms-2 icon-effect">
                        <img src="@/../public/assets/images/eye-blue.svg" alt="Logo" />
                      </button>
                    </router-link>
                    <!-- <button
                      class="action-btns ms-2 delete-profile"
                      @click="showDeleteModel(user)"
                    >
                      <DeleteIcon />
                    </button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :page="page"
            :totalPages="totalPages"
            :name="'Users'"
            :count="users ? users.count : 0"
            :incrementpage="incrementpage"
            :decrementpage="decrementpage"
            :setpage="setpage"
            :perpage="10"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import EyeIcon from "../../icons/eye.vue";
// import DeleteIcon from "../../icons/cancel";
import {
  LIST_USERS,
  LIST_ORGS,
  DEACTIVATE_USER,
  FULL_DELETE_USER,
} from "@/Core/store/action-types";
import { mapActions, mapGetters } from "vuex";
import { UserRoleMixin, MERCHANT } from "@/Core/mixins/UserRoleMixin";
import Pagination from "../../Core/Pagination";
import RegistrationModal from "./RegistrationModal";
// import FormModal from "./FormModal";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../user.vars";
import CustomModel from "../../Core/CustomModel.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "merchantTable",
  components: {
    Pagination,
    // FormModal,
    CustomModel,
    // EyeIcon,
    // DeleteIcon,
    RegistrationModal,
    Multiselect,
  },
  mixins: [UserRoleMixin],
  computed: {
    ...mapGetters("org", ["getOrgById", "orgsList"]),
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  data() {
    return {
      fields: [
        "ID",
        "Email",
        "Company Name",
        "Users",
        "Created by",
        "Creation Date",
        "Last Login",
        "Status",
        "Pending delete",
        "",
      ],
      status: "all",
      search: "",
      page: 1,
      users: null,
      totalPages: [],
      role: "all",
      createModalId: "createDialog",
      deleteUserModelID: "deleteuserModel",
      deactivateModelID: "deactivateuserModel",
      deletedresource: {},
      organization: "all",
      asc_email: null,
      desc_email: null,
      asc_id: null,
      desc_id: true,
      asc_created: null,
      desc_created: null,
      asc_status: null,
      desc_status: null,
      asc_delete: null,
      desc_delete: null,
      createpermission: this.$ability.can("create", subject("Merchant", {})),
      loadindShow:false
    };
  },
  methods: {
    ...mapActions("user", [LIST_USERS, DEACTIVATE_USER, FULL_DELETE_USER]),
    ...mapActions("org", [LIST_ORGS]),
    initUser(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        persist: false,
        page: this.page,
        is_active:
          this.status == "all" ? null : this.status == "active" ? 1 : 0,
        q: this.search?.length < 3 ? null : this.search,
        merchant: 1,
        organization: this.organization !== "all" ? this.organization : null,
        asc_email: this.asc_email ? this.asc_email : null,
        desc_email: this.desc_email ? this.desc_email : null,
        asc_id: this.asc_id ? this.asc_id : null,
        desc_id: this.desc_id ? this.desc_id : null,
        asc_created: this.asc_created ? this.asc_created : null,
        desc_created: this.desc_created ? this.desc_created : null,
        asc_status: this.asc_status ? this.asc_status : null,
        desc_status: this.desc_status ? this.desc_status : null,
        asc_delete: this.asc_delete ? this.asc_delete : null,
        desc_delete: this.desc_delete ? this.desc_delete : null,
      };
      this.loadindShow = true
      this[LIST_USERS](options)
        .then((res) => {
          this.users = res;
          this.totalPages = Array(Math.ceil(res.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>this.loadindShow=false)
    },
    showDeleteModel(item) {
      this.deletedresource = item;
      if (this.getUserRole(item) == MERCHANT.name) {
        window.$(`#${this.deactivateModelID}`).modal("toggle");
      } else {
        window.$(`#${this.deleteUserModelID}`).modal("toggle");
      }
    },
    async deleteUser() {
      window.$(`#${this.deleteUserModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[FULL_DELETE_USER](this.deletedresource.id)
          .then(() => {
            this.initUser(1);
            this.$alertify.notify(
              `${this.deletedresource.email} successfully removed.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    async deactivateUser() {
      window.$(`#${this.deactivateModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "deactivate",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[DEACTIVATE_USER](this.deletedresource.id)
          .then(() => {
            this.initUser(1);
            this.$alertify.notify(
              `${this.deletedresource.email} successfully deactivated.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    changeStatus(state) {
      this.status = state;
    },
    incrementpage() {
      this.page = this.page + 1;
      this.initUser();
    },
    decrementpage() {
      this.page = this.page - 1;
      this.initUser();
    },
    setpage(page) {
      this.page = page;
      this.initUser();
    },
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    sortTable(item) {
      if (item == "id") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_status = null),
          (this.desc_status = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (this.asc_id) {
          this.asc_id = null;
          this.desc_id = true;
        } else if (this.desc_id) {
          this.desc_id = null;
          this.asc_id = true;
        } else {
          this.asc_id = true;
          this.desc_id = null;
        }
      } else if (item == "email") {
        (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_status = null),
          (this.desc_status = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (!this.asc_email && !this.desc_email) {
          this.asc_email = true;
          this.desc_email = null;
        } else if (this.asc_email) {
          this.asc_email = null;
          this.desc_email = true;
        } else {
          this.asc_email = true;
          this.desc_email = null;
        }
      } else if (item == "created") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_status = null),
          (this.desc_status = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (!this.asc_created && !this.desc_created) {
          this.asc_created = true;
          this.desc_created = null;
        } else if (this.asc_created) {
          this.asc_created = null;
          this.desc_created = true;
        } else {
          this.asc_created = true;
          this.desc_created = null;
        }
      } else if (item == "status") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (!this.asc_status && !this.desc_status) {
          this.asc_status = true;
          this.desc_status = null;
        } else if (this.asc_status) {
          this.asc_status = null;
          this.desc_status = true;
        } else {
          this.asc_status = true;
          this.desc_status = null;
        }
      } else if (item == "pending_delete") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_status = null),
          (this.desc_status = null);
        if (!this.asc_delete && !this.desc_delete) {
          this.asc_delete = true;
          this.desc_delete = null;
        } else if (this.asc_delete) {
          this.asc_delete = null;
          this.desc_delete = true;
        } else {
          this.asc_delete = true;
          this.desc_delete = null;
        }
      } else {
        return;
      }
      this.initUser(1);
    },
  },
  mounted() {},
  created() {
    this.initUser(), this[LIST_ORGS]();
  },
  watch: {
    status() {
      this.page = 1;
      this.initUser();
    },
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initUser();
      }
    },
    organization() {
      this.page = 1;
      this.initUser();
    },
  },
};
</script>
<style scoped>
.data-heading-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.data-heading-wrp .data-heading {
  position: relative;
}

.data-heading-wrp .data-heading-btn button {
  background: #5e66fa;
  border-radius: 4px;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 11px 19px;
}
.search-box-wrp {
  position: relative;
  min-width: 300px;
  display: inline-block;
  margin-right: 20px;
}
.search-box-wrp input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #aaabad;
}
.search-box-wrp input::placeholder {
  color: #aaabad;
}
.tab-box-wrp {
  position: relative;
  display: inline-block;
}
.tab-box-wrp ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.tab-box-wrp ul li {
  background: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #3f3f40;
  list-style-type: none;
  border: 1px solid #e2e2e2;
  padding: 9px 20px;
  cursor: pointer;
}
.tab-box-wrp ul li:first-child {
  border-radius: 4px 0px 0px 4px;
}
.tab-box-wrp ul li:last-child {
  border-radius: 0px 4px 4px 0px;
}
.tab-box-wrp ul li.active {
  background: #5e66fa;
  color: #fff;
  border: 1px solid #5e66fa;
}
.table-wrp table tr:hover {
  background: #f6f6f7;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.sort-active {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}
#icon-date {
  background: url("../../../public/assets/images/calendar.png") no-repeat scroll
    left center / 15px auto;
  background-position: 95%;
}
.res-date-field {
  top: -16px;
}

select#adminfilter {
  border-radius: 3px;
  padding: 10px 122px 10px 8px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.contant-box-main{
    padding: 0;
    border: 1px solid #ECECED;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
    border-radius: 12px;
}
  .data-heading-wrp{
    display: block;
    padding: 20px;
}
  .search-box-wrp{
    position: relative;
    min-width: 190px;
    display: inline-block;
    margin-right: 0px;
    width: 190px;
}
.search-box-wrp input{
    background: #F5F7F9;
    border-radius: 10px;
    border: 0;
    color: #242220;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    padding: 9px 12px 9px 40px;
}
.search-box-wrp input:focus,
.search-box-wrp input:focus-visible{
  outline: none;
  box-shadow: none;
}
.search_icon{
    position: absolute;
    top: 5px;
    left: 12px;
}
.data-heading-btn{
    display: flex;
    justify-content: flex-end;
}
.data-heading-wrp .data-heading-btn button{
    background: #565DF2;
    border-radius: 5px;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    border: 0;
    color: #fff;
    font-weight: 400;
    padding: 8px 10px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "Outfit", sans-serif !important;
}
.table-wrp .table thead tr th{
  color: #4F4D55 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    vertical-align: middle;
    border-bottom: 1px solid #E9EBED !important;
    background: rgba(86, 93, 242, 0.0588235294);
    padding: 12px 20px;
}
.table-wrp .table thead tr th .table-head {
    color: #4F4D55 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    padding: 0;
}
.table-wrp .table thead tr th span{
  margin-left: 12px;
  cursor: pointer;
}
.table-wrp .table thead tr th span span{
  margin-left: 0px;
  font-size: 14px;
  margin-right: 2px;
}
.table-wrp .table tbody tr td{
  padding: 12px 20px;
    color: #4F4D55;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-color: #E9EBED;
}
.table-wrp .table .main-button--table td{
  padding: 0;
  border: 0;
  width: auto;
}
.table > :not(:first-child) {
    border-top: 0;
}
.table-wrp .table .eye-view {
    background: transparent;
    border: 0;
    border-radius: 0;
    width: auto;
    height: auto;
    margin: 0 !important;
    padding: 0;
}
table.main-button--table{
  margin: 0 auto;
    text-align: center;
    width: auto;
}
.table-wrp .table thead tr th:last-child {
    text-align: center;
}
.table-wrp table tr:hover {
    background: transparent;
}

nav.main-pagination {
    padding: 20px;
}
.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-wrp .table .icon-effect{
    background: transparent;
    border: 0;
    border-radius: 0;
    width: auto;
    height: auto;
    margin: 0 !important;
    padding: 0;
}
</style>
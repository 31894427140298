<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar 
      title="Sales" 
      :author="{home: 'Home'}"
    />
    <div class="main-wrp">
      <div class="sales-topbar">
        <div class="row">
          <div class="col-lg-8">
            <div class="dashboard-tabs-card">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" @click='changeTab("re-stock")'>
                  <a class="nav-link active" id="re-stock-tab" data-toggle="pill" href="#re-stock" role="tab"
                    aria-controls="re-stock" aria-selected="false">Re-stock</a>
                </li>
                <li class="nav-item" @click='changeTab("inactive-customers")'>
                  <a class="nav-link" id="inactive-customers-tab" data-toggle="pill" href="#inactive-customers"
                    role="tab" aria-controls="inactive-customers" aria-selected="false">Inactive customers</a>
                </li>
                <li class="nav-item" @click='changeTab("no-orders")'>
                  <a class="nav-link" id="no-orders-tab" data-toggle="pill" href="#no-orders" role="tab"
                    aria-controls="no-orders" aria-selected="false">No Orders</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="date-range-picker-box">
              <!-- Show "Last 90 Days" or the date range picker based on the active tab -->
              <div v-if="activeTab === 'no-orders'" class="static-display">
                Last 90 Days
              </div>
              <div v-else-if="activeTab !== 'inactive-customers'"  class="input-group input-daterange ml-auto">
                <input 
                  id="icon-date" 
                  type="text" 
                  ref="salesDate" 
                  class="form-control daterange" 
                  readonly 
                  name="daterange" 
                  :value="selectedDateRange" 
                />
                <img src="../../public/assets/images/calendar.svg" alt="calendar" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
            <Table 
              :active-tab="activeTab"
              :selected-date-range="selectedDateRange"
              :start-date="startDate"
              :end-date="endDate"
            />
          </div>
          <div class="tab-pane fade" id="re-stock" role="tabpanel" aria-labelledby="re-stock-tab">
            <Table 
              :active-tab="activeTab"
              :selected-date-range="selectedDateRange"
              :start-date="startDate"
              :end-date="endDate"
            />
          </div>
          <div class="tab-pane fade" id="inactive-customers" role="tabpanel" aria-labelledby="inactive-customers-tab">
            <Table 
              :active-tab="activeTab"
              :selected-date-range="selectedDateRange"
              :start-date="startDate"
              :end-date="endDate"
            />
          </div>
          <div class="tab-pane fade" id="no-orders" role="tabpanel" aria-labelledby="no-orders-tab">
            <Table 
              :active-tab="activeTab"
              :selected-date-range="selectedDateRange"
              :start-date="startDate"
              :end-date="endDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../Menu/MainMenu.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DATE_FORMAT } from "../Core/helpers/utils";
import TopBar from "../Menu/TopBar.vue";
import Table from "../Sales/components/table.vue"

export default {
  name: "Sales",
  components: {
    MainMenu,
    Loading,
    TopBar,
    Table
  },
  data() {
    return {
      activeTab: "re-stock",
      fullPage: true,
      isLoading: true,
      dateFilterParams: {},
      startDate: window.moment().subtract(10, "days").format(DATE_FORMAT),
      endDate: window.moment().format(DATE_FORMAT),
      selectedDateRange: window.moment().subtract(10, "days").format(DATE_FORMAT) + ' - ' + window.moment().format(DATE_FORMAT),
      predefindedSalesDates: {
        Yesterday: [window.moment().subtract(1, "days"), window.moment()],
        "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
        "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
        "This Month": [
          window.moment().startOf("month"),
          window.moment().endOf("month"),
        ],
        "This Year": [
          window.moment().startOf("year"),
          window.moment().endOf("year"),
        ],
      },
    };
  },
  methods: {
    // Get the current tab when click
    changeTab(tab) {
      this.activeTab = tab;
    },
    // for date change picker
    datechange(startDate, endDate) {
      this.selectedDateRange = `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`;
      this.startDate = startDate.format(DATE_FORMAT);
      this.endDate = endDate.format(DATE_FORMAT);
    },
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);

    const last10Days = [window.moment().subtract(9, "days"), window.moment()];
    
    this.startDate = last10Days[0].format(DATE_FORMAT);
    this.endDate = last10Days[1].format(DATE_FORMAT);
    this.selectedDateRange = `${this.startDate} - ${this.endDate}`;

    // Initialize the date picker
    this.datepicker = window.$(this.$refs.salesDate);
    let _this = this;
    this.datepicker.daterangepicker(
      {
        autoUpdateInput: false,
        ranges: _this.predefindedSalesDates,
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      this.datechange
    );
  },
};
</script>

<style scoped>
.sales-topbar {
  padding-bottom: 23px;
  position: relative;
}

.nav-pills {
  gap: 11px;
  background: transparent !important;
}

.nav-pills li a {
  color: #1A2B3A;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #1A2B3A1A;
  opacity: 0.8;
  border-radius: 5px !important;
  padding: 6px 10px !important;
}

.nav-pills li a.active {
  background: #565DF2;
  color: #fff;
  opacity: 1;
  border-color: #565DF2;
}

.date-range-picker-box {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
}

.static-display {
  font-size: 14px;
  font-weight: bold;
  color: #1A2B3A;
  padding: 8px 15px; /* Add padding similar to the date range input */
  border: 1px solid #ced4da; /* Match the border style of the input */
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
</style>
<template>
  <div>
    <div v-if="!isFileUploadMode" class="modal create-new-customer-modal fade" :id="modalId" tabindex="-1" role="dialog" :aria-labelledby="modalId" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" :id="modalId">{{ header_text }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" class="form-horizontal" action="#">
            <div class="modal-body">
              <div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.first_name }">
                      <label for="first_name">First Name</label>
                      <Field as="input" type="text" class="form-control" name="first_name" placeholder="Enter First Name"
                        v-model="formValues.first_name" />
                      <div class="form-control-feedback">{{ errors.first_name }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.last_name }">
                      <label for="last_name">Last Name</label>
                      <Field as="input" type="text" class="form-control" name="last_name" placeholder="Enter Last Name"
                        v-model="formValues.last_name" />
                      <div class="form-control-feedback">{{ errors.last_name }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.company_name }">
                      <label for="company_name">Company Name</label>
                      <Field as="input" type="text" class="form-control" name="company_name"
                        placeholder="Enter Company Name" v-model="formValues.company_name" />
                      <div class="form-control-feedback">{{ errors.company_name }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.email }">
                      <label for="email">Email</label>
                      <Field as="input" type="text" class="form-control" name="email" placeholder="Enter Email"
                        v-model="formValues.email" />
                      <div class="form-control-feedback">{{ errors.email }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.birth_date }">
                      <label for="date">Birth Date</label>
                      <input type="text" ref="date" placeholder="Enter Birth date" class="form-control" />
                      <Field as="input" type="hidden" class="form-control" name="birth_date"
                        v-model="formValues.birth_date" />
                      <div class="form-control-feedback">{{ errors.birth_date }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <Field as="input" type="hidden" class="form-control" name="birth_date"
                        v-model="formValues.birth_date" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.phone }">
                      <label for="name">Phone</label>
                      <Field as="input" type="text" class="form-control" name="phone" placeholder="Enter Phone"
                        v-model="formValues.phone" />
                      <div class="form-control-feedback">{{ errors.phone }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.address }">
                      <label for="email">Address </label>
                      <Field as="input" type="text" class="form-control" name="address" placeholder="Enter Address"
                        v-model="formValues.address" />
                      <div class="form-control-feedback">{{ errors.address }}</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group" :class="{ 'has-error': errors.city }">
                      <label for="email">City</label>
                      <Field as="input" type="text" class="form-control" name="city" placeholder="Enter City"
                        v-model="formValues.city" />
                      <div class="form-control-feedback">{{ errors.city }}</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group" :class="{ 'has-error': errors.state }">
                      <label for="email">State</label>
                      <Field as="input" type="text" class="form-control" name="state" placeholder="Enter State"
                        v-model="formValues.State" />
                      <div class="form-control-feedback">{{ errors.state }}</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group" :class="{ 'has-error': errors.country }">
                      <label for="email">Country</label>
                      <Field as="input" type="text" class="form-control" name="country" placeholder="Enter Country"
                        v-model="formValues.country" />
                      <div class="form-control-feedback">{{ errors.country }}</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group" :class="{ 'has-error': errors.postal_id }">
                      <label for="email">Postal Code</label>
                      <Field as="input" type="text" class="form-control" name="postal_id" placeholder="Enter Postal Code"
                        v-model="formValues.postal_id" />
                      <div class="form-control-feedback">{{ errors.postal_id }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{ 'has-error': errors.sex }">
                      <label for="name">Sex</label>
                      <Field v-model="formValues.sex" name="sex">
                        <Multiselect v-model="formValues.sex" :options="sexChoices" placeholder="Select Sex" />
                      </Field>
                      <div class="form-control-feedback">{{ errors.sex }}</div>
                    </div>
                  </div>

                  <!-- <div class="form-group" :class="{ 'has-error': errors.external_id }">
                                    <label for="name">External ID</label>
                                    <Field as="input" type="text" class="form-control" name="external_id" placeholder="Enter External ID" v-model="formValues.external_id"/>
                                    <div class="form-control-feedback">{{errors.external_id}}</div>
                                  </div> -->

                  <!-- <div class="form-group form-check" >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  v-model="formValues.allow_marketing"
                                />

                                
                                <label for="checkbox" class="form-check-label">Allows Marketing Campaigns</label>
                              </div> -->
                  <!-- <div class="col-lg-12">
                    <div class="model-checkbox justify-content-start">
                      <Field
                        v-model="formValues.allow_marketing"
                        name="allow_marketing"
                      >
                        <label class="checkbox-container"
                          >Allows Marketing Campaigns
                          <input
                            type="checkbox"
                            name="allow_marketing"
                            v-model="formValues.allow_marketing"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </Field>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.detail }">
                <Field as="input" type="hidden" class="form-control" name="detail" />
                <div class="form-control-feedback">{{ errors.detail }}</div>
              </div>
              <span as="button" class="d-flex justify-content-center file-upload-title" v-if="!isFileUploadMode"
              @click="showFileUploadDialog"><img src="@/../public/assets/images/import-icon.svg" alt="Logo" />Import From
              File</span>
            <!-- <span as="button" class="d-flex justify-content-center file-upload-title mb-2" v-if="isFileUploadMode"
              @click="isFileUploadMode = false"><i class="bi bi-file-text"></i>Add manually</span> -->
            </div>
            <div class="modal-footer">
              <button type="button" class="cancle-btn" data-bs-dismiss="modal" v-on:click="clearForm()">
                Cancel
              </button>
              <!-- <button type="submit" class="save-btn" v-if="isFileUploadMode" @click="HandleShippingFileUpload">
                <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Add File</span>
              </button> -->
              <button type="submit" class="save-btn">{{ btn_name }}</button>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div v-else>
      <CustomerFileUpload
        :modalID="fileUploadModalId"
        :fileUploadMode=isFileUploadMode
        v-bind:class="{ 'show': isFileUploadMode }"
        @update:fileUploadMode="isFileUploadMode = $event"
      ></CustomerFileUpload>
    </div>
  </div>
</template>

<script>
  import { Field, Form } from "vee-validate";
  import * as yup from "yup";
  import { PHONE_REGEX } from "@/Core/helpers/utils";
  import { mapGetters, mapActions } from "vuex";
  import Multiselect from "@vueform/multiselect";
  import { UPDATE_CUSTOMER, CREATE_CUSTOMER, CUSTOMER_FILE_UPLOAD } from "@/Core/store/action-types";
  import ServerErrorHandler from "@/Core/helpers/ServerErrorHandler";
  import { DATE_FORMAT } from "@/Core/helpers/utils";
  import { RESOURCE_NAME } from "../customer.vars";
  import '@vueform/multiselect/themes/default.css';
  import CustomerFileUpload from "./FileUploadModal.vue";

  export default {
    name: "CustomerModal",
    components: {
      Form,
      Field,
      Multiselect,
      CustomerFileUpload
    },
    props: {
      modalId: {
        required: true,
        type: String,
      },
      memberToEdit: {
        required: false,
        type: Object,
        default: function () {
          return undefined;
        },
      },
      modalType: {
        required: true,
        type: Number,
      },
      initcustomers: {
        required: false,
        type: Function,
      },
    },
    computed: {
      ...mapGetters("user", ["userProfile"]),
    },
    watch: {
      memberToEdit: function (obj) {
        this.originalResource = obj;
        this.formValues = this.initForm();
      },
    },
    methods: {
      ...mapActions("customer", [UPDATE_CUSTOMER, CREATE_CUSTOMER, CUSTOMER_FILE_UPLOAD]),

      onSubmit(values, actions) {
        if (this.modalType == 1) this.updateCustomer(values, actions);
        if (this.modalType == 2) this.createCustomer(values, actions);
      },

      showFileUploadDialog() {
        this.isFileUploadMode = true;
        window.$(`#${this.fileUploadModalId}`).modal("toggle");
      },

      oncustomersFileChange(e) {
        this.shippingChargesFile = e.target.files[0];
      },
      updateCustomer(obj, actions) {
        obj.id = this.memberToEdit.id;
        this[UPDATE_CUSTOMER](obj)
          .then(() => {
            window.$(`#${this.modalId}`).modal("hide");
            this.$alertify.notify(
              `${RESOURCE_NAME} successfully updated.`,
              "success",
              3
            );
            this.clearForm(actions);
          })
          .catch(function (error) {
            ServerErrorHandler(actions, error.response);
            throw new Error("");
          });
      },

      createCustomer(obj, actions) {
        this[CREATE_CUSTOMER](obj)
          .then(() => {
            window.$(`#${this.modalId}`).modal("hide");
            this.$alertify.notify(
              `${RESOURCE_NAME} successfully created.`,
              "success",
              3
            );
            this.clearForm(actions);
            if (this.initcustomers) {
              this.initcustomers();
            }
          })
          .catch(function (error) {
            ServerErrorHandler(actions, error.response);
            throw new Error("");
          });
      },

      clearForm(actions) {
        this.formValues = this.initForm();
        if (actions) actions.resetForm();
      },

      initForm() {
        if (this.originalResource) {
          this.setDatepicker(this.originalResource.birth_date);
          return Object.assign({}, this.originalResource);
        }
        return {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          sex: null,
          allow_marketing: true,
          // external_id: '',
          birth_date: null,
        };
      },

      onDateChange(start) {
        this.formValues.birth_date = start.format(DATE_FORMAT);
        this.setDatepicker(this.formValues.birth_date);
      },

      setDatepicker(date) {
        this.datepicker.val(date);
      },
    },

    mounted() {
      this.datepicker = window.$(this.$refs.date);
      this.datepicker.daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
        },
        this.onDateChange
      );
    },

    beforeCreate() {
      if (this.modalType == 1) {
        // Edit mode
        this.btn_name = "Edit";
        this.header_text = `Edit ${RESOURCE_NAME}`;
      } else if (this.modalType == 2) {
        // Create mode
        this.btn_name = "Create";
        this.header_text = `Create new ${RESOURCE_NAME}`;
      }
    },

    data() {
      var sexChoices = ["Female", "Male", "Corporate"];
      return {
        resourceName: RESOURCE_NAME,
        sexChoices: sexChoices,
        customersFile: null,
        customersFileErrMSg: "",
        isFileUploadMode: false,
        isLoading: false,
        fileUploadModalId: "fileUploadDialog",
        formValues: this.initForm(),
        schema: yup.object().shape({
          first_name: yup.string().nullable().notRequired(),
          last_name: yup.string().nullable().notRequired(),
          // .min(6, 'Name must be at least 6 characters'),
          email: yup.string().email("Email is invalid").notRequired(),
          phone: yup.lazy(() => {
            if (this.formValues.phone) {
              return yup
                .string()
                .matches(PHONE_REGEX, "Phone number is not valid");
            }
            return yup.string().notRequired();
          }),
          address: yup.string().nullable(),
          country: yup.string().nullable(),
          city: yup.string().nullable(),
          postal_id: yup.lazy(() => {
            if (this.formValues.postal_id) {
              return yup
                .string()
                .length(5)
                .matches(/^[0-9]{5}/, "Postal code is not valid");
            }
            return yup.string().notRequired();
          }),

          // .matches(PHONE_REGEX, 'Phone number is not valid'),
          // external_id: yup.string()
          //   .min(6, 'External ID must be at least 6 characters')
          //   .required(`Please enter ${RESOURCE_NAME.toLowerCase()} External ID`),
          birth_date: yup
            .string()
            // .matches(DATE_REGEX, 'Please select valid date')
            .nullable(),
          sex: yup.string().nullable().notRequired(),
          allow_marketing: yup.boolean(),
          detail: yup
            .string() // use it for backend errors
            .nullable()
            .notRequired(),
        }),
      };
    },
  };
</script>
<style scoped>
  .add-cutomers-file-popup .info-line{
    display: flex;
    align-items: flex-start;
    background: #12B76A14;
    padding: 10px;
    border-radius: 8px;
    border: 0;
    margin-bottom: 10px;
  }
  .add-cutomers-file-popup .info-line img{
    margin-right: 5px;
  }
  .add-cutomers-file-popup .info-line p{
    color: #0000008F;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
  }
  .add-cutomers-file-popup .info-line p a{
    color: #3EB8F0;
    font-weight: 500;
  }
  .file-drop-box {
    background: #F8F8FF;
    border: 1px dashed #384EB74D;
    border-radius: 14px;
    height: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.file-drop-upload-info h6 {
    color: #0F0F0F;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 20px 0 5px 0;
}

.file-drop-upload-info h6 label {
    margin: 0;
    color: #565DF2;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-decoration: underline;
}

.file-drop-upload-info p {
    margin: 0;
    color: #676767;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
.file-drop-upload-info span{
  display: block;
  margin-top: 5px;
}
</style>
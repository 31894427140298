<template>
<div v-if="fileUploadMode" class="modal fade" :id="modalID" tabindex="-1" role="dialog" :aria-labelledby="modalID" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" :id="modalID">Add Cutomers File</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="clearForm">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <Form v-slot="{ errors }" class="form-horizontal" action="#">
                <div class="modal-body">
                    <div class="add-cutomers-file-popup">
                        <div class="info-line alert alert-success" role="alert">
                            <img src="@/../public/assets/images/sms.svg" alt="Logo" />
                            <p> You can send customers by upload them in MS Excel format
                                (.xlxs). This will update the existed customers, and add new customers.
                                Template for the Excel structure:
                                <a href="/assets/files/customer_final.xlsx" download>
                                    <ul>
                                        Click Here
                                    </ul>
                                </a>
                            </p>
                        </div>
                        <div class="file-drop-box">
                            <div class="file-drop-upload">
                                <input type="file" id="file-upload-customers" class="form-control d-none"
                                    @change="oncustomersFileChange"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                <div class="file-drop-upload-info">
                                    <img src="@/../public/assets/images/upload-icon.svg" alt="Logo" />
                                    <h6>Drag & drop files or
                                        <label for="file-upload-customers" role="button">
                                            Browse</label>
                                    </h6>
                                    <p>Supported formats: Excel</p>
                                    <span>{{ customersFile?.name }}</span>
                                </div>
                            </div>
                            <div class="form-control-feedback">
                                {{ customersFileErrMSg }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': errors.detail }">
                        <Field as="input" type="hidden" class="form-control" name="detail" />
                        <div class="form-control-feedback">{{ errors.detail }}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="cancle-btn" data-bs-dismiss="modal" v-on:click="clearForm()">
                        Cancel
                    </button>
                    <button type="submit" class="save-btn" v-if="fileUploadMode" @click="HandleShippingFileUpload">
                        <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;"
                            v-if="isLoading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <span v-else>Add File</span>
                    </button>
                    <button type="submit" v-else class="save-btn">{{ btn_name }}</button>
                </div>
            </Form>
        </div>
    </div>
</div>
</template>

<script>
    import { Field, Form } from "vee-validate";
    import { mapGetters, mapActions } from "vuex";
    import { CUSTOMER_FILE_UPLOAD } from "@/Core/store/action-types";
    import { RESOURCE_NAME } from "../customer.vars";

    export default {
        name: "CustomerFileUpload",
        components: {
            Form,
            Field,
        },
        props: {
            modalID: {
                required: true,
                type: String,
            },
            fileUploadMode: {
                required: true,
                type: Boolean
            }
        },
        computed: {
            ...mapGetters("user", ["userProfile"]),
        },
        methods: {
            ...mapActions("customer", [CUSTOMER_FILE_UPLOAD]),

            oncustomersFileChange(e) {
                this.shippingChargesFile = e.target.files[0];
            },
            HandleCustomerFileUpload() {
                if (this.customersFile) {
                    this.isLoading = true
                    this[CUSTOMER_FILE_UPLOAD](this.customersFile)
                        .then(() => {
                            this.$alertify.notify(
                                "Customers Uploaded successfully",
                                "success",
                                3
                            );
                            window.$(`#${this.modalID}`).modal("hide");
                        })
                        .catch(() => {
                            this.$alertify.notify(
                                "There is some error, please check your file",
                                "error",
                                3
                            );
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                } else {
                    this.customersFileErrMSg = "Please select file";
                }
            },

            clearForm() {
                window.$(`#${this.modalID}`).modal("hide");
                window.$('body').removeClass('modal-open');
                window.$('.modal-backdrop').remove();
                window.$('body').css({
                    'padding-right': '',
                    'overflow': '',
                });
                this.$emit('update:fileUploadMode', false);
            },
        },
        data() {
            return {
                resourceName: RESOURCE_NAME,
                customersFile: null,
                customersFileErrMSg: "",
                isLoading: false,
            };
        },
    };
</script>
<style scoped>
    .add-cutomers-file-popup .info-line {
        display: flex;
        align-items: flex-start;
        background: #12B76A14;
        padding: 10px;
        border-radius: 8px;
        border: 0;
        margin-bottom: 10px;
    }

    .add-cutomers-file-popup .info-line img {
        margin-right: 5px;
    }

    .add-cutomers-file-popup .info-line p {
        color: #0000008F;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin: 0;
    }

    .add-cutomers-file-popup .info-line p a {
        color: #3EB8F0;
        font-weight: 500;
    }

    .file-drop-box {
        background: #F8F8FF;
        border: 1px dashed #384EB74D;
        border-radius: 14px;
        height: 202px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .file-drop-upload-info h6 {
        color: #0F0F0F;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 20px 0 5px 0;
    }

    .file-drop-upload-info h6 label {
        margin: 0;
        color: #565DF2;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-decoration: underline;
    }

    .file-drop-upload-info p {
        margin: 0;
        color: #676767;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .file-drop-upload-info span {
        display: block;
        margin-top: 5px;
    }
    #fileUploadDialog.show{display: block;}
</style>
<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar title="Inventory" :author="{ home: 'Home' }" />
    <div class="main-wrp">
      <div class="inventory-page">
        <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="overview-tab"
              data-toggle="pill"
              href="#overview"
              role="tab"
              aria-controls="overview"
              aria-selected="true"
              >Overview</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="warehouses-tab"
              data-toggle="pill"
              href="#warehouses"
              role="tab"
              aria-controls="warehouses"
              aria-selected="false"
              >Warehouses</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="overview"
            role="tabpanel"
            aria-labelledby="overview-tab"
          >
            <div class="row">
              <div class="col-lg-6 mb-3">
                <div class="inventory-box">
                  <div class="contant-box-main customer-list-box bg-transparent p-0">
                    <div class="h-100">
                      <div class="contant-header">
                        <h6>Articles Low Stock</h6>
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="national-tab"
                              data-toggle="pill"
                              href="#national"
                              role="tab"
                              aria-controls="national"
                              aria-selected="true"
                              @click="setLowArticlesTab('national')"
                              >National Warehouse</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="central-warehouses-tab"
                              data-toggle="pill"
                              href="#central-warehouses"
                              role="tab"
                              aria-controls="central-warehouses"
                              aria-selected="false"
                              @click="setLowArticlesTab('central')"
                              >Central Warehouse</a
                            >
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="national"
                          role="tabpanel"
                          aria-labelledby="national-tab"
                        >
                          <!-- <ArticlesLowStockNationalWarehouse /> -->
                          <ArticlesLowStock :lowArticlesTab="lowArticlesTab" />
                        </div>
                        <div
                          class="tab-pane fade"
                          id="central-warehouses"
                          role="tabpanel"
                          aria-labelledby="central-warehouses-tab"
                        >
                          <!-- <ArticlesLowStockCentralWarehouse /> -->
                          <ArticlesLowStock :lowArticlesTab="lowArticlesTab" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="inventory-box">
                  <IntransitArticlesNationalWarehouse />
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="inventory-box">
                  <div class="contant-box-main customer-list-box bg-transparent p-0">
                    <div class="h-100">
                      <div class="contant-header">
                        <h6>Inventory Turnover (National Warehouse)</h6>
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              :class="{ active: inventoryTab === 'top' }"
                              @click.prevent="switchInventoryTab('top')"
                              role="button"
                            >
                              Top
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              :class="{ active: inventoryTab === 'lowest' }"
                              @click.prevent="switchInventoryTab('lowest')"
                              role="button"
                            >
                              Lowest
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="filter-up">
                        <select name="" id="">
                          <option value="Up">UP</option>
                          <option value="Down">Down</option>
                        </select>
                        <select name="" id="">
                          <option value="Month">Month</option>
                          <option value="Week">Week</option>
                        </select>
                      </div>
                      <InventoryTurnOver :inventory-tab="inventoryTab" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="inventory-box">
                  <AverageInventoryTurnOver />
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="warehouses"
            role="tabpanel"
            aria-labelledby="warehouses-tab"
          >
            <div class="inventory-box warehouses-tab">
              <div class="contant-header p-0">
                <h6>List view of warehouses</h6>
                <div class="warehouses-filter-tab">
                  <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="all-tab"
                        data-toggle="pill"
                        href="#all"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                        @click="setActiveTab('all')"
                        >All</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="list-national-tab"
                        data-toggle="pill"
                        href="#list-national"
                        role="tab"
                        aria-controls="list-national"
                        aria-selected="false"
                        @click="setActiveTab('national')"
                        >National</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="central-tab"
                        data-toggle="pill"
                        href="#central"
                        role="tab"
                        aria-controls="central"
                        aria-selected="false"
                        @click="setActiveTab('central')"
                        >Central</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab-content" id="pills-tabContent">
                <div class="list-view-twarehouses-table">
                  <Warehouses :activeTab="activeTab" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="dashboard-bottom-section dashboard-top-section col-12">
      <div class="dts-row-bottom">
        <div class="dts-box-outer">
          <ArticlesLowStockNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <IntransitArticlesNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <ArticlesLowStockCentralWarehouse/>
        </div>
      </div>
    </div>
    <div class="dashboard-bottom-section dashboard-top-section">
      <div class="dts-row-bottom">
        <div class="dts-box-outer">
          <TopInventoryTurnOverNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <LowestInventoryTurnOverNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <AverageInventoryTurnOver />
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ArticlesLowStock from "./components/ArticlesLowStock.vue";
import {
  LIST_ARTICLES,
  TRENDING_ARTICLES,
  LIST_AUDIENCE,
  GET_METRICS,
} from "@/Core/store/action-types";
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";

import InventoryTurnOver from "./components/InventoryTurnOver.vue";
// import ArticlesLowStockNationalWarehouse from "./components/ArticlesLowStockNationalWarehouse.vue";
// import ArticlesLowStockCentralWarehouse from "./components/ArticlesLowStockCentralWarehouse.vue";

import IntransitArticlesNationalWarehouse from "./components/IntransitArticlesNationalWarehouse.vue";

// import TopInventoryTurnOverNationalWarehouse from "./components/TopInventoryTurnOverNationalWarehouse.vue";
// import LowestInventoryTurnOverNationalWarehouse from "./components/LowestInventoryTurnOverNationalWarehouse.vue";

import AverageInventoryTurnOver from "./components/AverageInventoryTurnOver.vue";
import Warehouses from "../User/components/Warehouses.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  components: {
    TopBar,
    ArticlesLowStock,
    InventoryTurnOver,
    IntransitArticlesNationalWarehouse,
    // ArticlesLowStockNationalWarehouse,
    // ArticlesLowStockCentralWarehouse,
    // TopInventoryTurnOverNationalWarehouse,
    // LowestInventoryTurnOverNationalWarehouse,
    AverageInventoryTurnOver,
    MainMenu,
    Loading,
    Warehouses,
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
      generalMetrics: {
        totalOrders: 0,
        totalMembers: 0,
        totalProducts: 0,
        totalArticles: 0,
        get_avg_order_value: {},
        get_avg_clv_value: {},
        get_median_clv_value: {},
      },
      activeTab: "all",
      lowArticlesTab: "national",
      inventoryTab: "top",
      dateFilterParams: {},
      articles: {},
      audiences: {},
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
  },
  methods: {
    ...mapActions("article", [LIST_ARTICLES, TRENDING_ARTICLES]),
    ...mapActions("metrics", [GET_METRICS]),
    ...mapActions("audience", [LIST_AUDIENCE]),
    // onDateChange(start, end) {
    //   this.dateFilterParams = {
    //     date_after: start.format(DATE_FORMAT),
    //     date_before: end.format(DATE_FORMAT),
    //   };
    //   this.initMetrics();
    //   // this.getArticles();
    //   this.getAudiences();
    // },
    async initMetrics() {
      // let params = this.dateFilterParams;
      let params = {
        currency: this.default_currency,
      };
      // params.is_active = 1;
      await this[GET_METRICS](params).then((res) => {
        this.generalMetrics = res;
        // this.convertCurrency();
      });
    },
    async getArticles() {
      // let params = this.dateFilterParams;
      let params = {};
      params.order = "-sold_items";
      params.limit = 3;
      params.direction = "up";
      await this[LIST_ARTICLES]({ params: params, persist: true }).then((res) => {
        this.articles = res.results;
      });
    },
    async getAudiences() {
      // let params = this.dateFilterParams;
      let params = {};
      params.limit = 3;
      await this[LIST_AUDIENCE]({ params: params, persist: true }).then((res) => {
        this.audiences = res;
      });
    },
    switchInventoryTab(tab) {
      this.inventoryTab = tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    setLowArticlesTab(lowArticlesTab) {
      this.lowArticlesTab = lowArticlesTab;
    },
    // async convertCurrency() {
    //   this.convertedValues.avg_clv_value = await currencyConverter(
    //     this.generalMetrics.get_avg_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_clv_value?.avg_clv_value
    //   );
    //   this.convertedValues.median_clv_value = await currencyConverter(
    //     this.generalMetrics.get_median_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_median_clv_value?.median_clv_value
    //   );
    //   this.convertedValues.avg_order_value = await currencyConverter(
    //     this.generalMetrics.get_avg_order_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_order_value?.avg_receipt_value
    //   );
    // },
    last_month_last_date() {
      var d = new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started.
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    one_month_ago_date() {
      var d = new Date(new Date().setDate(new Date().getDate() - 30));
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  },
  created() {
    // this.initDateRange = [
    //   this.predefindedDates["Last 7 Days"][0],
    //   this.predefindedDates["Last 7 Days"][1],
    // ];
    // this.onDateChange(this.initDateRange[0], this.initDateRange[1]);
    // this.$root.$refs.Dashboard = this;
    // this.getArticles();
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    this.initMetrics();
    // this.initValuableCustomer();
    // this.getAudiences();
  },
};
</script>
<style scoped></style>

<template>
  <!-- leftbar-tab-menu -->
  <div
    id="sidebar"
    class=""
    v-if="userProfile.email && !this.currentUrl.includes('/changepassword')"
  >
    <div class="logo">
      <img src="@/../public/assets/images/crescofy-browser.png" alt="Logo" />
      <span>Crescofy</span>
    </div>
    <div
      class="d-flex justify-content-between flex-column navigations"
      v-if="!hasAdminPageAccess(userProfile)"
    >
      <ul class="list-unstyled">
        <li class="icon-hov">
          <router-link
            to="/dashboard"
            :class="currentUrl === '/dashboard' ? 'active' : ''"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.6">
                <path d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z" stroke="#1A2B3A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z" stroke="#1A2B3A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z" stroke="#1A2B3A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C15.7909 14 14 15.7909 14 18C14 20.2091 15.7909 22 18 22Z" stroke="#1A2B3A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>
            </span>
            <p>Dashboard</p>
          </router-link>
        </li>
        <!-- <li class="icon-hov">
          <router-link
            to="/analytics"
            :class="this.currentUrl == '/analytics' ? 'active' : ''"
          >
            <span><AnalyticsIcon /></span>
            <p>Analytics</p>
          </router-link>
        </li>
        <li class="icon-hov">
          <router-link
            to="/Cart"
            :class="this.currentUrl.includes('/Cart') ? 'active' : ''"
          >
            <span><CartIcon /></span>
            <p>Cart</p>
          </router-link>
        </li> -->
        <li class="icon-hov svg-active customers">
          <router-link
            to="/Customers"
            :class="this.currentUrl.includes('/Customers') ? 'active' : ''"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.6">
                <path d="M18.14 21.62C17.26 21.88 16.22 22 15 22H9C7.78 22 6.74 21.88 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.58 10.58C15.58 12.56 13.98 14.17 12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>
            </span>
            <p>Customers</p>
          </router-link>
        </li>

        
        <li class="icon-hov svg-active">
          <router-link
            to="/Inventory"
            :class="this.currentUrl.includes('/Inventory') ? 'active' : ''"
          >
            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g opacity="0.6">
              <path d="M2 9V7C2 4 4 2 7 2H17C20 2 22 4 22 7V9" stroke="#1A2B3A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 15V17C2 20 4 22 7 22H17C20 22 22 20 22 17V15" stroke="#1A2B3A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.7 9.26001L12 12.33L17.26 9.28003" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 17.7701V12.3201" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.76 6.29006L7.56 8.07009C6.84 8.47009 6.24001 9.48008 6.24001 10.3101V13.7001C6.24001 14.5301 6.83 15.5401 7.56 15.9401L10.76 17.7201C11.44 18.1001 12.56 18.1001 13.25 17.7201L16.45 15.9401C17.17 15.5401 17.77 14.5301 17.77 13.7001V10.3101C17.77 9.48008 17.18 8.47009 16.45 8.07009L13.25 6.29006C12.56 5.90006 11.44 5.90006 10.76 6.29006Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              </svg></span>
            <p>Inventory</p>
          </router-link>
        </li>
        
        <li class="icon-hov svg-active-stroke">
          <router-link
            to="/Sales"
            :class="this.currentUrl.includes('/Sales') ? 'active' : ''"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.6">
                <path d="M21.6702 6.95003C21.0302 4.78003 19.2202 2.97003 17.0502 2.33003C15.4002 1.85003 14.2602 1.89003 13.4702 2.48003C12.5202 3.19003 12.4102 4.47003 12.4102 5.38003V7.87003C12.4102 10.33 13.5302 11.58 15.7302 11.58H18.6002C19.5002 11.58 20.7902 11.47 21.5002 10.52C22.1102 9.74003 22.1602 8.60003 21.6702 6.95003Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                <path d="M18.9099 13.36C18.6499 13.06 18.2699 12.89 17.8799 12.89H14.2999C12.5399 12.89 11.1099 11.46 11.1099 9.70003V6.12003C11.1099 5.73003 10.9399 5.35003 10.6399 5.09003C10.3499 4.83003 9.9499 4.71003 9.5699 4.76003C7.2199 5.06003 5.0599 6.35003 3.6499 8.29003C2.2299 10.24 1.7099 12.62 2.1599 15C2.8099 18.44 5.5599 21.19 9.0099 21.84C9.5599 21.95 10.1099 22 10.6599 22C12.4699 22 14.2199 21.44 15.7099 20.35C17.6499 18.94 18.9399 16.78 19.2399 14.43C19.2899 14.04 19.1699 13.65 18.9099 13.36Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                  </g>
              </svg>
            </span>
            <p>Sales</p>
          </router-link>
        </li>
        <!-- <li class="icon-hov">
          <router-link
            to="/Audience"
            :class="this.currentUrl.includes('/Audience') ? 'active' : ''"
          >
            <span><AudienceIcon /></span>
            <p>Audience</p>
          </router-link>
        </li> -->
        <!-- <li class="icon-hov">
          <router-link
            to="/Email"
            :class="this.currentUrl.includes('/Email') ? 'active' : ''"
          >
          <span><EmailIcon /></span>
            <p>Email</p>
          </router-link>
        </li> -->
      </ul>
      <ul class="list-unstyled bottom-navigation">
        <li class="icon-hov svg-active settings" v-if="getUserRole(userProfile) != 'Worker'">
          <router-link
            :to="{ name: 'Settings' }"
            :active-class="currentUrl === '/Settings' ? 'active' : ''"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.6">
                <path d="M3 9.10998V14.88C3 17 3 17 5 18.35L10.5 21.53C11.33 22.01 12.68 22.01 13.5 21.53L19 18.35C21 17 21 17 21 14.89V9.10998C21 6.99998 21 6.99999 19 5.64999L13.5 2.46999C12.68 1.98999 11.33 1.98999 10.5 2.46999L5 5.64999C3 6.99999 3 6.99998 3 9.10998Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#1A2B3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>
            </span>
            <p>Settings</p>
          </router-link>
        </li>
        <li class="profile-menu">
          <router-link to="/">
            <div class="user">
              <div class="image profile-image">
                <img
                  :src="
                    generateAvatar(
                      userProfile?.email?.slice(0, 1)?.toUpperCase(),
                      '#5e66fa',
                      '#F0F1F2'
                    )
                  "
                  alt="User"
                  v-if="!userProfile.profile_image_url"
                />
                <img :src="userProfile?.profile_image_url" alt="User" v-else />
              </div>
              <span class="name">{{ userProfile.email }}</span>
              <span>{{ getUserRole(userProfile) }}</span>
            </div>
          </router-link>
          <button class="logout-btn">
            <LogOutIcon />
          </button>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-between flex-column navigations" v-else>
      <ul class="list-unstyled">
        <li class="icon-hov">
          <router-link
            to="/Companies"
            :class="this.currentUrl.includes('/Companies') ? 'active' : ''"
          >
            <span><OrganizationIcon /></span>
            <p>Companies</p>
          </router-link>
        </li>
        <li class="icon-hov">
          <router-link
            to="/Users"
            :class="this.currentUrl.includes('/Users') ? 'active' : ''"
          >
            <span><MembersIcon /></span>
            <p>Users</p>
          </router-link>
        </li>
        <li class="icon-hov" v-if="getUserRole(userProfile) == 'Super Admin'">
          <router-link
            to="/AllAdmins"
            :class="this.currentUrl.includes('/AllAdmins') ? 'active' : ''"
          >
            <span><SettingIcon /></span>
            <p>Admins</p>
          </router-link>
        </li>
      </ul>
      <ul class="list-unstyled bottom-navigation">
        <li class="profile-menu">
          <router-link to="/">
            <div class="user">
              <div class="image profile-image">
                <img
                  :src="
                    generateAvatar(
                      userProfile?.email.slice(0, 1).toUpperCase(),
                      '#5e66fa',
                      '#F0F1F2'
                    )
                  "
                  alt="User"
                  v-if="!userProfile?.profile_image_url"
                />
                <img :src="userProfile?.profile_image_url" alt="User" v-else />
              </div>
              <span class="name">{{ userProfile.email }}</span>
              <span>{{ getUserRole(userProfile) }}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div @click="toggleBodyClass" class="toggle-icon"></div>
  </div>
  
  <!-- end leftbar-tab-menu-->
</template>

<script>
import { mapGetters } from "vuex";
// import DashboardIcon from "../icons/dashboard.vue";
// import AnalyticsIcon from "../icons/analytics.vue";
// import CartIcon from "../icons/cart.vue";
import MembersIcon from "../icons/members.vue";
import LogOutIcon from "../icons/logout.vue";
// import AudienceIcon from "../icons/audience.vue";
import SettingIcon from "../icons/setting.vue";
import OrganizationIcon from "../icons/organization.vue";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
// import EmailIcon from "../icons/email.vue";

export default {
  name: "MainMenu",
  mixins: [UserRoleMixin],
  components: {
    // DashboardIcon,
    // AnalyticsIcon,
    // CartIcon,
    MembersIcon,
    // AudienceIcon,
    SettingIcon,
    OrganizationIcon,
    LogOutIcon,
    // EmailIcon
  },
  computed: {
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  // created() {
    // console.log(
    //   this.currentUrl,
    //   "/Cart/Recommendations",
    //   this.currentUrl, window.location.pathname ,this.currentUrl.includes('/Cart')
    // );
  // },
  created() {
    this.currentUrl = this.$route.path;
  },
  methods: {
    generateAvatar(text, foregroundColor, backgroundColor) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 200;
      canvas.height = 200;

      // Draw background
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw text
      context.font = "bold 100px Assistant";
      context.fillStyle = foregroundColor;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(text, canvas.width / 2, canvas.height / 2);

      return canvas.toDataURL("image/png");
    },
    toggleBodyClass() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        document.body.classList.add('active-body');
      } else {
        document.body.classList.remove('active-body');
      }
    },
  },
  data: function () {
    return {
      currentUrl: "",
      isActive: false,
    };
  },
};
</script>

<style>
.nav-left-bar {
  padding-top: 25px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .navigations {
    overflow-x: auto;
    overflow-y: auto;
  }
  .list-unstyled li {
    padding: 0 0 !important;
  }
  .list-unstyled li a {
    color: #303e67;
    padding: 10px 0;
  }
  .list-unstyled li .active {
    color: #5f66fa !important;
    background: #f6f6f7 !important;
    width: 100%;
  }
  .list-unstyled li svg:hover {
    stroke: #5f66fa !important;
    fill: #5f66fa !important;
  }
  .list-unstyled li .active svg {
    stroke: #5f66fa !important;
    fill: #5f66fa !important;
  }
  .profile-image {
    border-radius: 100%;
  }
</style>

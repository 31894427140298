<template>
  <div class="contant-details h-100">
    <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
      <div class="loader-main" v-if="loadindShow">
        <div
          class="spinner-border"
          role="status"
          style="width: 36px; height: 36px; color: #5e66fa"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <table class="table" id="my-table">
        <thead>
          <tr>
            <th>Article ID</th>
            <th>Article Name</th>
            <th>Warehouse Code</th>
            <th>Stock</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="article in displayedArticles" :key="article.article_id">
            <td>{{ article.sku }}</td>
            <td>{{ article.name }}</td>
            <td>{{ article.warehouse_code }}</td>
            <td>{{ article.stock }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="contant-footer d-flex justify-content-end" v-if="articles.length > 10">
    <router-link
      :to="{
        name: `InventoryViewAll`,
        params: { api: 'ArticlesLowStock' },
      }"
      ><a role="button" data-toggle="modal" class="view-more-btn">
        View All
        <img src="@/../public/assets/images/right-sky.png" alt=""
      /></a>
    </router-link>
  </div>

  <div
    class="modal fade"
    id="articlesLowStockCentralWareHouseModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="valubaleCustomerListModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="restockNowArticleListModelTitle">
            Articles Low Stock (Central Warehouse)
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="contant-details">
            <form class="profile-form">
              <div class="contant-header justify-content-end">
                <button type="button" class="save-btn">Export List</button>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="model_table m-0">
                <div class="table-wrp overflow-auto position-relative">
                  <div class="loader-main" v-if="loadindShow">
                    <div
                      class="spinner-border"
                      role="status"
                      style="width: 36px; height: 36px; color: #5e66fa"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <table class="table my-table-wrp table-sm" id="my-table">
                    <thead>
                      <tr>
                        <th>Article ID</th>
                        <th>Article Name</th>
                        <th>Warehouse Code</th>
                        <th>Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="article in allArticles
                          ? allArticles.slice(10 * (page_model - 1), page_model * 10)
                          : []"
                        :key="article.article_id"
                        :class="
                          checkedFilter(article.article_id) ? 'active-row selected' : ''
                        "
                      >
                        <td>{{ article.sku }}</td>
                        <td>{{ article.name }}</td>
                        <td>{{ article.warehouse_code }}</td>
                        <td>{{ article.stock }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Pagination
                  :page="page_model"
                  :totalPages="
                    allArticles
                      ? Array(Math.ceil(allArticles.length / 10))
                          .fill(0)
                          .map((e, i) => i + 1)
                      : []
                  "
                  :name="'Articles'"
                  :count="allArticles ? allArticles.length : 0"
                  :incrementpage="incrementpage_model"
                  :decrementpage="decrementpage_model"
                  :setpage="setpage_model"
                  :perpage="10"
                />
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/Core/helpers/utils";
import Pagination from "../../Core/Pagination.vue";

export default {
  name: "ArticlesLowStock",
  components: {
    Pagination,
  },
  props: {
    lowArticlesTab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      articles: [],
      allArticles: [],
      centralLowArticles: [],
      nationalLowArticles: [],
      loadindShow: false,
      page_model: 1,
      choosen_articles: [],
      isAllSelected: false,
    };
  },
  computed: {
    displayedArticles() {
      return this.articles.slice(0, 10); // Return the first 10 articles
    },
  },
  watch: {
    lowArticlesTab: "filterLowArticles",
  },

  methods: {
    filterLowArticles() {
      if (this.lowArticlesTab === "national") {
        this.articles = this.nationalLowArticles;
      } else if (this.lowArticlesTab === "central") {
        this.articles = this.centralLowArticles;
      }
    },
    async fetchArticlesLowStock() {
      const token = this.$store.getters["user/userProfile"].access;
      this.loadindShow = true;
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/warehouse/low-stock-articles/?threshold=20`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.centralLowArticles = response.data.filter(
          (article) => article.warehouse_type === "central/production"
        );
        this.nationalLowArticles = response.data.filter(
          (article) => article.warehouse_type === "national/distribution"
        );
        // Set initial articles to national
        this.articles = this.nationalLowArticles;
        this.allArticles = this.articles;
      } catch (error) {
        console.error(
          "Error fetching articles:",
          error.response ? error.response.data : error.message
        );
      } finally {
        this.loadindShow = false;
      }
    },
    onTimeChange() {
      this.fetchArticlesLowStock();
    },
    viewAllArticles() {
      this.$nextTick(() => {
        window.$("#articlesLowStockCentralWareHouseModel").modal("show"); // Show the modal
      });
    },
    closeModal() {
      window.$("#articlesLowStockCentralWareHouseModel").modal("hide"); // Hide the modal
    },
    AddRemoveArticles(id) {
      this.isAllSelected = false;
      setTimeout(() => {
        if (this.choosen_articles.includes(id)) {
          this.choosen_articles = this.choosen_articles.filter(
            (article_id) => article_id != id
          );
        } else {
          this.choosen_articles.push(id);
        }
      }, 300);
    },
    checkedFilter(id) {
      if (this.choosen_articles.includes(id)) return true;
      else return false;
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    AddRemoveAllArticles() {
      if (this.isAllSelected) {
        this.choosen_articles = [];
        this.isAllSelected = false;
      } else {
        this.choosen_articles = this.getAllArticleIDs();
        this.isAllSelected = true;
      }
    },

    getAllArticleIDs() {
      return this.allArticles.map((a) => a.article_id);
    },

    resetModalFields() {
      this.choosen_articles = [];
      this.isAllSelected = false;
      this.page_model = 1;
      this.loadindShow = false;
    },
  },
  mounted() {
    window
      .$("#articlesLowStockCentralWareHouseModel")
      .on("hide.bs.modal", this.resetModalFields);
    this.fetchArticlesLowStock();
    // Set initial tab data
    this.articles = this.nationalLowArticles;
  },
};
</script>

<style scoped></style>

<template>
  <div class="dashboard-block-section">
    <div class="row">
      <div class="col-lg-4">
        <div class="dashboard-block-box">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <OrderUploadIcon class="mr-3" />
              <p class="m-0">Upload receipts/orders from file</p>
            </div>
          </div>
          <div class="edit-box-selection file-import-btn">
            <button data-toggle="modal" data-target="#receiptFileModel"><img src="@/../public/assets/images/add.svg" alt="Logo" /> Add Files</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="dashboard-block-box">
          
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <CustomerUploadIcon class="mr-3" />
              <div>
                <p class="m-0">Upload customers from file</p>
                <div class="upload-info"
                  v-if="this?.org?.last_uploaded_customer_count != null && this?.org?.last_uploaded_customer_timestamp">
                  <span>Last updated : <em>{{ getdate(this?.org?.last_uploaded_customer_timestamp) }}
                      <!-- at {{getTime(this?.org?.last_uploaded_customer_timestamp)}} -->
                    </em></span>
                </div>
              </div>
            </div>
            <div class="customers">
              <span>
                <!-- {{ this?.org?.last_uploaded_customer_count }}  -->
                <img src="@/../public/assets/images/customer.svg" alt="Logo" /> customers</span>
            </div>
          </div>
          <div class="edit-box-selection file-import-btn">
            <button data-toggle="modal" data-target="#customerFileImportModel"><img src="@/../public/assets/images/add.svg" alt="Logo" /> Add Files</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="dashboard-block-box">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <InventoryStockIcon class="mr-3" />
              <p class="m-0">Upload inventory stock from file</p>
            </div>
          </div>
          <div class="edit-box-selection file-import-btn">
            <button data-toggle="modal" data-target="#articleFileImportModel"><img src="@/../public/assets/images/add.svg" alt="Logo" /> Add Files</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- add article file model  -->
  <div class="modal fade" id="articleFileImportModel" tabindex="-1" role="dialog"
    aria-labelledby="articleFileImportModelLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="articleFileImportModelLabel">
            Add Articles file
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="add-cutomers-file-popup">
            <div class="info-line alert alert-success" role="alert">
              <img src="@/../public/assets/images/sms.svg" alt="Logo" />
              <p>
                You can send store Articles by upload them in MS Excel format
              (.xlxs). This will update the existed Articles, and add newly sold.
              Template for the Excel structure:
                <a href="/assets/files/example_Inventory_file.xlsx" download>click here</a>
              </p>
            </div>

          <div class="file-drop-box">
            <div class="file-drop-upload">
              <input type="file" id="file-upload" class="form-control d-none" @change="onFileChnage"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                <div class="file-drop-upload-info">
                  <img src="@/../public/assets/images/upload-icon.svg" alt="Logo" />
                  <h6>
                    Drag & drop files or 
                    <label for="file-upload" role="button">
                      Browse</label>
                  </h6>
                  <p>Supported formats: Excel</p>
                  <span>{{ articleFile?.name }}</span>
                  <div class="form-control-feedback">{{ articleFileErrMsg }}</div>
                </div>
            </div>
          </div>
        </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="save-btn" @click="HandleFileUpload">
            <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Add File</span>
          </button>
          <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close" id="close-file-import-model">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- add receipt file model  -->
  <div class="modal fade" id="receiptFileModel" tabindex="-1" role="dialog" aria-labelledby="receiptFileModelLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="receiptFileModel">Add Receipt File</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="add-cutomers-file-popup">
            <div class="info-line alert alert-success" role="alert">
              <img src="@/../public/assets/images/sms.svg" alt="Logo" />
              <p>
                You can send receipts/orders by upload them in JSON format (.json). This will create receipts/order. Template for the JSON structure:
                <a href="/assets/files/receipt-data-sample.json" download>click here</a>
              </p>  
            </div>
            <div class="file-drop-box">
              <div class="file-drop-upload">
                <input type="file" id="file-upload-receipt" class="form-control d-none" @change="onReceiptFileChange"
                  accept=".json, application/json" />
                  <div class="file-drop-upload-info">
                    <img src="@/../public/assets/images/upload-icon.svg" alt="Logo" />
                    <h6>
                      Drag & drop files or 
                      <label for="file-upload-receipt" role="button">
                        Browse</label>
                    </h6>
                    <p>Supported formats: Excel</p>
                    <span>{{ receiptFile?.name }}</span>
                    <div class="form-control-feedback">{{ receiptFileErrMsg }}</div>
                  </div>
              </div>
              
              <!-- <div>
                      <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                    </div> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
          id="close-receipt-file-import-model">
          Cancel
        </button>
          <button type="submit" class="save-btn" @click="HandleReceiptFileUpload">
            <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Add File</span>
          </button>
         
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="ShopifyReceiptFileModel" tabindex="-1" role="dialog"
    aria-labelledby="ShopifyReceiptFileModelLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ShopifyReceiptFileModel">Add Receipt CSV File from shopify</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send receipts/orders by upload them in CSV format (.csv).
            This will create receipts/order.<br />
            Template for the CSV structure:
            <a href="/assets/files/Shopify_orders_sample.csv" download>
              <ul>
                click here
              </ul>
            </a>
          </div>
          <div>
            <div class="d-flex">
              <input type="file" id="file-upload-shopify-receipt" class="form-control d-none"
                @change="onShopifyReceiptFileChange" accept=".csv" />

              <label for="file-upload-shopify-receipt" class="save-btn" role="button">
                Select File</label>
              <span class="p-2">{{ shopifyReceiptFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ shopifyReceiptFileErrMsg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="save-btn" @click="HandleShopifyReceiptFileUpload">
            <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Add File</span>
          </button>
          <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
            id="close-shopify-receipt-file-import-model">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- add multiple reciepts model -->
  <div class="modal fade" id="MultipleReceiptFileModel" tabindex="-1" role="dialog"
    aria-labelledby="MultipleReceiptFileModelLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="MultipleReceiptFileModel">Add Receipt XLSX File</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send receipts/orders by upload them in XLSX format (.xlsx).
            This will create receipts/order.<br />
            Template for the XLSX structure:
            <a href="/assets/files/Receipts_CSV.xlsx" download>
              <ul>
                click here
              </ul>
            </a>
          </div>
          <div>
            <div class="">
              <input type="file" id="multiple-file-upload-receipt" class="form-control d-none"
                @change="onMultipleReceiptFileChange" webkitdirectory multiple ref="fileInput" />
              <label for="multiple-file-upload-receipt" class="save-btn" role="button">
                Select File...</label>
              <div class="uploded-file-name">
                <span class="p-2" v-if="multipleReceiptFileDisplay && multipleReceiptFileDisplay.length > 0"> <span
                    v-for="file in multipleReceiptFileDisplay" :key="file">{{ file }}</span> </span>
              </div>
            </div>
            <div class="form-control-feedback">
              {{ multipleReceiptFileErrMsg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="save-btn" @click="HandleMultipleReceiptFileUpload">
            <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Add File</span>
          </button>
          <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
            id="close-multiple-receipt-file-import-model">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- add customer model -->
  <div class="modal fade" id="customerFileImportModel" tabindex="-1" role="dialog"
    aria-labelledby="customerFileImportModelLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="customerFileImportModelLabel">
            Add Customers file
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="add-cutomers-file-popup">
            <div class="info-line alert alert-success" role="alert">
              <img src="@/../public/assets/images/sms.svg" alt="Logo" />
              <p>
                You can send customers by upload them in MS Excel format
              (.xlxs). This will update the existed customers, and add new customers.
              Template for the Excel structure:
              <a href="/assets/files/customer_final.xlsx" download>click here</a>
              </p>
            </div>
          <div class="file-drop-box">
            <div class="file-drop-upload">
              <input type="file" id="file-upload-customers" class="form-control d-none"
                @change="oncustomersFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                <div class="file-drop-upload-info">
                  <img src="@/../public/assets/images/upload-icon.svg" alt="Logo" />
                  <h6>
                    Drag & drop files or 
                    <label for="file-upload-customers" role="button">
                      Browse</label>
                  </h6>
                  <p>Supported formats: Excel</p>
                  <span>{{ customersFile?.name }}</span>
                  <div class="form-control-feedback">{{ customersFileErrMSg }}</div>
                </div>
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
          id="close-file-import-model-customer">
          Cancel
        </button>
          <button type="submit" class="save-btn" @click="HandleCustomerFileUpload">
            <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Add File</span>
          </button>
        
        </div>
      </div>
    </div>
  </div>
  <!-- add sales channel model -->
  <div class="modal fade" id="salesChannelFileImportModel" tabindex="-1" role="dialog"
    aria-labelledby="salesChannelFileImportModelLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="salesChannelFileImportModelLabel">
            Add sales channel file
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send sales channel by upload them in MS Excel format
            (.xlxs). This will update the existed sales channel, and add new sales channel.
            Template for the Excel structure:
            <a href="/assets/files/Store_example_file.xlsx" download>
              <ul>
                click here
              </ul>
            </a>
          </div>
          <div>
            <div class="d-flex">
              <input type="file" id="file-upload-sales_channel" class="form-control d-none"
                @change="onSalesChannelFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              <label for="file-upload-sales_channel" class="save-btn" role="button">
                Select File</label>
              <span class="p-2">{{ salesChannelsFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ salesChannelsFileErrMSg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="save-btn" @click="HandleSalesChannelFileUpload">
            <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Add File</span>
          </button>
          <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
            id="close-file-import-model-sales-channel">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import FormManuallyIcon from "../../icons/formManually.vue";
// import FileUploadIcon from "../../icons/fileUpload.vue";
//import ArticleUploadIcon from "../../icons/article-white.vue";
import InventoryStockIcon from "../../icons/inventory-stock.vue";
import OrderUploadIcon from "../../icons/orderUpload.vue";
import CustomerUploadIcon from "../../icons/customerUpload.vue"
// import SalesChannelUploadIcon from "../../icons/salesChannelUpload.vue"
// import ShopifyUploadIcon from "../../icons/shopifyUpload.vue"
// import RecieptsUploadIcon from "../../icons/recieptsUploadIcon.vue"
import {
  ARTICLE_FILE_UPLOAD,
  INVENTORY_FILE_UPLOAD,
  RECEIPT_FILE_UPLOAD,
  CUSTOMER_FILE_UPLOAD,
  UPDATE_ORG_UPLOAD_DATA,
  SALES_CHANNEL_FILE_UPLOAD,
  GET_ORG,
  SHOPIFY_RECEIPT_FILE_UPLOAD,
  MULTIPLE_RECEIPT_FILE_UPLOAD
} from "@/Core/store/action-types";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Imports",
  components: {
    // FormManuallyIcon,
    // FileUploadIcon,
    //ArticleUploadIcon,
    InventoryStockIcon,
    OrderUploadIcon,
    CustomerUploadIcon,
    // SalesChannelUploadIcon,
    // ShopifyUploadIcon,
    // RecieptsUploadIcon
  },
  data() {
    return {
      articleFile: null,
      articleFileErrMsg: "",
      receiptFile: null,
      receiptFileErrMsg: "",
      shopifyReceiptFile: [],
      shopifyReceiptFileDisplay: [],
      shopifyReceiptFileErrMsg: "",
      multipleReceiptFile: [],
      multipleReceiptFileDisplay: [],
      multipleReceiptFileErrMsg: "",
      customersFile: null,
      customersFileErrMSg: "",
      salesChannelsFile: null,
      salesChannelsFileErrMSg: "",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("user", { userProfile: "userProfile" }),
    ...mapGetters("org", ["org"]),
  },
  methods: {
    ...mapActions("receipt", [ARTICLE_FILE_UPLOAD, RECEIPT_FILE_UPLOAD, SHOPIFY_RECEIPT_FILE_UPLOAD, MULTIPLE_RECEIPT_FILE_UPLOAD, INVENTORY_FILE_UPLOAD]),
    ...mapActions("_store", [SALES_CHANNEL_FILE_UPLOAD]),
    ...mapActions("customer", [CUSTOMER_FILE_UPLOAD]),
    ...mapActions("user", [UPDATE_ORG_UPLOAD_DATA]),
    ...mapActions('org', [GET_ORG]),
    HandleFileUpload() {
      if (this.articleFile) {
        this.isLoading = true
        this[INVENTORY_FILE_UPLOAD](this.articleFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Article Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            document.getElementById("close-file-import-model").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.articleFileErrMsg = "Please select file";
      }
    },
    HandleCustomerFileUpload() {
      if (this.customersFile) {
        this.isLoading = true
        this[CUSTOMER_FILE_UPLOAD](this.customersFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Customers Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            document.getElementById("close-file-import-model-customer").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.customersFileErrMSg = "Please select file";
      }
    },
    onSalesChannelFileChange(e) {
      console.log("SalesChannelFileChange")
      this.salesChannelsFile = e.target.files[0];
    },
    HandleSalesChannelFileUpload() {
      if (this.salesChannelsFile) {
        console.log("salesChannelsFile", this.salesChannelsFile)
        this.isLoading = true
        this[SALES_CHANNEL_FILE_UPLOAD](this.salesChannelsFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Sales channel Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            document.getElementById("close-file-import-model-sales-channel").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.salesChannelsFileErrMSg = "Please select file";
      }
    },
    onFileChnage(e) {
      this.articleFile = e.target.files[0];
      console.log("e", this.articleFile);
    },
    getdate(date) {
      // console.log(new Date(date).toLocaleTimeString('en', {timeZone: 'America/New_York'}))
      return new Date(date)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
    },
    getTime(date) {
      return new Date(date).toLocaleTimeString('en-US')
    },
    oncustomersFileChange(e) {
      this.customersFile = e.target.files[0];
    },
    HandleReceiptFileUpload() {
      if (this.receiptFile) {
        this.isLoading = true
        this[RECEIPT_FILE_UPLOAD](this.receiptFile)
          .then(() => {
            this.$alertify.notify(
              "Receipt generated successfully",
              "success",
              3
            );
            document.getElementById("close-receipt-file-import-model").click();
            this.receiptFile = null;
          })
          .catch((error) => {
            this.$alertify.notify(
              error.response?.data?.detail || "There is some error, please try again",
              "error",
              3
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.receiptFileErrMsg = "Please select file";
      }
    },
    onReceiptFileChange(e) {
      if (e.target.files.length > 0) {
        this.receiptFileErrMsg = "";
        this.receiptFile = e.target.files[0];
        e.target.value = '';
      } else {
        this.receiptFile = null;
        this.receiptFileErrMsg = "Please select a file.";
      }
    },
    onShopifyReceiptFileChange(e) {
      this.shopifyReceiptFile = e.target.files[0];
      console.log("e", this.shopifyReceiptFile);
    },

    UploadReceiptModalClear() {
      this.multipleReceiptFileDisplay = [];
      this.multipleReceiptFile = [];
    },
    onMultipleReceiptFileChange(e) {
      for (let file of e.target.files) {
        if (!file.name.startsWith("~$")) {
          if (file.name.split(".")[1] === "csv" || file.name.split(".")[1] === "xlsx") {
            this.multipleReceiptFile = [...this.multipleReceiptFile, file];
            this.multipleReceiptFileDisplay = [...this.multipleReceiptFileDisplay, file.name];
          } else {
            this.multipleReceiptFileErrMsg = "We only accept .csv or .xls file format. Files with other extension will be ignored."
          }
        }
      }
      this.$refs.fileInput.value = "";
    },
    HandleShopifyReceiptFileUpload() {
      if (this.shopifyReceiptFile) {
        this.isLoading = true
        this[SHOPIFY_RECEIPT_FILE_UPLOAD](this.shopifyReceiptFile)
          .then(() => {
            this.$alertify.notify(
              "Receipts generated successfully",
              "success",
              3
            );
            // this.shopifyReceiptFile = '';
            document.getElementById("close-shopify-receipt-file-import-model").click();
          })
          .catch((e) => {
            this.$alertify.notify(
              e.response?.data?.detail || "There is some error, please try again",
              "error",
              3
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.shopifyReceiptFileErrMsg = "Please select file";
      }
    },
    HandleMultipleReceiptFileUpload() {
      if (this.multipleReceiptFile) {
        this.isLoading = true
        this[MULTIPLE_RECEIPT_FILE_UPLOAD](this.multipleReceiptFile)
          .then(() => {
            this.$alertify.notify(
              "Receipts generated successfully",
              "success",
              3
            );
            // this.shopifyReceiptFile = '';
            document.getElementById("close-multiple-receipt-file-import-model").click();
          })
          .catch((e) => {
            this.$alertify.notify(
              e.response?.data?.detail || "There is some error, please try again",
              "error",
              3
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.multipleReceiptFileErrMsg = "Please select file";
      }
    },
    onArticleFileChange(e) {
      this.articleFile = e.target.files[0];
    },
    HandleArticleFileUpload() {
      if (this.articleFile) {
        this.isLoading = true
        this[INVENTORY_FILE_UPLOAD](this.articleFile)
          .then(() => {
            this.$alertify.notify(
              "Article Uploaded successfully",
              "success",
              3
            );
            document.getElementById("close-article-file-import-model").click();
            this.articleFile = null;
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.articleFileErrMsg = "Please select file";
      }
    },

    // download(fileUrl, fileName) {
    //   var a = document.createElement("a");
    //   a.href = fileUrl;
    //   a.setAttribute("download", fileName);
    //   a.click();
    // },
  },
};
</script>
<style scoped>

.upload-info span em {
  font-style: normal;
  color: #2422207A;
}

.dashboard-block-section .dashboard-block-box .file-import-btn button{
  background: #565DF2;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin-left: auto;
    margin-right: 0;
    padding: 8px 10px;
}
.dashboard-block-section .dashboard-block-box .upload-info span{
  margin: 0;
  color: #242220;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.dashboard-block-section .dashboard-block-box .customers span{
  margin: 0;
    background: #F5F7F9;
    border-radius: 8px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    gap: 5px;
}
</style>
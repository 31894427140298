<template>
  <div class="main-wrp">
    <MainMenu />
    <TopBar title="Most Valuable Customers" :author="{ home: 'Home' }" />
    <div class="box-wrapper">
      <!-- heading -->
      <div class="data-heading-wrp">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <h5 class="section-title mb-0">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBjVJBbtpQEJ0Z29+N2lS+QeEGcIKSE5TuqihBlhrSRReQEwROELproBWmRN2GngBuEHIDjkClVBQb/8kMwY4SJQqz8HyP/3vz3owRNrEf3pQc3zTAQkFeB8OeiWCLIH18ChcF1zNjtFDVCiL0D+txf2sCz3dOAWFOTloenps9YPgmJOHnr/xuKwLpGQhoHn3fmembZRxpXi7/F18icNdwtteiu1o7Tk4t4DWBPZPy7OLHziS7WKvHDUktURowYGfY9U5yBb+6fktlA3BLwJdyDojjjxn44CipCLDDAFO5M0DgZu142YJ7C0LSM02itLjiuEyvvWLUezPNfZKtaJbN7EmzUI5TZnyfW6iGHLw1q4ZNuemiE6T/ksnB0aKdWbApTMkRGxuLUikRQZQr2PWSS5XPyALgtmygQOSMhWTd+eKnP3pgUeaDmLbX81N/RDxmsCfD7quOFkNRZE1yxQwzlZ1Z2a/flDT/fmAv87cBa0QRzqXjH1FSySyqfBfM2EVzdfgl7uvPl8/gyUCey2N93PWTMyEMUXzLJv4iwwdjnIooLT9PsAntJIBQZzM4l3WDrnUx0hmlXlIlZjtjhMljYFYnzwk0rzgZZd90O3eYFG4Bk3jRLFulvBYAAAAASUVORK5CYII="
                alt=""
              />
              Most Valuable Customers
            </h5>
          </div>

          <div class="col-auto">
            <div class="action-wrapper d-flex align-items-center gap-4">
              <span class="filter-wrp">
                <select
                  class="for"
                  id="adminfilter"
                  v-model="valuablecustomerTime"
                  @change="onTimeChange"
                >
                  <option v-for="option in timeOptions" :key="option">
                    {{ option }}
                  </option>
                </select>
                <!-- <img src="@/../public/assets/images/calendar.svg" alt="calendar" /> -->
              </span>
              <button type="button" class="save-btn" @click="generateCSV()">
                Export List
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow">
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <table class="table my-table-wrp table-sm" id="my-table">
          <thead>
            <tr>
              <th width="10%">Customer ID</th>
              <th width="30%">Company Name</th>
              <th width="20%">Total Amount</th>
              <th width="20%">Currency</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in all_most_valuable_customers
                ? all_most_valuable_customers.slice(
                    25 * (page_model - 1),
                    page_model * 25
                  )
                : []"
              :key="customer.id"
            >
              <td class="font-light-text">
                {{ customer.id }}
              </td>
              <td class="font-text">
                {{ customer.company_name }}
              </td>
              <td class="font-light-text">
                {{ customer.total_value.toFixed(0) }}
              </td>
              <td class="font-light-text">
                {{ customer.currency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :page="page_model"
        :totalPages="
          all_most_valuable_customers
            ? Array(Math.ceil(all_most_valuable_customers.length / 25))
                .fill(0)
                .map((e, i) => i + 1)
            : []
        "
        :name="'Customers'"
        :count="all_most_valuable_customers ? all_most_valuable_customers.length : 0"
        :incrementpage="incrementpage_model"
        :decrementpage="decrementpage_model"
        :setpage="setpage_model"
        :perpage="25"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { CREATE_AUDIENCE, GET_MOST_VALUABLE_CUSTOMERS } from "@/Core/store/action-types";
import Pagination from "../../../Core/Pagination.vue";
import TopBar from "@/Menu/TopBar.vue";
import MainMenu from "@/Menu/MainMenu.vue";

export default {
  name: "MostValuableView",
  components: {
    Pagination,
    TopBar,
    MainMenu,
  },
  data() {
    return {
      page_model: 1,
      highest_ordering_customers: [],
      all_most_valuable_customers: [],
      valuablecustomerTime: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      createAudienceMode: false,
      nameError: false,
      errMsg: "",
      audienceName: "",
      loadindShow: false,
    };
  },
  computed: {
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("audience", [CREATE_AUDIENCE]),
    ...mapActions("customer", [GET_MOST_VALUABLE_CUSTOMERS]),
    initValuableCustomer(isAll = true) {
      this.loadindShow = true;
      let params = {
        currency: this.default_currency,
        duration:
          this.valuablecustomerTime == "month"
            ? 30
            : this.valuablecustomerTime == "week"
            ? 7
            : this.valuablecustomerTime == "quater"
            ? 90
            : this.valuablecustomerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this.loadindShow = true;
      this[GET_MOST_VALUABLE_CUSTOMERS](params)
        .then((result) => {
          if (isAll) this.all_most_valuable_customers = result;
          else this.highest_ordering_customers = result;
        })
        .catch(() => (this.highest_ordering_customers = []))
        .finally(() => (this.loadindShow = false));
    },
    onTimeChange() {
      this.initValuableCustomer();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    validateName() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    generateCustomerIdArray() {
      let customerIds = [];
      this.all_most_valuable_customers.map((customer) => customerIds.push(customer.id));
      return customerIds;
    },
    onAudienceCreate() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.all_most_valuable_customers.length <= 0) {
        this.nameError = false;
        this.$alertify.notify("There in no customers to create audience", "error", 3);
      } else {
        this.nameError = false;
        let payload = {
          members: this.generateCustomerIdArray(),
          name: this.audienceName,
          articles: [],
          article_pref_type: "",
          gender: "All",
          churn_status: "All",
          age_min: 0,
          age_max: 100,
        };
        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audienceName} successfully created`,
              "success",
              3
            );
            this.createAudienceMode = false;
          })
          .catch((e) => {
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    generateCSV() {
      console.log("Total customers:", this.all_most_valuable_customers.length); // Debug: Check how many customers are loaded

      if (this.all_most_valuable_customers.length === 0) {
        console.error("No customers data available to export.");
        return; // Exit if no customers data is available
      }

      let arrData = [
        {
          customers: this.all_most_valuable_customers,
        },
      ];

      // Prepare the CSV content: First header, then data rows
      let csvContent = [
        Object.keys(arrData[0].customers[0]).join(","), // Header
        ...arrData[0].customers.map(
          (item) =>
            Object.values(item)
              .map((field) =>
                field === null || field === undefined ? '"N/A"' : `"${field}"`
              )
              .join(",") 
        ), // Rows
      ].join("\n");

      // Clean up any unwanted characters (e.g., #)
      csvContent = csvContent.replace(/#/g, "");

      console.log("csvContent :", csvContent);

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `valuable-customers-${this.valuablecustomerTime}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Revoke the Object URL to free up memory
      URL.revokeObjectURL(url);
    },
  },
  mounted() {
    this.initValuableCustomer();
  },
};
</script>
<style scoped>
.box-wrapper {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.main-pagination {
  padding: 20px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table > :not(:first-child) {
  border-top: 0;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-wrp select {
  font-size: 14px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border: none;
  border-radius: 10px;
  padding: 10px;
}
.action-wrapper {
  white-space: nowrap;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none">
    <rect y="0.5" width="44" height="44" rx="10" fill="#585BEF" fill-opacity="0.1"/>
    <g clip-path="url(#clip0_169_2818)">
    <path d="M29.166 12.8906H25.0125C24.6897 11.4944 23.4517 10.5 21.999 10.5C20.5463 10.5 19.3083 11.4944 18.9855 12.8906H14.832C14.1859 12.8906 13.6602 13.4163 13.6602 14.0625V33.3281C13.6602 33.9743 14.1859 34.5 14.832 34.5H29.1661C29.8123 34.5 30.338 33.9743 30.338 33.3281V14.0625C30.3379 13.4163 29.8123 12.8906 29.166 12.8906ZM21.999 11.9062C22.6758 11.9062 23.2668 12.3015 23.5347 12.8906H20.4634C20.7313 12.3015 21.3223 11.9062 21.999 11.9062ZM18.3717 14.2969H25.6264V15.7813H18.3717V14.2969ZM28.9317 33.0938H15.0664V14.2969H16.9655V16.0156C16.9655 16.6618 17.4912 17.1875 18.1374 17.1875H25.8608C26.507 17.1875 27.0327 16.6618 27.0327 16.0156V14.2969H28.9318V33.0938H28.9317Z" fill="#565DF2"/>
    <path d="M26.1946 25.492H17.8027C17.4144 25.492 17.0996 25.8068 17.0996 26.1951C17.0996 26.5834 17.4144 26.8982 17.8027 26.8982H26.1945C26.5828 26.8982 26.8977 26.5834 26.8977 26.1951C26.8977 25.8068 26.5829 25.492 26.1946 25.492ZM26.1946 28.9295H17.8027C17.4144 28.9295 17.0996 29.2443 17.0996 29.6326C17.0996 30.0209 17.4144 30.3357 17.8027 30.3357H26.1945C26.5828 30.3357 26.8977 30.0209 26.8977 29.6326C26.8977 29.2443 26.5829 28.9295 26.1946 28.9295ZM20.436 23.3177C20.554 23.4435 20.7142 23.5215 20.886 23.5368C21.0578 23.5522 21.2293 23.5039 21.3678 23.4011L24.9701 20.7272C25.2819 20.4957 25.3471 20.0554 25.1156 19.7435C24.8841 19.4317 24.4437 19.3666 24.1319 19.598L21.0327 21.8985L19.959 20.7545C19.6933 20.4713 19.2483 20.4572 18.9652 20.723C18.682 20.9887 18.6679 21.4337 18.9336 21.7169L20.436 23.3177Z" fill="#565DF2"/>
    </g>
    <defs>
    <clipPath id="clip0_169_2818">
    <rect width="24" height="24" fill="white" transform="translate(10 10.5)"/>
    </clipPath>
    </defs>
    </svg>
</template>

<script>
export default {
  name: "CustomerUploadIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
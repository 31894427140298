import {
  LIST_CUSTOMERS,
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  LIST_CUSTOMER_RECEIPTS,
  LIST_RECENT_CUSTOMERS,
  CUSTOMER_DETAILS,
  LIST_CUSTOMER_PURCHASES,
  GET_GDPR_DATA,
  GET_MOST_VALUABLE_CUSTOMERS,
  GET_REOCCURING_CUSTOMERS,
  CUSTOMER_FILE_UPLOAD,
  GET_CUSTOMER_RESTOCK,
  GET_CHURN_POTENTIAL_CUSTOMERS,
  GET_CUSTOMER_NOORDER,
  GET_CUSTOMER_ALL,
  GET_CUSTOMER_INACTIVE,
  CUSTOMER_CONTACT_MODE_UPDATE,
  CUSTOMER_PURCHASED_ORDER_MODE_UPDATE
} from "@/Core/store/action-types";

import {
  SET_CUSTOMERS_LIST,
  REMOVE_CUSTOMER,
  EDIT_CUSTOMER,
  ADD_CUSTOMER,
  SET_RECENT_CUSTOMERS_LIST,
  SET_CUSTOMER_DETAILS,
} from "@/Core/store/mutation-types";

import api from "./customer.api";
// import router from '../Router';

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_CUSTOMERS]({ commit, rootState }, { params, persist }) {
    const resp = await api.get(rootState.user.userProfile.access, params);
    let members = resp.data.results ? resp.data.results : resp.data;
    if (persist) commit(SET_CUSTOMERS_LIST, members);
    return resp.data;
  },
  async [CUSTOMER_DETAILS]({ commit, rootState },params) {
    try {
      const resp = await api.get_member_detail(
        rootState.user.userProfile.access,
        params
      );
      let member_details = resp.data.results ? resp.data.results : resp.data;
      commit(SET_CUSTOMER_DETAILS, member_details);
    } catch (e) {
      throw new Error("Error in fetch member details page : ");
    }
  },
  async [LIST_RECENT_CUSTOMERS]({ rootState }) {
    const resp = await api.get_recent(rootState.user.userProfile.access);
    // let members = resp.data.results ? resp.data.results: resp.data;
    // commit(SET_CUSTOMERS_LIST, members);
    return resp.data;
  },
  async [DELETE_CUSTOMER]({ rootState, commit }, objId) {
    try {
      await api.delete(rootState.user.userProfile.access, objId);
      commit(REMOVE_CUSTOMER, objId);
      // router.push('/Members');
    } catch (e) {
      throw new Error("");
    }
  },
  async [CREATE_CUSTOMER]({ rootState, commit }, obj) {
    const resp = await api.create(rootState.user.userProfile.access, obj);
    commit(ADD_CUSTOMER, resp.data);
    return resp.data;
  },
  async [LIST_CUSTOMER_RECEIPTS]({ rootState }, { params, objId }) {
    const resp = await api.get_receipts(
      rootState.user.userProfile.access,
      params,
      objId
    );
    return resp.data;
  },
  async [LIST_CUSTOMER_PURCHASES]({ rootState }, { params, objId }) {
    const resp = await api.get_member_purchases(
      rootState.user.userProfile.access,
      params,
      objId
    );
    return resp.data;
  },
  async [UPDATE_CUSTOMER]({ rootState, commit }, obj) {
    const response = await api.update(rootState.user.userProfile.access, obj);
    commit(EDIT_CUSTOMER, response.data);
    return response.data;
  },
  async [GET_GDPR_DATA]({ rootState }, member_id) {
    const response = await api.get_gdpr_data(
      rootState.user.userProfile.access,
      member_id
    );
    return response.data;
  },
  async [GET_MOST_VALUABLE_CUSTOMERS]({ rootState }, params) {
    const response = await api.get_highest_ordering_customers(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_REOCCURING_CUSTOMERS]({ rootState }, params) {
    const response = await api.get_most_reoccuring_customers(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [CUSTOMER_FILE_UPLOAD]({ rootState }, file) {
    const resp = await api.customer_file_upload(
      rootState.user.userProfile.access,
      file
    );
    return resp.data;
  },
  async [GET_CUSTOMER_RESTOCK]({ rootState }, params) {
    const response = await api.get_customer_restock(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_CUSTOMER_NOORDER]({ rootState }, params) {
    const response = await api.get_customer_noOrder(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_CUSTOMER_ALL]({ rootState }, params) {
    const response = await api.get_customer_all(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_CUSTOMER_INACTIVE]({ rootState }, params) {
    const response = await api.get_customer_inactive(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_CHURN_POTENTIAL_CUSTOMERS]({ rootState }, params) {
    const response = await api.get_churn_potential_customers(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [CUSTOMER_CONTACT_MODE_UPDATE]({ rootState }, obj) {
    const resp = await api.customer_contact_mode_update(rootState.user.userProfile.access, obj);
    return resp.data;
  },
  async [CUSTOMER_PURCHASED_ORDER_MODE_UPDATE]({ rootState }, obj) {
    const resp = await api.customer_purchased_order_mode_update(rootState.user.userProfile.access, obj);
    return resp.data;
  },
};

const mutations = {
  [SET_CUSTOMERS_LIST](state, customersList) {
    state.customersList = customersList.results
      ? customersList.results
      : customersList;
  },
  [SET_CUSTOMER_DETAILS](state, customerDetails) {
    console.log("state.customerDetails", customerDetails);
    state.customerDetails = customerDetails.results
      ? customerDetails.results
      : customerDetails;
    // state.customerDetails.birth_date =state.customerDetails.birth_date.split("/").reverse().join("-")
  },
  [SET_RECENT_CUSTOMERS_LIST](state, recentMembersList) {
    state.recentMembersList = recentMembersList
      ? recentMembersList.results
      : recentMembersList;
  },
  [ADD_CUSTOMER](state, obj) {
    // state.customersList.push(obj);
    console.log(state, obj);
  },
  [EDIT_CUSTOMER](state, obj) {
    // let idx = state.customersList.findIndex((u) => u.id == obj.id);

    // if (idx > -1) {
    //   state.customersList[idx] = obj;
    // }
    console.log(state, obj);
  },
  [REMOVE_CUSTOMER](state, objId) {
    console.log(state, objId);

    //   let idx = state.customersList.findIndex((u) => u.id == objId);

    //   if (idx > -1) {
    //     state.customersList[idx].is_active = false;
    //   }
  },
};

const getters = {
  customersList(state) {
    return state.customersList;
  },
  customerDetails(state) {
    return state.customerDetails;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

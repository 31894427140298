<template>
  <div class="main-wrp">
    <MainMenu />
    <TopBar title="Most Re-occuring Customers" :author="{ home: 'Home' }" />
    <div class="box-wrapper">
      <!-- heading -->
      <div class="data-heading-wrp">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <h5 class="section-title mb-0">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBjVJBbtpQEJ0Z29+N2lS+QeEGcIKSE5TuqihBlhrSRReQEwROELproBWmRN2GngBuEHIDjkClVBQb/8kMwY4SJQqz8HyP/3vz3owRNrEf3pQc3zTAQkFeB8OeiWCLIH18ChcF1zNjtFDVCiL0D+txf2sCz3dOAWFOTloenps9YPgmJOHnr/xuKwLpGQhoHn3fmembZRxpXi7/F18icNdwtteiu1o7Tk4t4DWBPZPy7OLHziS7WKvHDUktURowYGfY9U5yBb+6fktlA3BLwJdyDojjjxn44CipCLDDAFO5M0DgZu142YJ7C0LSM02itLjiuEyvvWLUezPNfZKtaJbN7EmzUI5TZnyfW6iGHLw1q4ZNuemiE6T/ksnB0aKdWbApTMkRGxuLUikRQZQr2PWSS5XPyALgtmygQOSMhWTd+eKnP3pgUeaDmLbX81N/RDxmsCfD7quOFkNRZE1yxQwzlZ1Z2a/flDT/fmAv87cBa0QRzqXjH1FSySyqfBfM2EVzdfgl7uvPl8/gyUCey2N93PWTMyEMUXzLJv4iwwdjnIooLT9PsAntJIBQZzM4l3WDrnUx0hmlXlIlZjtjhMljYFYnzwk0rzgZZd90O3eYFG4Bk3jRLFulvBYAAAAASUVORK5CYII="
                alt=""
              />
              Re-occuring Customers
            </h5>
          </div>

          <div class="col-auto">
            <div class="action-wrapper d-flex align-items-center gap-4">
              <span class="filter-wrp">
                <select
                  class="form-select"
                  id="adminfilter"
                  v-model="reoccuringcustomerTime"
                  @change="onTimeChange"
                >
                  <option v-for="option in timeOptions" :key="option">
                    {{ option }}
                  </option>
                </select>
              </span>
              <button type="button" class="save-btn" @click="generateCSV()">
                Export List
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow">
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <table class="table my-table-wrp table-sm" id="my-table">
          <thead>
            <tr>
              <th width="15%">Customer ID</th>
              <th width="40%">Company Name</th>
              <th width="20%">Purchase Count</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in all_reoccuring_customers
                ? all_reoccuring_customers.slice(25 * (page_model - 1), page_model * 25)
                : []"
              :key="customer.id"
            >
              <td class="font-light-text">
                {{ customer.member__id }}
              </td>
              <td class="font-text">
                {{ customer.member__company_name }}
              </td>
              <td class="font-light-text">
                {{ customer.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        :page="page_model"
        :totalPages="
          all_reoccuring_customers
            ? Array(Math.ceil(all_reoccuring_customers.length / 25))
                .fill(0)
                .map((e, i) => i + 1)
            : []
        "
        :name="'Customers'"
        :count="all_reoccuring_customers ? all_reoccuring_customers.length : 0"
        :incrementpage="incrementpage_model"
        :decrementpage="decrementpage_model"
        :setpage="setpage_model"
        :perpage="25"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { CREATE_AUDIENCE, GET_REOCCURING_CUSTOMERS } from "@/Core/store/action-types";
import Pagination from "../../../Core/Pagination.vue";
import TopBar from "@/Menu/TopBar.vue";
import MainMenu from "@/Menu/MainMenu.vue";

export default {
  name: "MostReoccuringView",
  components: {
    Pagination,
    TopBar,
    MainMenu,
  },
  data() {
    return {
      page_model: 1,
      reoccuring_customers: [],
      all_reoccuring_customers: [],
      reoccuringcustomerTime: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      createAudienceMode: false,
      nameError: false,
      errMsg: "",
      audienceName: "",
      loadindShow: false,
    };
  },
  methods: {
    ...mapActions("audience", [CREATE_AUDIENCE]),
    ...mapActions("customer", [GET_REOCCURING_CUSTOMERS]),
    initReOccuringCustomers(isAll = true) {
      let params = {
        duration:
          this.reoccuringcustomerTime == "month"
            ? 30
            : this.reoccuringcustomerTime == "week"
            ? 7
            : this.reoccuringcustomerTime == "quater"
            ? 90
            : this.reoccuringcustomerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this.loadindShow = true;
      this[GET_REOCCURING_CUSTOMERS](params)
        .then((result) => {
          if (isAll) this.all_reoccuring_customers = result;
          else this.reoccuring_customers = result;
        })
        .catch(() => (this.reoccuring_customers = []))
        .finally(() => (this.loadindShow = false));
    },
    onTimeChange() {
      this.initReOccuringCustomers();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    validateName() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    generateCustomerIdArray() {
      let customerIds = [];
      this.all_reoccuring_customers.map((customer) =>
        customerIds.push(customer.member__id)
      );
      return customerIds;
    },
    onAudienceCreate() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.all_reoccuring_customers.length <= 0) {
        this.nameError = false;
        this.$alertify.notify("There in no customers to create audience", "error", 3);
      } else {
        this.nameError = false;
        let payload = {
          members: this.generateCustomerIdArray(),
          name: this.audienceName,
          articles: [],
          article_pref_type: "",
          gender: "All",
          age_min: 0,
          churn_status: "All",
          age_max: 100,
        };

        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audienceName} successfully created`,
              "success",
              3
            );
            this.createAudienceMode = false;
          })
          .catch((e) => {
            console.log("in aud", e.message);
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    generateCSV() {
      if (this.all_reoccuring_customers.length === 0) {
        console.error("No customers data available to export.");
        return;
      }
      let arrData = [
        {
          customers: this.all_reoccuring_customers,
        },
      ];

      // Prepare the CSV content: First header, then data rows
      let csvContent = [
        Object.keys(arrData[0].customers[0] || {}).join(","), // Header
        ...arrData[0].customers.map(
          (item) =>
            Object.values(item)
              .map((field) =>
                field === null || field === undefined ? '"N/A"' : `"${field}"`
              )
              .join(",") // Wrap each value in quotes
        ), // Rows
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `reoccuring-customers-${this.reoccuringcustomerTime}.csv`
      );
      link.click();
      URL.revokeObjectURL(url);
    },
  },
  mounted() {
    this.initReOccuringCustomers();
  },
};
</script>
<style scoped>
.box-wrapper {
  padding: 0;
  border: 1px solid #ececed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}
.main-pagination {
  padding: 20px;
}
.data-heading-wrp {
  padding: 20px;
  display: block;
}

.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a2b3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section-title img {
  margin-right: 10px;
}

.table-wrp .table thead tr th {
  color: #4f4d55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4f4d55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #e9ebed;
}

.table > :not(:first-child) {
  border-top: 0;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-display {
  font-size: 12px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border-radius: 10px;
  padding: 10px;
}
.date-display img {
  margin-bottom: 4px;
  margin-left: 10px;
}
.filter-wrp select {
  font-size: 14px;
  color: #242220;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: #f5f7f9;
  border: none;
  border-radius: 10px;
  padding: 10px;
}
.action-wrapper {
  white-space: nowrap;
}
</style>

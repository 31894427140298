<template>
  <!-- Log In page -->
  <div class="container-full d-flex align-items-center">
    <div class="vld-parent">
      <Loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <div class="container-xxl">
      <div class="row d-flex justify-content-center">
        <div class="col-12 align-self-center">
          <div class="">
            <div class="card-body p-0">
              <div class="row d-flex justify-content-center align-items-center welcome-gap">
                <div class="col-lg-5">
                  <div class="media mb-5 justify-content-center d-none">
                    <img src="../../public/assets/images/crescofy-logo.png" height="55" alt="logo" class="auth-logo" />
                  </div>
                  <div class="card card-box">
                    <div class="card-body p-0">
                      <div class="forgot-title-box">
                        <h2>Forgot Password?</h2>
                        <h4 class="form-paragraph">
                          Please enter the email you are registered with. We’ll
                          send the password recovery link there.
                        </h4>
                      </div>
                      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"
                        class="form-horizontal auth-form my-4 mb-0" action="index.html">
                        <div class="form-group" :class="{ 'has-error': errors.email }">
                          <label for="email">Email</label>
                          <div class="position-relative">
                            <div class="control-icon">
                              <img src="@/../public/assets/images/smsicon.svg" alt="" />
                            </div>
                            <Field as="input" type="email" class="form-control form-control-danger" name="email"
                              placeholder="Enter Email" />
                          </div>
                          <div class="form-control-feedback">
                            {{ errors.email }}
                          </div>
                        </div>
                        <!--end form-group-->

                        <div class="form-group" :class="{ 'has-error': errors.detail }">
                          <Field as="input" type="hidden" class="form-control" name="detail" />
                          <div class="form-control-feedback">
                            {{ errors.detail }}
                          </div>
                        </div>
                        <!--end form-group-->

                        <div class="form-group mb-0 row">
                          <div class="col-12 d-flex align-items-center flex-column">
                            <button class="w-100 btn btn-block waves-effect waves-light login-button" type="submit">
                              Send email verification
                            </button>

                            <router-link to="/login" class="login-page-link">
                              <img src="@/../public/assets/images/arrow-left.svg" alt="" />Back to Log in</router-link>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end form-group-->
                      </Form><!--end form-->
                    </div>
                  </div>
                </div>

                <!-- Welcome Back Section (Right Side) -->
                <ImageSlider />
              </div>
              <!--end row-->
            </div>
            <!--end card-body-->
          </div>
          <!--end card-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

import { FORGOT_PASSWORD } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import ImageSlider from "./components/ImageSlider.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ImageSlider,
    Loading
  },
  data() {
    const schema = yup.object().shape({
      email: this.emailValidator(),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      schema,
      isLoading: false
    };

  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [FORGOT_PASSWORD]),

    onSubmit(values, actions) {
      this.isLoading = true
      console.log("egfewgfeg", values);
      this[FORGOT_PASSWORD]({ values, actions })
        .then(() => {
          this.$alertify.notify(
            `reset password link sent to your email.please check`,
            "success",
            3
          );
          this.isLoading = false
          this.$router.push("/verification");

        })
        .catch(() => { }); //stay on a page
    },
  },
};
</script>
<style scoped>
.container-full {
  background: #fff;
  height: auto;
  padding: 40px 0;
}

.control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.login-button {
  background-color: #5e66fa;
  color: #ffffff;
  height: 46px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}

.login-button:hover {
  background-color: #727aff;
  color: #fff;
}

.login-page-link {
  border-radius: 8px;
  margin-left: 0;
  color: #1a2b3a;
  font-style: normal;
  height: 46px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d5dd;
  margin-top: 30px;
  gap: 8px;
}

.login-page-link:hover {
  border: 1px solid #d0d5dd;
  background: #d0d5dd;
}

.card-box {
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
  border: 0;
}

.forgot-title-box h2 {
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1a2b3a;
}

.forgot-title-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #1a2b3a;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 30px;
}

.form-paragraph {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
  color: #485561;
  margin-bottom: 30px;
}

.form-group label {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.64px;
  text-align: left;
  color: #1a2b3a;
  margin-bottom: 12px;
}

.form-group .form-control {
  font-size: 14px;
  border: 0;
  font-weight: 400;
  color: #1a2b3a;
  padding: 8px 15px;
  background: #f5f7f9;
  height: 46px;
  border-radius: 8px;
  padding-left: 47px;
}

.form-control-feedback {
  line-height: 12px;
}

@media (min-width: 992px) {
  .container-full {
    height: 100vh;
    padding: 0 0;
  }
}
</style>
